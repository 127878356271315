import { Box, Grid, RadioGroup, Typography } from "@material-ui/core";
import Radio from "components/radio";
import React, { FC } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useStyles } from "../styles";
import { ProrationsForm } from "../types";

type Props = {
  selectedTabIndex: number
  isLoading: boolean
}

export const DebitedToForm: FC<Props> = ({
  selectedTabIndex,
  isLoading
}) => {
  const { control } = useFormContext<ProrationsForm>();
  const classes = useStyles();
  return (
    <Box>
      <Typography variant="h6" className={classes.subTitle}>
        Debited Party
      </Typography>
      <Box>
        <Controller
          control={control}
          key={`forms.${selectedTabIndex}.debited_party`}
          name={`forms.${selectedTabIndex}.debited_party`}
          render={({ field }) => (
            <RadioGroup
              value={field.value}
              onChange={(_, value) => {
                field.onChange(value);
              }}
            >
              <Grid container>
                <Grid item>
                  <Radio
                    label="Seller Owes Buyer"
                    value="seller_owes_buyer"
                    disabled={isLoading}
                  />
                </Grid>

                <Grid item>
                  <Radio
                    label="Buyer Owes Seller"
                    value="buyer_owes_seller"
                    disabled={isLoading}
                  />
                </Grid>
              </Grid>
            </RadioGroup>
          )}
        />
      </Box>
    </Box>
  );
};