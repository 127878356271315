import { Input, makeStyles, Typography } from '@material-ui/core';
import Switch from 'components/switch';
import { TFunction } from 'i18next';
import React from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { projectedPayments } from '../services';

const useStyles = makeStyles((theme: any) => ({
  root: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center'
  },
  addDescField: {
    marginLeft: theme.spacing(3),
    '&>*': {
      height: theme.spacing(4),
      width: theme.spacing(30),
    },
  },
  underline: {
    fontSize: theme.spacing(2),
    color: theme.palette.primary.light,
    fontWeight: 500,
    width: '100%',
    paddingLeft: theme.spacing(1),
    '&&&:before': { borderBottom: 'none' },
    '&&:after': { borderBottom: 'none' },
    '&:hover': { background: theme.palette.input.border },
  },
}));

type IncludesFieldsType = {
  methods: UseFormReturn<projectedPayments, any, undefined>
}

const IncludesFields = ({
  methods
}: IncludesFieldsType) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <>
      <span className={classes.root}>
        <Controller
          control={methods.control}
          name="includes_taxes"
          render={({ field }) => (
            <>
              <Switch
                label=""
                checked={field.value}
                handleOnChange={(e) => field.onChange(e.target.checked)}
              />

              <Typography>{t('projectedPayments:propertiesTaxes')}</Typography>
            </>
          )}
        />
      </span>

      <span className={classes.root}>
        <Controller
          control={methods.control}
          name="includes_homeowners"
          render={({ field }) => (
            <>
              <Switch
                label=""
                checked={field.value}
                handleOnChange={(e) => field.onChange(e.target.checked)}
              />

              <Typography>{t('projectedPayments:homeownersInsurance')}</Typography>
            </>
          )}
        />
      </span>

      <span className={classes.root}>
        <Controller
          control={methods.control}
          name="includes_other"
          render={({ field }) => (
            <>
              <Switch
                label=""
                checked={field.value}
                handleOnChange={(e) => field.onChange(e.target.checked)}
              />

              <Typography>{t('projectedPayments:other')}</Typography>
            </>)}
        />
      </span>
      <span className={classes.addDescField}>
        <Controller
          control={methods.control}
          name="other_description"
          render={({ field }) => (
            <>
              <Input
                className={classes.underline}
                placeholder={t('projectedPayments:addADescription')}
                value={field.value}
                onChange={(e) => field.onChange(e.target.value)}
              />
            </>)}
        />
      </span>
    </>
  );
};

export default IncludesFields;
