import PageCheckerAuthenticated from "components/organisms/PageCheckerAuthenticated";
import PageCheckerUnOnboarded from "components/organisms/PageCheckerUnOnboarded";
import { LLMPRoxyServiceProvider } from "contexts/llmProxySerivceContext";
import { WorkspaceContextProvider } from "contexts/workspace-context";
import AccessRequired from "layouts/access-required-layout/access-required-layout";
import AuthenticatedLayout from "layouts/authneticated-layout/authenticated-layout";
import React from "react";
import { Route, RouteProps } from "react-router-dom";

type Props = {
  adminOnly?: boolean
} & RouteProps;

export const PrivateRoute = ({ children, adminOnly = false, ...rest }: Props) => {
  return (
    <Route
      {...rest}
      render={() => (
        <PageCheckerAuthenticated
          render={() => (
            <PageCheckerUnOnboarded
              render={() => (
                <WorkspaceContextProvider>
                  <LLMPRoxyServiceProvider>
                    <AuthenticatedLayout>
                      {adminOnly ? (
                        <AccessRequired>
                          {children as any}
                        </AccessRequired>
                      ) : children as any}
                    </AuthenticatedLayout>
                  </LLMPRoxyServiceProvider>
                </WorkspaceContextProvider>
              )}
            />
          )}
        />
      )}
    />
  );
};