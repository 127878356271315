import { makeStyles, Tab, Typography } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';

const useStyles = makeStyles((theme: any) => ({
  tabroot: {
    minWidth: theme.spacing(12),
    maxWidth: theme.spacing(30),
  },
  tabWrapper: {
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(1),
    textTransform: 'none',
  },
  tabSelected: {
    fontWeight: 500,
    color: theme.palette.primary.main,
  },
  tabUnselected: {
    fontWeight: 500,
    color: theme.palette.tab.offselected,
  },
  disabledTab: { opacity: 0.5 },
  enabledTab: { opacity: 1 }
}));

type CustomTabtype = {
  text: string,
  value: string,
  currentValue: string,
  disabled?: boolean
}

export const CustomTab = ({
  text, value, currentValue, disabled, ...props
}: CustomTabtype) => {
  const classes = useStyles();
  return (
    <Tab
      {...props}
      value={value}
      classes={{ root: classes.tabroot, wrapper: classes.tabWrapper }}
      disabled={disabled}
      label={(
        <Typography
          className={clsx({
            [classes.tabSelected]: currentValue === value,
            [classes.tabUnselected]: currentValue !== value,
            [classes.enabledTab]: !disabled,
            [classes.disabledTab]: disabled
          })}
          variant="body2"
        >
          {text}
        </Typography>
      )}
    />
  );
};

CustomTab.defaultProps = { disabled: false, };
export default CustomTab;
