import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid, InputAdornment, Switch, Box, Typography } from '@material-ui/core';
import SelectField from 'components/select-field';
import TextField from 'components/text-field';
import { ListingEntry } from 'graphql/listings/queries';
import React, { useCallback } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { setOrderConfiguration } from 'redux/order-configuration-reducer/actions';
import { getOrderConfiguration } from 'redux/order-configuration-reducer/selectors';

import { DatesAndConfigurationForm } from '../services';
import Title from './title';

type FormConfigProps = {
  workflows: ListingEntry[]
};

const settlementStatementTypes = [
  {
    name: "Closing Disclosure"
  },
  {
    name: "HUD 1"
  },
  {
    name: "Settlement Statement"
  }
];

const FormConfig = ({ workflows }: FormConfigProps) => {
  const { control, getValues } = useFormContext<DatesAndConfigurationForm>();

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const configurationOrder = useSelector(getOrderConfiguration);

  const handleChangeMenu = useCallback((newType: string) => {
    dispatch(setOrderConfiguration({ ...configurationOrder!, type: newType }));
  }, [configurationOrder, dispatch]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Title>{t('datesAndConfig:configuration')}</Title>
      </Grid>

      <Grid item xs={6}>
        <TextField
          disabled
          label={`${t('orders:order')} #`}
          value={getValues('order_id')}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <FontAwesomeIcon icon="edit" size="xs" />
              </InputAdornment>
            ),
          }}
        />
      </Grid>

      <Grid item xs={6}>
        <Controller
          control={control}
          name="type"
          render={({ field }) => (
            <SelectField
              inputRef={field.ref}
              value={field.value}
              label={t('orders:settlement-statement')}
              data={settlementStatementTypes}
              dataKey="name"
              dataValue="name"
              dataText="name"
              handleBlur={field.onBlur}
              handleChange={(e) => {
                field.onChange(e);
                setTimeout(() => { handleChangeMenu(e.target.value); }, 1500);
              }}
            />
          )}
        />

      </Grid>
      {!!workflows.length && (
        <Grid item xs={12}>
          <Controller
            control={control}
            name="workflow"
            render={({ field }) => (
              <SelectField
                value={field.value}
                inputRef={field.ref}
                label={t('datesAndConfig:workflow')}
                data={workflows}
                dataKey="code"
                dataValue="code"
                dataText="description"
                handleBlur={field.onBlur}
                handleChange={(event) => field.onChange(event.target.value)}
              />
            )}
          />

        </Grid>
      )}

      <Grid item xs={12}>
        <Title>{t('datesAndConfig:additional-letter-fees')}</Title>
      </Grid>
      <Grid item xs={12}>
        <Controller
          control={control}
          name="additional_fees_letter"
          render={({ field }) => (
            <Box display="flex" flexDirection="row" alignItems="center">
              <Typography>B</Typography>
              <Switch
                {...field}
                checked={field.value === 'C'}
                color="secondary"
                onChange={(e) => {
                  if (e.target.checked) {
                    field.onChange('C');
                  } else {
                    field.onChange('B');
                  }
                }}
              />
              <Typography>C</Typography>
            </Box>
          )}
        />
      </Grid>
    </Grid>
  );
};

export default FormConfig;
