import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ButtonBase, Chip, Grid, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { Typewriter } from 'components/inference/components/typewriter';
import React, { FC } from 'react';

import { Message } from '../../types';
import { useStyles } from './styles';

type MessageContentProps = {
  message: Message,
  onViewSourceClick: () => void,
  onPlaySpeech?: ({ messageId, text }: { messageId: string, text: string }) => void,
  onStopSpeech?: () => void,
  isAudioPlaying?: boolean,
  isAudioLoading?: boolean
}

export const MessageContent: FC<MessageContentProps> = ({
  message,
  onViewSourceClick,
  onPlaySpeech,
  onStopSpeech,
  isAudioPlaying = false,
  isAudioLoading = false
}) => {
  const classes = useStyles();

  return (
    <Grid
      item
      container
      direction="column"
      className={classes.messageItem}
    >
      <Grid item container wrap="nowrap" justifyContent="space-between">
        <Grid item>
          <Typography color="primary" style={{ fontWeight: 500, fontSize: '1.1rem' }}>{message.text}</Typography>
        </Grid>
        {message.response && (
          <Grid item>
            {(isAudioPlaying && isAudioLoading) ? (
              <FontAwesomeIcon spin icon="spinner" color="#FB8C00" />
            ) : (
              <ButtonBase onClick={() => {
                if (isAudioPlaying) {
                  if (onStopSpeech) {
                    onStopSpeech();
                  }
                } else if (onPlaySpeech) {
                  onPlaySpeech({ messageId: message.id, text: message.response });
                }
              }}
              >
                <FontAwesomeIcon
                  icon={isAudioPlaying ? 'stop-circle' : 'play-circle'}
                  size="lg"
                  color="primary"
                />
              </ButtonBase>
            )}
          </Grid>
        )}
      </Grid>
      <Grid item>
        {message.isLoading && (
          <>
            <Skeleton variant="text" height={30} width="90%" />
            <Skeleton variant="text" height={30} width="70%" />
            <Skeleton variant="text" height={30} width="80%" />
          </>
        )}
        {!message.isLoading && message.response.length > 0 && (
          <Typewriter
            text={message.response}
            animationEnabled={false}
          />
        )}
      </Grid>
      <Grid item container spacing={1}>
        {message.isLoading && (
          <>
            <Grid item style={{ width: '20%' }}>
              <Skeleton variant="text" height={35} />
            </Grid>
            <Grid item style={{ width: '20%' }}>
              <Skeleton variant="text" height={35} />
            </Grid>
          </>
        )}
        {message.sources && (
          <>
            {message.sources.map((source) => (
              <Grid item>
                <Chip
                  label={`Source: ${source.filePath} ${source.pageLabel ? ` Page: ${source.pageLabel}` : ''}`}
                  size="small"
                />
              </Grid>
            ))}
            <Grid item>
              <FontAwesomeIcon
                icon="eye"
                onClick={onViewSourceClick}
                style={{ cursor: 'pointer' }}
              />
            </Grid>
          </>
        )}
      </Grid>
      <Grid item alignContent="flex-end">
        <Typography variant="caption" align="right" color="primary">
          {`${message.questionTime.getHours()}:${message.questionTime.getMinutes()}`}
        </Typography>
      </Grid>
    </Grid>
  );
};