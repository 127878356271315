import React from 'react';

import useProjectedPayments from './projected-payments-hook';
import ProjectedPaymentsScene from './projected-payments-scene';
import { projectedPayments } from './services';

const ProjectedPayments = () => {
  const {
    methods,
    updateProjectedPayments,
    updateOrderProjectedResponse,
    orderId
  } = useProjectedPayments();

  const handleSave = (values: projectedPayments) => {
    updateProjectedPayments({
      id: orderId,
      data: {
        ...values,
        year_range_1: Number(values.year_range_1),
        year_range_2: Number(values.year_range_2),
        principal_amount: Number(values.principal_amount),
        principal_min: Number(values.principal_min),
        principal_max: Number(values.principal_max),
        mortgage_insurance: Number(values.mortgage_insurance),
        estimated_escrow: Number(values.estimated_escrow),
        prorations_amount: Number(values.prorations_amount),
      }
    });
  };

  return (
    <ProjectedPaymentsScene
      methods={methods}
      loadingSave={updateOrderProjectedResponse.isLoading}
      handleSave={handleSave}
    />
  );
};

export default ProjectedPayments;
