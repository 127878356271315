import { Box, makeStyles, Typography } from '@material-ui/core';
import DatePicker from 'components/date-picker/date-picker';
import NumberField from 'components/number-field';
import Select from 'components/select-field';
import TextField from 'components/text-field';
import { TFunction } from 'i18next';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { OrderProperty } from 'types/order';
import { ScheduleProrationEnum } from 'v2-types/order';
import { ProrationsForm } from '../types';
import { paymentScheduleOptions } from '../utils';

type FormTaxInfoProps = {
  t: TFunction,
  selectedProperty: OrderProperty,
  selectedTabIndex: number,
  isLoading: boolean
};

const useStyles = makeStyles((theme: any) => ({
  subTitle: {
    color: theme.palette.tab.offselected,
    fontWeight: 500,
    marginBottom: theme.spacing(1)
  },
}));

export const FormTaxInfo = ({
  t,
  selectedProperty,
  selectedTabIndex,
  isLoading
}: FormTaxInfoProps) => {
  const classes = useStyles();
  const { control } = useFormContext<ProrationsForm>();

  const streetAndParcelIds = `${selectedProperty.address.street_address} ${selectedProperty.parcel_ids.length > 0 ? `(${selectedProperty.parcel_ids.join(', ')})` : ''}`;

  return (
    <Box>
      <Typography variant="h6" className={classes.subTitle}>
        {t('taxesAndProrations:tax-info')}
      </Typography>

      <Box display="flex" style={{ gap: "20px" }} flexDirection="column">
        <TextField
          disabled
          label={t('taxesAndProrations:parcel-id')}
          value={streetAndParcelIds}
        />

        <Controller
          control={control}
          key={`forms.${selectedTabIndex}.anualAmount.amount`}
          name={`forms.${selectedTabIndex}.anualAmount.amount`}
          render={({ field }) => (
            <NumberField
              color="secondary"
              label="Amount"
              name="amount"
              handleBlur={field.onBlur}
              value={field.value}
              thousandSeparator
              onValueChange={({ floatValue }) => field.onChange(floatValue ?? null)}
              prefix="$"
              disabled={isLoading}
            />
          )}
        />

        <Box display="flex" style={{ gap: "20px" }}>
          <Controller
            control={control}
            key={`forms.${selectedTabIndex}.paid_thru`}
            name={`forms.${selectedTabIndex}.paid_thru`}
            render={({ field }) => (
              <DatePicker
                name="paid_thru"
                label={t('taxesAndProrations:paid-thru')}
                value={field.value}
                handleBlur={field.onBlur}
                onChange={(date) => field.onChange(date)}
                disabled={isLoading}
              />
            )}
          />

          <Controller
            control={control}
            key={`forms.${selectedTabIndex}.next_due`}
            name={`forms.${selectedTabIndex}.next_due`}
            render={({ field }) => (
              <DatePicker
                name="next_due"
                label={t('taxesAndProrations:next-due')}
                value={field.value}
                handleBlur={field.onBlur}
                onChange={(date) => field.onChange(date)}
                disabled={isLoading}
              />
            )}
          />

          <Controller
            control={control}
            key={`forms.${selectedTabIndex}.schedule`}
            name={`forms.${selectedTabIndex}.schedule`}
            render={({ field }) => (
              <Select
                key={`${selectedTabIndex}_payment_schedule`}
                name="payment_schedule"
                label={t('taxesAndProrations:payment-schedule')}
                disabled={isLoading}
                value={field.value}
                data={paymentScheduleOptions}
                dataKey="value"
                dataValue="value"
                dataText="name"
                handleBlur={field.onBlur}
                handleChange={(event) => {
                  const value = event.target.value as ScheduleProrationEnum;
                  field.onChange(value);
                }}
              />
            )}
          />
        </Box>
      </Box>
    </Box>
  );
};