import { useTheme } from '@material-ui/core';
import { useLLMProxyServiceContext } from 'contexts/llmProxySerivceContext';
import { useWorkspaceContext } from 'contexts/workspace-context';
import { updateLedgerEntries } from 'graphql/ledger/mutations';
import { createOrderProperty, removeOrderProperty, updateOrderProperty } from 'graphql/properties/mutations';
import { getOrderProperties, GQLGetOrderProperties } from 'graphql/properties/queries';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryCache } from 'react-query';
import { useParams } from 'react-router-dom';
import { hasObjectAllPropertiesWithValues } from 'services/inference/utils';
import { ToolFunction } from 'services/inference/types';
import { GraphqlError, OrderProperty } from 'types/order';
import useAlert from 'utils/alert';
import { LEDGER_CODE_LOAN_AMOUNT, LEDGER_CODE_PURCHASE_PRICE, PROPERTY_TAB } from 'utils/constants';
import { capitalize } from 'utils/helpers';

import { extractPropertyAddress } from './utils/extractPropertyAddress';
import { extractPropertyData } from './utils/extractPropertyData';
import { initialValues } from './utils/initialValues';

export const useOrderFlowProperties = () => {
  const { id: orderId } = useParams<{ id: string }>();
  const { t } = useTranslation();
  const theme = useTheme();
  const showAlert = useAlert();
  const [selectedProperty, setSelectedProperty] = useState<OrderProperty>(initialValues);
  const [currentTab, setCurrentTab] = useState<string>(PROPERTY_TAB);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
  const { updateOrderTabPropertyAddress } = useWorkspaceContext();
  const { llmServiceRef } = useLLMProxyServiceContext();

  const {
    handleSubmit,
    formState: {
      errors,
      isValid: dataIsValid,
      isDirty: dataHasChanges,
      dirtyFields: dataDirtyFields
    },
    reset,
    control,
    getValues,
    setValue
  } = useForm<Omit<OrderProperty, 'loans'>>({ defaultValues: initialValues, mode: 'onChange' });
  const queryCache = useQueryCache();

  const { data, isLoading: isPropertiesDataLoading } = useQuery<GQLGetOrderProperties>(
    ['order-properties', orderId],
    () => getOrderProperties({ id: orderId }),
    {
      refetchOnWindowFocus: false,
      onSuccess: (localdata) => {
        let { estates } = localdata.getOrder;
        if (estates && estates?.length > 0) {
          estates = estates.map((estate) => {
            const accounting = estate.accounting || [];
            return {
              ...estate,
              accounting: accounting.filter((entry) => !entry.code.includes(LEDGER_CODE_LOAN_AMOUNT))
            };
          });

          llmServiceRef.current.setViewInformation({
            id: "properties-info",
            text: `Order properties information: ${estates.map(estate => {
              const purchasePriceValue = estate.accounting.find((entry) => entry.code.includes(LEDGER_CODE_PURCHASE_PRICE));
              return `-${estate.address.address}: propertyId: ${estate._id}, purchase price information: { amount: ${purchasePriceValue?.amount ?? 0}, accountingId: ${purchasePriceValue?._id}\n`;
            })}`
          });

          updateOrderTabPropertyAddress(orderId, estates[0].address.street_address);

          if (selectedProperty._id === '') {
            setSelectedProperty(estates[0]);
            reset(estates[0]);
          } else {
            const property = estates.find((orderProperty) => orderProperty._id === selectedProperty._id);
            setSelectedProperty(property ?? initialValues);
            reset(property ?? initialValues);
          }
        } else {
          reset(initialValues);
          llmServiceRef.current.removeViewInformationItem("properties-info");
          updateOrderTabPropertyAddress(orderId, '');
        }
      }
    }
  );

  const properties = data?.getOrder.estates || [];

  const [
    createProperty,
    {
      isLoading: isCreatePropertyLoading,
      isError: isCreatePropertyError,
      error: createPropertyError,
    }
  ] = useMutation(createOrderProperty, {
    onSuccess: async (createResponse) => {
      showAlert(t('properties:created'), 'success');

      queryCache.invalidateQueries(['party-order', orderId]);
      queryCache.invalidateQueries(['order-properties-premium', orderId]);
      queryCache.invalidateQueries([{ orderId }, 'prorations']);

      if (createResponse.createOrderProperty.estates) {
        const propertiesFromResponse = createResponse.createOrderProperty.estates;
        const propertyFormData = getValues();
        const propertyCreated = propertiesFromResponse.find((property) => property.address.street_address === propertyFormData.address.street_address
          && property.address.postal_code === propertyFormData.address.postal_code
          && property.address.locality === propertyFormData.address.locality
          && property.address.state === propertyFormData.address.state);
        queryCache.setQueryData(['order-properties', orderId], { getOrder: createResponse.createOrderProperty });

        if (propertyCreated) {
          setSelectedProperty(propertyCreated);
          reset(propertyCreated);
        }
      }
    },
    onError: () => { showAlert(capitalize(t('dialogs:there-is-an')), 'error'); }
  });

  const [
    updateProperty,
    {
      isLoading: isUpdatePropertyLoading,
      isError: isUpdatePropertyError,
      error: updatePropertyError,
    }
  ] = useMutation(updateOrderProperty, {
    onSuccess: () => {
      showAlert(t('properties:updated'), 'success');
      queryCache.invalidateQueries(['order-properties', orderId]);
      queryCache.invalidateQueries(['order-properties-premium', orderId]);
    },
    onError: () => { showAlert(capitalize(t('dialogs:there-is-an')), 'error'); }
  });

  const [
    removeProperty,
    {
      isLoading: isRemovePropertyLoading,
      isError: isRemovePropertyError,
      error: removePropertyError
    }
  ] = useMutation(removeOrderProperty, {
    onSuccess: () => {
      setIsDeleteModalOpen(false);

      showAlert(t('properties:removed'), 'success');
      queryCache.invalidateQueries(['order-properties', orderId]);
      queryCache.invalidateQueries(['party-order', orderId]);
      queryCache.invalidateQueries(['order-properties-premium', orderId]);
      reset(initialValues);

      setSelectedProperty(initialValues);
    },
    onError: (error: GraphqlError) => {
      if (error && error.response.errors) {
        showAlert(error.response.errors[0].message, 'error');
      } else {
        showAlert(capitalize(t('dialogs:there-is-an')), 'error');
      }
    }
  });

  const [
    updateAccountingEntries,
    { isLoading: isLoadingPurchasePrice }
  ] = useMutation(updateLedgerEntries, {
    onSuccess: () => {
      showAlert(t('properties:updated'), 'success');
      queryCache.invalidateQueries(['order-properties', orderId]);
      queryCache.invalidateQueries(['order-properties-premium', orderId]);
    },
    onError: () => { showAlert(capitalize(t('dialogs:there-is-an')), 'error'); }
  });

  useEffect(() => {
    const tools: ToolFunction[] =
      [
        {
          name: "createNewProperty",
          tool: async ({ tool, toolParams }) => {
            if (!(tool === "createNewProperty" && toolParams && hasObjectAllPropertiesWithValues(toolParams))) return "Please provide all the required infomation.";

            const {
              block,
              jurisdiction,
              legal_description,
              lot,
              parcel_ids,
              range,
              section,
              subdivision,
              survey_date,
              survey_information,
              survey_number,
              township,
              type,
              street,
              settlement,
              references,
              postal_code,
              locality,
              state = "TX",
              country = "US"
            } = toolParams;

            await createProperty({
              id: orderId!,
              data: {
                block: block !== null && block !== "" ? block : undefined,
                jurisdiction,
                legal_description,
                lot,
                parcel_ids: parcel_ids !== null ? parcel_ids.split(',') : [""],
                range,
                section,
                subdivision,
                survey_date,
                survey_information,
                survey_number,
                township,
                type,
              },
              address: {
                street_address: street,
                state,
                settlement,
                references,
                postal_code,
                locality,
                country
              }
            });

            return `The property ${street} was succesfully created.`;
          }
        },
        {
          name: "updateProperty",
          tool: async ({ tool, toolParams }) => {
            if (!(tool === "updateProperty" && toolParams && hasObjectAllPropertiesWithValues(toolParams))) return "Please provide all the required infomation.";
            const {
              propertyId,
              orderId: id,
              block,
              brief_legal_description,
              jurisdiction,
              legal_description,
              lot,
              parcel_ids,
              range,
              section,
              subdivision,
              survey_date,
              survey_information,
              survey_number,
              township,
              type
            } = toolParams;

            await updateProperty({
              id,
              propertyId,
              data: {
                block,
                jurisdiction,
                legal_description,
                lot,
                parcel_ids: parcel_ids ? parcel_ids.split(',') : undefined,
                range,
                section,
                subdivision,
                survey_date,
                survey_information,
                survey_number,
                township,
                type,
                brief_legal_description
              }
            });

            return `The property was succesfully updated.`;
          }
        },
        {
          name: "removeProperty",
          tool: async ({ tool, toolParams }) => {
            if (!(tool === "removeProperty" && toolParams && hasObjectAllPropertiesWithValues(toolParams))) return "Please provide all the required infomation.";

            try {
              const {
                propertyId,
                orderId: id,
              } = toolParams;

              const response = await removeProperty({
                id,
                propertyId
              });

              if (response) { return `The property was succesfully removed.`; }
              return "Unexpected error ocurred while removing the property.";
            } catch (error) {
              const responseError = error as {
                response: {
                  status: number,
                  errors: {
                    message: string
                  }[]
                }
              };
              if (responseError && responseError.response && responseError.response.status === 200) {
                if (responseError.response.errors && responseError.response.errors.length > 0) {
                  return `The property can't be removed due: ${responseError.response.errors[0].message}`;
                }
              }
              return `Unexpected error ocurred while removing the property. Error message: ${error}`;
            }
          }
        },
        {
          name: "updatePropertyPurchasePrice",
          tool: async ({ tool, toolParams }) => {
            if (!(tool === "updatePropertyPurchasePrice" && toolParams && hasObjectAllPropertiesWithValues(toolParams))) return "Please provide all the required infomation.";

            const {
              orderId: id,
              purchasePriceEntryId,
              amount
            } = toolParams;

            await updateAccountingEntries({
              id,
              filters: { accounting: 'property' },
              entries: [{
                accountingId: purchasePriceEntryId,
                data: {
                  amount_override: amount,
                  letter: null,
                  entry_date: null
                }
              }]
            });

            return `The purchase price was succesfully updated.`;
          }
        }
      ];

    tools.forEach((tool) => llmServiceRef.current.addCurrentTool(tool));
  }, []);

  const selectProperty = (id: string) => {
    if (data?.getOrder.estates) {
      const property = data.getOrder.estates.find((orderProperty) => orderProperty._id === id);
      setSelectedProperty(property ?? initialValues);
      reset(property ?? initialValues);
    }
  };

  const onSaveProperty = handleSubmit(async (propertyInfo) => {
    if (!dataHasChanges) return;
    const { _id: propertyId, accounting } = propertyInfo;
    const propertyData = extractPropertyData(propertyInfo);

    if (propertyId.length > 0) {
      if (Object.keys(dataDirtyFields).some((key) => propertyData[key])) {
        updateProperty({
          id: orderId,
          propertyId,
          data: propertyData,
        });
      }

      if (dataDirtyFields.accounting) {
        updateAccountingEntries({
          id: orderId,
          filters: { accounting: 'property' },
          entries: accounting.map((entry) => ({
            accountingId: entry?._id || '',
            data: {
              amount_override: entry.amount,
              letter: entry.letter,
              entry_date: entry.entry_date
            }
          }))
        });
      }
    } else {
      const address = extractPropertyAddress(propertyInfo);
      createProperty({
        id: orderId,
        data: propertyData,
        address
      });
    }
  });

  const onAddProperty = () => {
    reset(initialValues);
    setSelectedProperty(initialValues);
  };

  const onRemoveProperty = async () => {
    removeProperty({
      id: orderId,
      propertyId: selectedProperty._id
    });
  };

  return {
    t,
    theme,
    properties,
    isPropertiesDataLoading,
    selectedProperty,
    selectProperty,
    currentTab,
    onSelectTab: setCurrentTab,
    onSaveProperty,
    errors,
    dataIsValid,
    control,
    isCreatePropertyLoading,
    isCreatePropertyError,
    createPropertyError,
    isUpdatePropertyLoading,
    isUpdatePropertyError,
    updatePropertyError,
    onAddProperty,
    onRemoveProperty,
    isRemovePropertyLoading,
    isRemovePropertyError,
    removePropertyError,
    getValues,
    isSaveLoading: isCreatePropertyLoading || isUpdatePropertyLoading || isLoadingPurchasePrice,
    setValue,
    isDeleteModalOpen,
    setIsDeleteModalOpen
  };
};
