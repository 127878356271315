import graphQLClient from 'config/graphql-instance';
import { gql } from 'graphql-request';
import { DatesAndConfigurationType, Loan } from 'v2-types/order';

export const loansQuery = gql`
  configuration {
      additional_fees_letter
  }
  loans {
    _id
    amount_kind
    commitment_date
    construction
    construction_initial
    estate {
      address {
        address
        country
        street_address
      }
    }
    grace_days
    heloc
    heloc_draw
    insurance_case
    loan_number
    interests {
      ... on OrderLoanInterestAdjustable {
        _id
        index_name
        kind
        margin
      }
      ... on OrderLoanInterestConversion {
        _id
        fee
        kind
        period_start
        period_end
      }
      ... on OrderLoanInterestFirst {
        _id
        change_date
        change_min
        change_max
        kind
      }
      ... on OrderLoanInterestFixed {
        _id
        kind
        rate
      }
      ... on OrderLoanInterestOnly {
        _id
        kind
        rate
      }
      ... on OrderLoanInterestSubsequent {
        _id
        change_max
        kind
        months
        rate_min
        rate_max
      }
      ... on OrderLoanInterestVariable {
        _id
        kind
        rate
      }
    }
    mers
    mers_min
    payment_first_date
    payment_last_date
    term_months
    term_years
    terms
    purpose
    product
    buyer_issue_date
    seller_issue_date
    late_payment_days
    transfer_assumption
    demand_feature
    negative_amortization
    partial_payment_accept
    partial_payment_denied
    partial_payment_hold
    escrow
    escrow_declined
    unpaid_liability
    increase_loan_amount
    increase_loan_amount_text
    increase_interest_rate
    increase_interest_rate_text
    increase_monthly_principal
    increase_monthly_principal_text
    has_prepayment_penalty
    has_prepayment_penalty_text
    has_balloon_payment
    has_balloon_payment_text
    penalty_as_percent
    accounting {
      _id
      amount
      amount_calculated
      amount_override
      annuality
      code
      description
      entry_date
      kind
      months
      involved_parties {
        _id
        amount
        at_closing_percent
        at_closing_amount
        before_closing_amount
        before_closing_percent
        kind
        name
        order_kinds
        percent
      }
    }
  }
  accounting {
    _id
  }
`;

type GetLoansParams = {
  orderId: string,
}

export type GetLoansReturn = {
  getOrder: {
    loans: Loan[],
    configuration?: Pick<DatesAndConfigurationType, 'additional_fees_letter'>
  }
}

export const getLoans = async (payload: GetLoansParams) => {
  const query = gql`
  query GetOrder($orderId: ID!) {
    getOrder(_id: $orderId) {
      ${loansQuery}
    }
  }
  `;
  const response = await graphQLClient().request<GetLoansReturn, GetLoansParams>(query, payload);
  return response;
};
