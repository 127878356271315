import { getOrderAccounting, GetOrderAccountingReturn } from "graphql/ledger/queries";
import { QueryConfig, useQuery } from 'react-query';
import { AccountingFilter } from "v2-types/order";

type Params = {
  orderId: string,
  filters?: {[key: string]: AccountingFilter},
  queryKey: string,
  queryConfig?: QueryConfig<GetOrderAccountingReturn, Error>,
}

export const useGetOrderAccounting = ({ orderId, filters, queryConfig, queryKey }: Params) => useQuery([{ orderId }, queryKey], {
  ...queryConfig,
  queryFn: () => getOrderAccounting({ id: orderId, filters })
});
