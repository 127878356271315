import gqlRequest from 'graphql/generic';
import { AccountingType, PolicyType } from 'v2-types/order';

type filterchargesType = {
  accounting: string;
};

export type OrderCharges = {
  accounting_id: string;
  description: string;
  payee_id: string;
  tax: number;
  per_month: number;
  months: number;
  buyer_at: number;
  buyer_before: number;
  seller_at: number;
  seller_before: number;
  by_others: number;
  number: number;
};

type UpdateChargesParams = {
  id: string;
  data: OrderCharges[];
  filters?: filterchargesType;
};

type MutationChargesResponse = {
  helpOrderCharges: {
    accounting: AccountingType[];
  };
};

const mutationUpdateOrder = `
  mutation HelpOrderCharges($id: ID!, $data: [OrderChargesContent!]!, $filters: OrderFilters) {
    helpOrderCharges(_id: $id, data: $data, filters: $filters) {
      accounting {
        _id
        amount
        amount_calculated
        amount_override
        annuality
        code
        kind
        letter
        number
        entry_date
        description
        months
        per_month
        months_advanced
        involved_parties {
          _id
          amount
          at_closing_amount
          at_closing_percent
          before_closing_amount
          before_closing_percent
          kind
          name
          order_kinds
          payment_id
          payment_kind
          payment_reference
          percent
          accounted_amount
          accounted_percent
          accounted_by {
            amount
            percent
            record {
              _id
            description
            code
            entry_date
            }
          }
        }
      }
    }
  }`;

export const updateCharges = (payload: UpdateChargesParams) =>
  gqlRequest<MutationChargesResponse, UpdateChargesParams>(mutationUpdateOrder, payload);

type DeleteChargesParams = {
  id: string;
  accountingId: string;
};

const mutationDeleteOrder = `
  mutation DeleteOrderLedgerEntry($id: ID!, $accountingId: ID!) {
    deleteOrderLedgerEntry(_id: $id, accounting_id: $accountingId) {
      _id
    }
  }`;

export const deleteCharges = (payload: DeleteChargesParams) =>
  gqlRequest<MutationChargesResponse, DeleteChargesParams>(mutationDeleteOrder, payload);

export type UpdateOrder = {
  updateOrderPolicy: {
    accounting: AccountingType[] | null;
    lenders_policy: {
      disclosure_section: string;
    };
  };
};

export type UpdateOrderParams = {
  id: string;
  kind: 'owners' | 'lenders';
  data: {
    disclosure_section: string;
  };
  filters?: filterchargesType;
};

const mutationUpdateOrderPolicy = `
	mutation UpdateOrderPolicy($id: ID!, $kind: OrderPolicyKinds!, $data: OrderPolicyContent!, $filters: OrderFilters) {
	  updateOrderPolicy(_id: $id, kind: $kind, data: $data, filters: $filters) {
		accounting {
		  _id
		  amount
		  amount_calculated
		  amount_override
		  code
		  description
		  entry_date
		  kind
		  letter
		  number
		  months
		  per_month
		  involved_parties {
			_id
			amount
			at_closing_amount
			at_closing_percent
			before_closing_amount
			before_closing_percent
			kind
			name
			order_kinds
			payment_id
			percent
		  }
		}
		lenders_policy {
			disclosure_section
		  }
	  }
	}
  `;

export const updateOrderPolicy = (payload: UpdateOrderParams) =>
  gqlRequest<UpdateOrder, UpdateOrderParams>(mutationUpdateOrderPolicy, payload);
