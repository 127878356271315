import { Grid, Paper, makeStyles } from '@material-ui/core';
import React, { useState } from 'react';
import Header from '../components/header';
import { useTranslation } from 'react-i18next';
import { general } from '../styles/general';
import TableCharges from '../components/tableCharges';
import useGetCharges from '../services/getCharges';
import SkeletonCharges from '../components/skeleton';
import DocumentsDetails from './components/documents-details';

const useStyles = makeStyles((theme) => general(theme));

const ChargesE = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const currentLetter = 'E';

  const [isLoading, setIsLoading] = useState(false);

  const { accounting, updatedAccounting, isFetching, setHideTax, hideTax } =
    useGetCharges(currentLetter);

  const handleIsLoading = (value) => {
    setIsLoading(value);
  };

  return (
    <Grid container className={classes.chargesContainer} direction="column" spacing={2}>
      <Grid item xs={12} style={{ marginBottom: 24 }}>
        <Paper>
          <Header
            setHideTax={setHideTax}
            hideTax={hideTax}
            title={t('charges:taxes-and-goverment-fees')}
            chargesIdentifier={currentLetter}
            t={t}
          />

          <DocumentsDetails
            accountingData={accounting.filter((charge) => charge.code === 'document')}
            handleUpdateAccounting={updatedAccounting}
            handleIsLoading={handleIsLoading}
          />

          {isFetching ? (
            <SkeletonCharges />
          ) : (
            <TableCharges
              accountingData={accounting.filter((charge) => charge.number !== null)}
              currentLetter={currentLetter}
              updatedAccounting={updatedAccounting}
              hideTax={hideTax}
              policyIsLoading={isLoading}
            />
          )}
        </Paper>
      </Grid>
    </Grid>
  );
};

export default ChargesE;
