import { makeStyles, TextField } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';
import NumberFormat, { FormatInputValueFunction, NumberFormatValues } from 'react-number-format';

const useStyles = makeStyles((theme: any) => ({
  input: {
    '&:focus': { backgroundColor: theme.palette.background.default },
    '& .MuiFilledInput-root': {
      textTransform: 'none',
      fontSize: '0.875rem',
      color: theme.palette.common.black,
      borderTopLeftRadius: 4,
      borderTopRightRadius: 4,
      backgroundColor: theme.palette.background.default,
    },
    '& .MuiOutlinedInput-root': {
      textTransform: 'none',
      fontSize: '0.875rem',
      color: theme.palette.common.black,
      borderTopLeftRadius: 4,
      borderTopRightRadius: 4,
      backgroundColor: theme.palette.background.default,
    },
    '& .Mui-disabled': { cursor: 'not-allowed' }
  },
  naked: {
    '&:focus': { backgroundColor: theme.palette.background.default },
    '& .MuiFilledInput-root': {
      textTransform: 'none',
      fontSize: '0.875rem',
      color: theme.palette.common.black,
      backgroundColor: 'transparent',
    },
    '& .MuiFilledInput-input': {
      paddingTop: 10,
      paddingLeft: 0,
    },
    '& .MuiOutlinedInput-root': {
      textTransform: 'none',
      fontSize: '0.875rem',
      color: theme.palette.common.black,
      backgroundColor: 'transparent',
    },
    '& .Mui-disabled': { cursor: 'not-allowed' }
  },
  title: {
    '&:focus': { backgroundColor: theme.palette.background.default },
    '& .MuiFilledInput-root': {
      textTransform: 'none',
      fontSize: 24,
      color: theme.palette.primary.darker,
      fontWeight: 'bold',
    },
  },
  icon: { padding: 0 },
}));

type NumberFieldProps = {
  ref?: any;
  customRef?: any;
  fullWidth?: boolean;
  name?: string;
  color?: 'primary' | 'secondary';
  variant?: 'filled' | 'outlined';
  naked?: boolean;
  isTitle?: boolean;
  disabled?: boolean;
  prefix?: string;
  decimalScale?: number;
  isAllowed?: (values: NumberFormatValues) => boolean;
  thousandSeparator?: string | boolean;
  customInput?: React.ComponentType<any>;
  label?: string | React.ReactNode;
  placeholder?: string;
  value: number | null | undefined;
  error?: boolean;
  helperText?: string;
  handleBlur?: any;
  onChange?: (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
  onValueChange?: (values: NumberFormatValues) => void;
  InputLabelProps?: any
  InputProps?: any;
  isNumericString?: boolean,
  allowNegative?: boolean,
  suffix?: string,
  style?: React.CSSProperties | undefined,
  className?: string
  mask?: string
  format?: string | FormatInputValueFunction
  autoComplete?: string
  type?: 'text' | 'tel' | 'password' | undefined,
  onFocus?: (e: any) => void
};

const NumberField = ({
  ref,
  customRef,
  fullWidth,
  name,
  color,
  variant,
  naked,
  isTitle,
  disabled,
  prefix,
  decimalScale,
  isAllowed,
  thousandSeparator,
  customInput,
  label,
  placeholder,
  value,
  error,
  helperText,
  handleBlur,
  onChange,
  onValueChange,
  InputLabelProps,
  InputProps,
  isNumericString,
  allowNegative,
  suffix,
  style,
  className,
  mask,
  format,
  autoComplete,
  type,
  onFocus
}: NumberFieldProps) => {
  const classes = useStyles();

  return (
    <NumberFormat
      ref={customRef || ref}
      fullWidth={fullWidth}
      variant={variant}
      name={name}
      color={color}
      disabled={disabled}
      prefix={prefix}
      decimalScale={decimalScale}
      isAllowed={isAllowed}
      thousandSeparator={thousandSeparator}
      customInput={customInput}
      className={`${className} ${clsx({ [classes.input]: !naked, [classes.naked]: naked, [classes.title]: isTitle })}`}
      isNumericString={isNumericString}
      label={label}
      placeholder={placeholder}
      allowNegative={allowNegative}
      value={value !== null ? value : ''}
      error={error}
      helperText={helperText}
      onBlur={handleBlur}
      onChange={onChange}
      InputProps={{ ...InputProps }}
      onValueChange={onValueChange}
      InputLabelProps={InputLabelProps}
      suffix={suffix}
      style={style}
      mask={mask}
      format={format}
      autoComplete={autoComplete}
      type={type}
      onFocus={(e) => {
        if (onFocus) {
          onFocus(e);
        } else if (parseFloat(e.target.value) === 0) e.target.value = null;
      }}
    />
  );
};

NumberField.defaultProps = {
  ref: undefined,
  customRef: undefined,
  fullWidth: true,
  name: undefined,
  color: 'primary',
  variant: 'filled',
  naked: false,
  isTitle: false,
  disabled: false,
  prefix: undefined,
  decimalScale: 2,
  isAllowed: ({ floatValue = 0 }) => floatValue >= -99_999_999_999_999.99 && floatValue <= 99_999_999_999_999.99,
  thousandSeparator: undefined,
  customInput: TextField,
  label: undefined,
  placeholder: undefined,
  error: false,
  helperText: undefined,
  handleBlur: undefined,
  onChange: () => { },
  InputProps: undefined,
  onValueChange: undefined,
  InputLabelProps: undefined,
  isNumericString: false,
  allowNegative: true,
  suffix: undefined,
  style: undefined,
  className: '',
  mask: undefined,
  format: undefined,
  autoComplete: undefined,
  type: undefined,
  onFocus: undefined
};

export default NumberField;
