import { Grid, Typography } from '@material-ui/core';
import SelectField from 'components/select-field';
import React, { FC, memo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { NewOrderPartyType } from 'v2-types/order';

import { getParty } from '../graphql/queries';
import { DatesAndConfigurationForm } from '../services';
import Title from './title';

type placeofclosingProps = {
  parties: Pick<NewOrderPartyType, 'name' | '_id' | 'accounts' | 'kinds'>[];
}

const PlaceOfClosing: FC<placeofclosingProps> = ({ parties }) => {
  const titleCompany = parties.find((party) => party.kinds.includes('Title Company'));
  const { t } = useTranslation();
  const { control } = useFormContext<DatesAndConfigurationForm>();
  const titleCompanyRequest = useQuery(
    ['getCompany', titleCompany?._id],
    () => getParty({ id: titleCompany?._id! }),
    {
      enabled: !!titleCompany?._id,
      refetchOnWindowFocus: false,
    },
  );

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item xs={12}>
        <Title>{t('datesAndConfig:place-of-closing')}</Title>
      </Grid>
      {titleCompany?.name
        && (
          <Grid item xs={12}>
            <Typography variant="body2" style={{ fontWeight: 500 }}>{`Addresses taken from: ${titleCompany?.name}`}</Typography>
          </Grid>
        )}
      <Grid item xs={12}>
        <Controller
          name="closing_place"
          control={control}
          render={({ field }) => (
            <SelectField
              value={field.value!}
              label={t('common:address')}
              data={titleCompanyRequest.data?.getParty.addresses || []}
              dataKey="_id"
              dataValue="_id"
              dataText="address"
              handleBlur={field.onBlur}
              handleChange={(e) => {
                field.onChange(e);
              }}
              disabled={!titleCompanyRequest.data?.getParty.addresses.length}
            />
          )}
        />
      </Grid>
    </Grid>
  );
};

export default memo(PlaceOfClosing);
