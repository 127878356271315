import { Box, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import BackdropLoading from 'components/common/BackdropLoading';
import VoidOrders from 'components/order-void-screen/orders-void-screen';
import { TFunction } from 'i18next';
import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { WorkspaceRoutes } from 'types/common';
import { OrderProperty } from 'types/order';
import {
  DRAWER_ORDERS_WIDTH,
  ERROR,
  IDLE,
  LOADING,
  NARRATIVE,
  OVERVIEW,
  QUESTIONS_AND_ANSWERS,
  ROUTE_CHARGES,
  ROUTE_CLOSING,
  ROUTE_DATES_AND_CONFIG,
  ROUTE_DISBURSEMENTS_AND_RECEIPTS,
  ROUTE_DOCUMENTS,
  ROUTE_EARNEST_COMMISSIONS,
  ROUTE_PARTIES,
  ROUTE_PAYMENTS,
  ROUTE_PAYOFFS,
  ROUTE_POLICY_INFO_AND_RATES,
  ROUTE_PROPERTIES,
  ROUTE_TAXES_AND_PRORATIONS,
} from 'utils/constants';
import { NewOrderPartyType } from 'v2-types/order';
import { CashToClose } from 'views/orders/cash-to-close';
import ChargesEPrototype from 'views/orders/charges-accounting/charges-e/charges-e';
import ChargesCPrototype from 'views/orders/charges-accounting/charges-c/charges-c';
import ChargesJPrototype from 'views/orders/charges-accounting/charges-j/charges-j';
import ChargesJK from 'views/orders/charges-accounting/charges-jk/charges-jk';
import ChargesKMPrototype from 'views/orders/charges-accounting/charges-km/charges-km';
import ChargesL from 'views/orders/charges-accounting/charges-l/charges-l';
import ChargesLNPrototype from 'views/orders/charges-accounting/charges-ln/charges-ln';
import DatesAndConfig from 'views/orders/dates-and-config/dates-and-config';
import DisbursementsAndReceipts from 'views/orders/disbursements-receipts/disbursements-receipts';
import Documents from 'views/orders/documents/new_documents/documents';
import EarnestAndCommissions from 'views/orders/earnest-and-commissions/earnest-and-commissions';
import { Loans } from 'views/orders/loans';
import MortgagePayoffs from 'views/orders/mortgage-payoffs/mortgage-payoffs';
import { Narrative } from 'views/orders/narrative';
import NewParties from 'views/orders/new-parties/new-parties';
import { Overview } from 'views/orders/overview/overview';
import { WireTransfers } from 'views/orders/payments/wire-tranfers';
import PolicyInfoAndRates from 'views/orders/policy-info-and-rates-refactor/policy-info-refactor';
import ProjectedPayments from 'views/orders/projected-payments/projected-payments';
import Properties from 'views/orders/properties/order-flow-properties';
import ScheduleRon from 'views/orders/ron-invite-screen/ron-invite-screen';
import { TaxesAndProrations } from 'views/orders/taxes-and-prorations';

import Accounting from '../../views/orders/accounting/accounting';
import InformationBar from './components/information-bar/information-bar';
import TabOrderOpen from './components/order-overview-tabs';
import Sidemenu from './components/sidemenu';
import ChargesA from 'views/orders/new-charges/charges-a/charges-a';
import ChargesB from 'views/orders/new-charges/charges-b/charges-b';
import ChargesC from 'views/orders/new-charges/charges-c/charges-c';
import ChargesE from 'views/orders/new-charges/charges-e/charges-e';
import ChargesF from 'views/orders/new-charges/charges-f/charges-f';
import ChargesH from 'views/orders/new-charges/charges-h/charges-h';
import ChargesG from 'views/orders/new-charges/charges-g/charges-g';
import { useWorkspaceContext } from 'contexts/workspace-context';
import { QuestionsAndAnswersLLMProxy } from 'views/orders/questions-and-answers-llm-proxy/questions-and-answers-llm-proxy';

type OrdersLayoutSceneProps = {
  t: TFunction;
  sidemenuOpen: boolean;
  setSidemenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
  statusFetchOrderData: string;
  handleViewChange: (view: WorkspaceRoutes) => void;
  properties: OrderProperty[];
  parties: NewOrderPartyType[];
  orderHasAccountingData: boolean;
  isOrderLoading: boolean;
  orderType: string;
  idTitleCompany: string;
};

const useStyles = makeStyles((theme: any) => ({
  containerOpen: { marginLeft: DRAWER_ORDERS_WIDTH },
  containerClose: { marginLeft: theme.spacing(7) + 1 },
}));

const OrdersLayoutScene = ({
  t,
  sidemenuOpen,
  setSidemenuOpen,
  statusFetchOrderData,
  handleViewChange,
  properties,
  parties,
  orderHasAccountingData,
  isOrderLoading,
  orderType,
  idTitleCompany,
}: OrdersLayoutSceneProps) => {
  const { path } = useRouteMatch();
  const classes = useStyles();
  const { openedOrderIdList } = useWorkspaceContext();
  if (statusFetchOrderData === LOADING) return <BackdropLoading open loadingText="loading" />;

  if (openedOrderIdList.length === 0 || statusFetchOrderData === (ERROR || IDLE)) return <VoidOrders />;

  return (
    <>
      <Sidemenu
        t={t}
        sidemenuOpen={sidemenuOpen}
        setSidemenuOpen={setSidemenuOpen}
        handleViewChange={handleViewChange}
      />

      <Box
        className={clsx({
          [classes.containerOpen]: sidemenuOpen,
          [classes.containerClose]: !sidemenuOpen,
        })}
      >
        <TabOrderOpen />

        <InformationBar t={t} properties={properties} isOrderLoading={isOrderLoading} />

        <Switch>
          <Route path={`${path}/${OVERVIEW}`}>
            <Overview
              properties={properties}
              parties={parties}
              isOrderLoading={isOrderLoading}
              orderType={orderType}
            />
          </Route>

          <Route path={`${path}/${ROUTE_DATES_AND_CONFIG}`}>
            <DatesAndConfig orderHasAccountingData={orderHasAccountingData} />
          </Route>

          <Route path={`${path}/${ROUTE_PROPERTIES}`}>
            <Properties />
          </Route>

          <Route path={`${path}/${ROUTE_PARTIES}`}>
            <NewParties />
          </Route>
          <Route exact path={`${path}/${ROUTE_EARNEST_COMMISSIONS}`}>
            <EarnestAndCommissions />
          </Route>

          <Route path={`${path}/${ROUTE_PAYOFFS}`}>
            <MortgagePayoffs />
          </Route>

          <Route exact path={`${path}/${ROUTE_DOCUMENTS}`}>
            <Documents />
          </Route>

          <Route exact path={`${path}/loans/:loanId?`}>
            <Loans properties={properties} />
          </Route>

          <Route exact path={`${path}/${ROUTE_TAXES_AND_PRORATIONS}`}>
            <TaxesAndProrations properties={properties} />
          </Route>

          <Route exact path={`${path}/${QUESTIONS_AND_ANSWERS}`}>
            <QuestionsAndAnswersLLMProxy />
          </Route>

          <Route exact path={`${path}/${NARRATIVE}`}>
            <Narrative />
          </Route>

          <Route exact path={`${path}/${ROUTE_POLICY_INFO_AND_RATES}`}>
            <PolicyInfoAndRates />
          </Route>
          <Route exact path={`${path}/${ROUTE_CHARGES}/a/prototype`}>
            <ChargesEPrototype />
          </Route>
          <Route exact path={`${path}/${ROUTE_CHARGES}/b/prototype`}>
            <ChargesCPrototype />
          </Route>
          <Route exact path={`${path}/${ROUTE_CHARGES}/a`}>
            <ChargesA />
          </Route>
          <Route exact path={`${path}/${ROUTE_CHARGES}/b`}>
            <ChargesB />
          </Route>

          <Route exact path={`${path}/${ROUTE_CHARGES}/c`}>
            <ChargesC />
          </Route>

          <Route exact path={`${path}/${ROUTE_CHARGES}/e`}>
            <ChargesE />
          </Route>
          <Route exact path={`${path}/${ROUTE_CHARGES}/f`}>
            <ChargesF />
          </Route>

          <Route exact path={`${path}/${ROUTE_CHARGES}/g`}>
            <ChargesG />
          </Route>

          <Route exact path={`${path}/${ROUTE_CHARGES}/h`}>
            <ChargesH />
          </Route>

          <Route exact path={`${path}/${ROUTE_CHARGES}/j`}>
            <ChargesJPrototype />
          </Route>

          <Route exact path={`${path}/${ROUTE_CHARGES}/k-m`}>
            <ChargesKMPrototype />
          </Route>

          <Route exact path={`${path}/${ROUTE_CHARGES}/l-n`}>
            <ChargesLNPrototype />
          </Route>

          <Route exact path={`${path}/${ROUTE_CHARGES}/l`}>
            <ChargesL />
          </Route>
          <Route exact path={`${path}/${ROUTE_CHARGES}/j-k`}>
            <ChargesJK />
          </Route>

          <Route exact path={`${path}/projected-payments`}>
            <ProjectedPayments />
          </Route>

          <Route exact path={`${path}/cash-to-close`}>
            <CashToClose />
          </Route>

          <Route exact path={`${path}/${ROUTE_PAYMENTS}/wire-transfers`}>
            <WireTransfers idTitleCompany={idTitleCompany} />
          </Route>

          <Route exact path={`${path}/${ROUTE_DISBURSEMENTS_AND_RECEIPTS}`}>
            <DisbursementsAndReceipts />
          </Route>

          <Route exact path={`${path}/${ROUTE_PAYMENTS}/accounting`}>
            <Accounting />
          </Route>

          <Route exact path={`${path}/${ROUTE_CLOSING}/schedule-closing`}>
            <ScheduleRon />
          </Route>
        </Switch>
      </Box>
    </>
  );
};

export default OrdersLayoutScene;
