import { Grid, makeStyles, Typography } from '@material-ui/core';
import SaveButton from 'components/common/save-button';
import { TFunction } from 'i18next';
import React from 'react';

const useStyles = makeStyles((theme: any) => ({
  headerContainer: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    borderBottomColor: theme.accent.main,
    borderBottomStyle: 'solid',
    borderBottomWidth: 1,
  },
  headerTitle: {
    fontWeight: 500,
    color: theme.palette.primary.main,
  },
}));

type HeaderProps = {
  t: TFunction;
  isValid: boolean;
  loading?: boolean
};

const Header = ({ t, isValid, loading }: HeaderProps) => {
  const classes = useStyles();

  return (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      className={classes.headerContainer}
    >
      <Grid item>
        <Typography variant="h5" className={classes.headerTitle}>
          {t('disclosure:projected-payments')}
        </Typography>
      </Grid>

      <Grid item>
        <SaveButton
          disabled={!isValid}
          type="submit"
          loading={loading}
        />
      </Grid>
    </Grid>
  );
};

export default Header;
