import { useCallback } from "react";
import { useQueryCache } from "react-query";
import { GQLGetOrder } from "services/querys/orders";
import { capitalize } from "utils/helpers";
import { NewOrderPartyType } from "types/order";
import { updatePartyCache } from ".";
import { useHistory, useParams, useRouteMatch } from "react-router-dom";
import useAlert from "utils/alert";
import { useTranslation } from "react-i18next";

const useUpdateParties = (shouldRedirect = true) => {
  const showAlert = useAlert();
  const history = useHistory();
  const { t } = useTranslation();
  const { url } = useRouteMatch();
  const { id: orderId } = useParams<{ id: string }>();
  const queryCache = useQueryCache();

  const updateParties = useCallback((parties:NewOrderPartyType[]) => {
    if (shouldRedirect) {
      history.push(url);
    }
    showAlert(capitalize(t('dialogs:order-updated')), 'success');
    queryCache.invalidateQueries(['party-order', orderId]);
    queryCache.setQueryData<GQLGetOrder | undefined>(
      ['order-parties', orderId],
      (orderData) => updatePartyCache({ orderData, parties })
    );
  }, [history, orderId, queryCache, shouldRedirect, showAlert, t, url]);

  return {
    updateParties
  };
};

export default useUpdateParties;