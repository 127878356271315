import { useEffect } from "react";
import useCreateAssociation from "./use-create-association";
import useDeleteAssociation from './use-delete-association';
import useUpdateParties from "./use-update-parties";
import { useParams } from "react-router-dom";
import useAlert from "utils/alert";

const useChangeAssociationHooks = (partyId:string, kindRemove:string) => {
  const { id: orderId } = useParams<{ id: string }>();
  const { updateParties } = useUpdateParties(false);
  const showAlert = useAlert();
  const { deleteAssociation, responseDeleteAssociation } = useDeleteAssociation((deleteData) => { updateParties(deleteData.deleteOrderAssociation?.parties || []); });
  const { createAssociation, responseCreateAssociation } = useCreateAssociation(() => {
    deleteAssociation({ id: orderId!, partyId, kind: kindRemove });
  });

  useEffect(() => {
    if (responseDeleteAssociation.isLoading) {
      showAlert('Removing Previous Association', 'info');
    }
  }, [responseDeleteAssociation.isLoading, showAlert]);

  useEffect(() => {
    if (responseCreateAssociation.isLoading) {
      showAlert('Creating New Association', 'info');
    }
  }, [responseCreateAssociation.isLoading, showAlert]);

  return {
    createAssociation,
    responseCreateAssociation
  };
};

export default useChangeAssociationHooks;