import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  makeStyles,
  Typography
} from '@material-ui/core';
import SelectField from 'components/select-field';
import React, { FC } from 'react';
import {
  documentCalculation,
  formDocumentCalculation,
  pricePagesName
} from './services';
import { Controller, UseFormReturn } from 'react-hook-form';
import NumberField from 'components/number-field';
import { useTranslation } from 'react-i18next';
import TextField from 'components/text-field';
import SaveButton from 'components/common/save-button';
import { Alert } from '@material-ui/lab';

const useStyles = makeStyles((theme: any) => ({
  container: { padding: theme.spacing(3) },
  removecontentPadding: { '& .MuiDialogContent-root': { padding: 0 } },
  nocontendPadding: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  text: {
    textTransform: 'none',
    color: theme.palette.primary.main,
    fontWeight: 500,
  },
  itempaddinfoother: {
    '& > * ': {
      marginLeft: theme.spacing(2)
    }
  },
  itempaddinType: {
    '& > * ': { marginTop: theme.spacing(2) },
    padding: theme.spacing(3),
    margin: 0,
  },
  errorIconContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
}));

type documentCalculationFees = {
  open: boolean,
  onClose: () => void,
  methods: UseFormReturn<formDocumentCalculation, any, undefined>
  loading: boolean,
  handleEntry: (e?: React.BaseSyntheticEvent) => Promise<void>;
  errors: string[];
}

const DocumentModalCalculationScene: FC<documentCalculationFees> = ({
  open,
  onClose,
  methods,
  handleEntry,
  loading,
  errors
}) => {
  const { watch, setValue, control } = methods;
  const [CurrentTDI, numberName, numberPages] = watch(["currentTDI", "numberOfNames", "numberOfPages"]);
  const totalPrices = pricePagesName(CurrentTDI, numberName, numberPages, setValue);
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Dialog
      open={open}
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          onClose();
        }
      }}
      fullWidth
      className={classes.removecontentPadding}
    >
      <form onSubmit={handleEntry}>
        <DialogContent className={classes.nocontendPadding}>
          <Grid container className={classes.itempaddinType} direction="column">
            <Grid item xs={12}>
              <Typography variant="h6" style={{ color: 'black' }}>
                Add New Document
              </Typography>
            </Grid>
            {!!errors.length && (
              <Grid item xs={12}>
                <Alert severity="error" classes={{ icon: classes.errorIconContainer }}>
                  {errors.map((error) => (
                    <Typography variant="subtitle1">{error}</Typography>
                  ))}
                </Alert>
              </Grid>
            )}
            <Grid item xs={12}>
              <Grid container direction="row" spacing={2}>
                <Grid item xs={6}>
                  <Grid container direction="column" spacing={2}>
                    <Grid item xs={12}>
                      <Controller
                        control={control}
                        name="currentTDI"
                        render={({ field }) => (<SelectField
                          value={field.value?.countyName || ""}
                          data={documentCalculation}
                          dataKey="countyName"
                          dataValue="countyName"
                          dataText={["tdiCountyCode", "countyName"]}
                          label="Select County (TDI Code)"
                          handleChange={(event) => {
                            const currentCalculation = documentCalculation.find(item => item.countyName === event.target.value);
                            field.onChange(currentCalculation);
                          }}
                        />)}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Controller
                        control={control}
                        name="numberOfPages"
                        render={({ field }) => (
                          <NumberField
                            {...field}
                            label="Number of Pages"
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Controller
                        control={control}
                        name="numberOfNames"
                        render={({ field }) => (
                          <NumberField
                            {...field}
                            label="Number of Names"
                          />
                        )}
                      />
                    </Grid>

                  </Grid>
                </Grid>
                <Grid item xs={6}>
                  <Grid container direction="column">
                    <Grid item xs={12}>
                      <Typography variant="body1" style={{ color: 'black', marginBottom: 4 }}>Prices of the TDI selected:</Typography>
                      <Typography variant="body2" style={{ color: 'black' }}>{`Price of first Page: ${totalPrices.firstPage}`}</Typography>
                      <Typography variant="body2" style={{ color: 'black' }}>{`Price per Page: ${CurrentTDI?.eachAdditionalPage || 0}`}</Typography>
                      <Typography variant="body2" style={{ color: 'black' }}>{`Extra Pages: ${numberPages > 0 ? numberPages - 1 : 0}`}</Typography>
                      <Typography variant="body2" style={{ color: 'black' }}>{`Price of Additional Pages: ${totalPrices.additionalPages}`}</Typography>
                      <Typography variant="body2" style={{ color: 'black' }}>{`Names Included: ${CurrentTDI?.namesIncluded || 0}`}</Typography>
                      <Typography variant="body2" style={{ color: 'black' }}>{`Price per extra Name: ${CurrentTDI?.eachAdditionalName || 0}`}</Typography>
                      <Typography variant="body2" style={{ color: 'black' }}>{`Price of Additional Names: ${totalPrices.additionalNames}`}</Typography>

                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="amount"
                control={control}
                render={({ field: { ref, ...field } }) => (
                  <TextField
                    {...field}
                    inputRef={ref}
                    label={t('accounting:amount')}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="description"
                control={control}
                render={({ field: { ref, ...field } }) => (
                  <TextField
                    {...field}
                    inputRef={ref}
                    label={t('accounting:description')}
                  />
                )}
              />
            </Grid>

          </Grid>
        </DialogContent>
        <DialogActions disableSpacing style={{ padding: 16 }} className={classes.itempaddinfoother}>
          <Button
            color="primary"
            variant="outlined"
            disableElevation
            disabled={loading}
            onClick={onClose}
          >
            <Typography variant="body2" className={classes.text}>
              {t('common:close')}
            </Typography>
          </Button>
          <SaveButton
            type="submit"
            customText={t('accounting:add-entry')}
            loading={loading}
          />
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default DocumentModalCalculationScene;
