import { ChatOpenAI } from "@langchain/openai";
import { TOKEN_CLAIMS } from "utils/constants";
import {
  AgentExecutor,
  createToolCallingAgent,
} from "langchain/agents";
import {
  ChatPromptTemplate,
} from "@langchain/core/prompts";
import {
  StructuredOutputParser,
} from "langchain/output_parsers";
import { z } from "zod";

import {
  RunnableWithMessageHistory,
} from "@langchain/core/runnables";

import { ChatMessageHistory } from "langchain/memory";
import { ChainValues } from "@langchain/core/utils/types";
import { propertiesToolsZodSchema } from "./schemas/propertiesViewTools";
import { defaultZodSchema } from "./schemas/default";
import { asyncForEach } from "utils/helpers";
import { loansToolsZodSchema } from "./schemas/loansViewTools";
import { AgentTool, ToolFunction } from "./types";

const memory = new ChatMessageHistory();

export class LlmProxyService {
  private llm: ChatOpenAI;

  private agentExecutorWithMemory: RunnableWithMessageHistory<Record<string, any>, ChainValues>;

  private config: { configurable: { sessionId: "test-session" } };

  private parser: StructuredOutputParser<any>;

  private orderId: string;

  private currentOrderNarrative: string;

  private currentViewInformation: {id: string, text: string}[];

  private currentTools: ToolFunction[];

  constructor(narrative?: string) {
    const access_token = localStorage.getItem(TOKEN_CLAIMS);

    this.llm = new ChatOpenAI({
      configuration: {
        baseURL: process.env.REACT_APP_LLM_PROXY,
      },
      modelName: "claude-3-5",
      openAIApiKey: access_token || "",
      temperature: 0.9
    });

    this.orderId = "";
    this.currentOrderNarrative = narrative ?? "";
    this.currentViewInformation = [];
    this.currentTools = [];

    this.parser = StructuredOutputParser.fromZodSchema(defaultZodSchema);
    const prompt = ChatPromptTemplate.fromMessages([
      ["system", "You are a chat assistant that will act as an agent, you can talk with users and evaluate when to use the available tools"],
      ["system", "{format_question}"],
      ["system", "This is some information about the current order that may the user asks: {narrative}"],
      ["system", `This is the orderId: {orderId}`],
      ["placeholder", "{chat_history}"],
      ["user", "{input}"],
      ["placeholder", "{agent_scratchpad}"],
    ]);

    const agent = createToolCallingAgent({ llm: this.llm, tools: [], prompt });
    const agentExecutor = new AgentExecutor({ agent, tools: [] });

    this.agentExecutorWithMemory = new RunnableWithMessageHistory({
      runnable: agentExecutor,
      getMessageHistory: () => memory,
      inputMessagesKey: "input",
      historyMessagesKey: "chat_history",
    });

    this.config = { configurable: { sessionId: "test-session" } };
  }

  private useAgentTool = async (agentTool: AgentTool) => {
    let toolResponseMessage = '';
    const { tool } = agentTool;

    const toolToInvoke = this.currentTools.find(({ name }) => name === tool);
    if (toolToInvoke) {
      toolResponseMessage = await toolToInvoke.tool(agentTool);
    }

    return toolResponseMessage;
  }

  invoke = async (question: string, onMessageResponse: (message: string) => void) => {
    let llmResponse = '';
    try {
      const response = await this.agentExecutorWithMemory.invoke(
        {
          input: question,
          format_question: this.parser.getFormatInstructions(),
          narrative: this.currentOrderNarrative + this.currentViewInformation.map(info => info.text).join('\n'),
          orderId: this.orderId
        },
        this.config
      );

      if (response.output && response.output.includes("```json")) {
        const match = response.output.toString().match(/({[\s\S]*})/);

        if (!match) return response.output;

        const content: {
          answer: string | null,
          toolsToUse: AgentTool[]
        } = await JSON.parse(match[1]);

        onMessageResponse(`${content.answer!}\n`);
        if (content.toolsToUse && content.toolsToUse.length > 0) {
          const toolResponseList: string[] = [];
          await asyncForEach(content.toolsToUse, async (toolToUse) => {
            const toolMessageResponse = await this.useAgentTool(toolToUse);
            toolResponseList.push(toolMessageResponse);
          });

          memory.addAIMessage(toolResponseList.join(", "));
          onMessageResponse(toolResponseList.join(", "));

          return `${content.answer}\n${toolResponseList.join(", ")}`;
        }

        llmResponse += content.answer ?? '';
      } else {
        llmResponse += response.output;
      }
    } catch (error) {
      return "Unexpected error occurred.";
    }
    return llmResponse;
  }

  setOrderId = (orderId: string) => {
    this.orderId = orderId;
  }

  setCurrentTools = (toolFunctions: ToolFunction[]) => {
    this.currentTools = toolFunctions;
  };

  addCurrentTool = (toolFunction: ToolFunction) => {
    this.currentTools.push(toolFunction);
  }

  setCurrentAppLocation = (pathname: string) => {
    let zodSchema: z.ZodTypeAny = defaultZodSchema;
    if (pathname.includes("properties")) {
      zodSchema = propertiesToolsZodSchema;
    }

    if (pathname.includes("loans")) {
      zodSchema = loansToolsZodSchema;
    }

    this.parser = StructuredOutputParser.fromZodSchema(zodSchema);
  }

  setCurrentOrderNarrative = (narrative: string) => {
    this.currentOrderNarrative = narrative;
  }

  setViewInformation = (info: { id: string, text: string}) => {
    const previousItemIndex = this.currentViewInformation.findIndex((infoItem) => infoItem.id === info.id);
    if (previousItemIndex > -1) this.currentViewInformation[previousItemIndex].text = info.text;
    else this.currentViewInformation.push(info);
  }

  removeViewInformationItem = (id: string) => {
    this.currentViewInformation = this.currentViewInformation.filter((info) => info.id !== id);
  }
}
