import { UseFormSetValue } from "react-hook-form";

export const documentCalculation = [
  {
    tdiCountyCode: 453,
    countyName: "Travis",
    firstPage: 25.00,
    eachAdditionalPage: 4.00,
    namesIncluded: 5,
    eachAdditionalName: 0.25
  },
  {
    tdiCountyCode: 491,
    countyName: "Williamson",
    firstPage: 25.00,
    eachAdditionalPage: 4.00,
    namesIncluded: 5,
    eachAdditionalName: 0.25
  },
  {
    tdiCountyCode: 7,
    countyName: "Aransas",
    firstPage: 25.00,
    eachAdditionalPage: 4.00,
    namesIncluded: 5,
    eachAdditionalName: 0.25
  },
  {
    tdiCountyCode: 215,
    countyName: "Hidalgo",
    firstPage: 35.00,
    eachAdditionalPage: 4.00,
    namesIncluded: 5,
    eachAdditionalName: 0.25
  },
  {
    tdiCountyCode: 61,
    countyName: "Cameron",
    firstPage: 35.00,
    eachAdditionalPage: 4.00,
    namesIncluded: 5,
    eachAdditionalName: 0.25
  },
  {
    tdiCountyCode: 209,
    countyName: "Hays",
    firstPage: 25.00,
    eachAdditionalPage: 4.00,
    namesIncluded: 5,
    eachAdditionalName: 0.25
  },
  {
    tdiCountyCode: 409,
    countyName: "San Patricio",
    firstPage: 25.00,
    eachAdditionalPage: 4.00,
    namesIncluded: 5,
    eachAdditionalName: 0.25
  },
  {
    tdiCountyCode: 355,
    countyName: "Nueces",
    firstPage: 20.00,
    eachAdditionalPage: 4.00,
    namesIncluded: 5,
    eachAdditionalName: 0.25
  },
  {
    tdiCountyCode: 29,
    countyName: "Bexar",
    firstPage: 25.00,
    eachAdditionalPage: 4.00,
    namesIncluded: 5,
    eachAdditionalName: 0.25
  },
  {
    tdiCountyCode: 91,
    countyName: "Comal",
    firstPage: 25.00,
    eachAdditionalPage: 4.00,
    namesIncluded: 5,
    eachAdditionalName: 0.25
  },
  {
    tdiCountyCode: 187,
    countyName: "Guadalupe",
    firstPage: 25.00,
    eachAdditionalPage: 4.00,
    namesIncluded: 5,
    eachAdditionalName: 0.25
  },
  {
    tdiCountyCode: 27,
    countyName: "Bell",
    firstPage: 10.00,
    eachAdditionalPage: 4.00,
    namesIncluded: 5,
    eachAdditionalName: 0.25
  },
  {
    tdiCountyCode: 55,
    countyName: "Caldwell",
    firstPage: 25.00,
    eachAdditionalPage: 4.00,
    namesIncluded: 5,
    eachAdditionalName: 0.25
  },
  {
    tdiCountyCode: 99,
    countyName: "Coryell",
    firstPage: 25.00,
    eachAdditionalPage: 4.00,
    namesIncluded: 5,
    eachAdditionalName: 0.25
  },
  {
    tdiCountyCode: 469,
    countyName: "Victoria",
    firstPage: 20.00,
    eachAdditionalPage: 4.00,
    namesIncluded: 5,
    eachAdditionalName: 0.25
  }
];

export type tdicountyType = {
  tdiCountyCode: number,
  countyName: string,
  firstPage: number,
  eachAdditionalPage: number,
  namesIncluded: number,
  eachAdditionalName: number
}

export type formDocumentCalculation = {
  currentTDI: tdicountyType | null,
  numberOfPages: number,
  numberOfNames: number,
  code: string,
  kind: string,
  letter: string,
  amount: number,
  description: string
}

export const pricePagesName = (tdiCounty: tdicountyType | null, names: number, pages: number, setValue: UseFormSetValue<formDocumentCalculation>) => {
  const firstPage = tdiCounty?.firstPage || 0;
  const additionalPages = (tdiCounty?.firstPage && pages > 0) ? ((pages - 1) * tdiCounty?.eachAdditionalPage) : 0;
  const additionalNames = (tdiCounty?.eachAdditionalName && names > tdiCounty.namesIncluded) ? ((names - tdiCounty.namesIncluded) * tdiCounty.eachAdditionalName) : 0;
  setValue('amount', additionalNames + additionalPages + firstPage);
  return {
    firstPage,
    additionalPages,
    additionalNames
  };
};
