import { Checkbox, FormControlLabel, Grid, TextField, Typography } from "@material-ui/core";
import React, { FC, useRef } from "react";
import { ContainedButton } from "components/ContainedButton";
import { useStyles } from "./styles";

type Props = {
  contentIsLoading: boolean
  onSendPrompt: (prompt: string) => void,
  isRecording: boolean,
  stopRecordAudio: () => void,
  startRecordAudio: () => void
}

export const PromptInput: FC<Props> = ({
  contentIsLoading,
  onSendPrompt,
  isRecording,
  stopRecordAudio,
  startRecordAudio
}) => {
  const questionInputRef = useRef<HTMLInputElement>(null);
  const classes = useStyles();
  return (
    <Grid
      item
      container
      className={classes.messageContainer}
      alignItems="flex-end"
    >
      <Grid
        item
        container
        direction="column"
        style={{ gap: '5px' }}
      >
        <Grid item>
          <Grid
            item
            container
            className={classes.message}
            direction="column"
            spacing={1}
          >
            <Grid item>
              <TextField
                fullWidth
                multiline
                placeholder="Assistant"
                inputRef={questionInputRef}
                InputProps={{
                  disableUnderline: true,
                  style: { color: '#000000' },
                  onKeyDown: (e) => {
                    if (e.key === 'Enter' && !e.shiftKey && !contentIsLoading) {
                      e.preventDefault();
                      onSendPrompt(questionInputRef.current!.value);
                      questionInputRef.current!.value = '';
                    }
                  }
                }}
              />
            </Grid>
            <Grid
              item
              container
              wrap="nowrap"
            >
              <Grid item>
                <FormControlLabel
                  control={(
                    <Checkbox
                      style={{ padding: 0 }}
                      defaultChecked
                    />
                  )}
                  label={<Typography variant="caption" color="primary">Do you want audio?</Typography>}
                  style={{ marginLeft: 0, whiteSpace: 'nowrap' }}
                />
              </Grid>
              <Grid item container justifyContent="flex-end" spacing={1}>
                <Grid item>
                  <ContainedButton
                    size="small"
                    icon="paper-plane"
                    text="Send"
                    onClick={() => {
                      onSendPrompt(questionInputRef.current!.value);
                      questionInputRef.current!.value = '';
                    }}
                    disabled={contentIsLoading}
                  />
                </Grid>
                <Grid item>
                  <ContainedButton
                    size="small"
                    icon={isRecording ? 'circle' : 'microphone'}
                    text={isRecording ? 'Recording...' : 'Record'}
                    onClick={isRecording ? stopRecordAudio : startRecordAudio}
                    disabled
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Typography variant="caption" color="primary" display="block" align="right">
            Shift + Return to add a new line
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};
