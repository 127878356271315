import { OrderProperty } from 'types/order';

export const initialValues: OrderProperty = {
  _id: '',
  address: {
    _id: '',
    kind: '',
    address: '',
    street_address: '',
    references: '',
    settlement: '',
    postal_code: null,
    locality: '',
    state: 'TX',
    country: 'US',
    geo: {
      type: '',
      coordinates: []
    }
  },
  parcel_ids: [{ value: '' }],
  block: '',
  jurisdiction: '',
  brief_legal_description: '',
  legal_description: '',
  lot: '',
  range: '',
  section: '',
  subdivision: '',
  survey_date: '',
  survey_information: '',
  survey_number: '',
  township: '',
  type: '',
  accounting: []
};
