/* eslint-disable radix */
import React, { FC, useCallback, useMemo } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { formType } from '../services';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Grid,
  IconButton,
  makeStyles,
  Tabs,
  Typography,
} from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { BaseButton } from 'components/BaseButton';
import { useMutation } from 'react-query';
import { useParams } from 'react-router-dom';
import { AccountingCharge } from 'views/orders/new-charges/types';
import { TabContext } from '@material-ui/lab';
import CustomTab from 'components/common/custom-tab';
import FormExistingCharges from './form-existing-charges';
import CustomTabPanel from './custonTabPanel';
import useAlert from 'utils/alert';
import { updateCharges } from 'views/orders/new-charges/services/mutations';

type formchargesProps = {
  charges: any[],
  onClose: () => void,
  localCharges: AccountingCharge[]
}

const FormTabsCharges: FC<formchargesProps> = ({
  charges,
  onClose,
  localCharges
}) => {
  const { t } = useTranslation();
  const [value, setValue] = React.useState('A');
  const showAlert = useAlert();
  const { id: orderId } = useParams<{ id: string }>();
  const [setUpdateCharges, updateChargesResponse] = useMutation(updateCharges, {
    onSuccess: (data) => {
      if (data) {
        onClose();
        window.location.reload();
        showAlert(t('charges:success-updated'), 'success');
      }
    },
    onError: () => {
      showAlert(t('documents:error-updated'), 'error');
    },
  });

  const methods = useForm<formType>({
    defaultValues: {
      charges: charges.filter(c => c.letter !== undefined).map((charge) => {
        const previousCharge = localCharges.find(data => data?.letter === charge?.letter && parseInt(data?.number as unknown as string, 10) === charge?.number);
        return {
          number: charge?.number || 0,
          description: charge?.description || '',
          letter: charge?.letter || '',
          payeeText: charge?.payee || '',
          payee: {
            name: previousCharge?.payee || '',
            id: previousCharge?.payee_id || ''
          },
          by_others: charge?.by_others || 0,
          seller: {
            at_closing: charge?.seller_at_closing || 0,
            before_closing: charge?.seller_before_closing || 0
          },
          buyer: {
            at_closing: charge?.borrower_at_closing || 0,
            before_closing: charge?.borrower_before_closing || 0
          },
          previousCharge
        };
      }),
    }
  });
  const { control, handleSubmit } = methods;

  const { fields } = useFieldArray({ control, name: 'charges' });
  const letters = useMemo(() => fields.map((item) => ({ letter: item.letter })).reduce((accumulator: any[], item) => {
    if (!accumulator.find(i => i.letter === item.letter) && item.letter !== '') {
      accumulator.push(item);
    }
    return accumulator;
  }, []), [fields]);
  const handleCreateOrderAI = useCallback((data: formType) => {
    setUpdateCharges({
      id: orderId,
      data: data.charges.map((charge) => ({
        accounting_id: charge?.previousCharge?.accounting_id || null,
        description: charge?.description || '',
        payee_id: charge?.payee?.id || '',
        letter: charge?.letter,
        tax: Number(charge?.previousCharge?.tax) || 0,
        per_month: Number(charge?.previousCharge?.per_month) || 0,
        months: Number(charge?.previousCharge?.months) || 0,
        buyer_at: Number(charge?.buyer?.at_closing) || 0,
        buyer_before: Number(charge?.buyer?.before_closing) || 0,
        seller_at: Number(charge?.seller?.at_closing) || 0,
        seller_before: Number(charge?.seller?.before_closing) || 0,
        by_others: Number(charge?.by_others) || 0,
        number: Number(charge?.number) || 0
      })) as any[],
    });
  }, [orderId, setUpdateCharges]);

  return (
    <form onSubmit={handleSubmit((data) => handleCreateOrderAI(data))}>
      <Grid container direction="column" spacing={4}>

        <Grid item xs={12}>
          <Grid container direction="row">
            <Grid item xs={11} style={{ textAlign: 'center' }}>
              <Typography variant="h6" style={{ color: 'black', marginBottom: 16 }}>
                {t('charges:charges-collected')}
              </Typography>
              <Typography variant="body2" style={{ color: 'black' }}>
                {t('charges:verify-charges')}
              </Typography>
            </Grid>
            <Grid item xs={1}>
              <IconButton
                style={{ padding: 0 }}
                onClick={(e) => {
                  e.stopPropagation();
                  onClose();
                }}
              >
                <FontAwesomeIcon icon="times" size="xs" />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs
                orientation="horizontal"
                variant="scrollable"
                value={value}
                onChange={(_, param) => setValue(param)}
              >
                {letters.map(letter => (
                  <CustomTab
                    key={letter?.letter}
                    currentValue={letter?.letter}
                    value={letter?.letter}
                    text={`Charges ${letter?.letter}`}
                  />
                ))}
              </Tabs>
            </Box>
            {letters.map(letter => (
              <CustomTabPanel value={value} index={letter.letter} key={`list-charges-${letter.letter}`}>
                <FormExistingCharges
                  methods={methods}
                  letter={letter.letter}
                />
              </CustomTabPanel>
            ))}
          </TabContext>
        </Grid>
        <Grid item xs={12}>
          <Grid container direction="row" justifyContent="flex-end" spacing={2}>
            <Grid item>
              <BaseButton
                text="Continue"
                type="submit"
                isLoading={updateChargesResponse.isLoading}
              />
            </Grid>
            <Grid item>
              <BaseButton
                buttonVariant="outlined"
                buttonColor="primary"
                text="Cancel"
                onClick={(e) => {
                  e.stopPropagation();
                  onClose();
                }}
                disabled={updateChargesResponse.isLoading}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

export default FormTabsCharges;
