import { z } from "zod";
import { defaultZodSchema } from "./default";

const toolSchema = z.discriminatedUnion("tool", [
  z.object({
    tool: z.literal('createNewLoan').describe("this tool is usefull to create a new loan"),
    toolParams: z.object({
      orderId: z.string().describe("This is the orderId of the order"),
      propertyId: z.string().describe("This is the propertyId"),
      loanNumber: z.string()
    }).describe("Tool parameters")
  }),
]).nullable();

export const loansToolsZodSchema = z.intersection(
  defaultZodSchema,
  z.object({
    toolsToUse: z.array(toolSchema).nullable().describe("Tools to use if are necesary"),
  })
);