import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme:any) => ({
  messageContainer: {
    maxHeight: '50%',
    flex: 1,
  },
  message: {
    border: '1px solid lightgray',
    padding: theme.spacing(1),
    borderRadius: theme.spacing(1),
  }
}));
