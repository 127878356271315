import React from 'react';
import { WorkspaceRoutes } from 'types/common';
import { CURRENT_PATH } from 'utils/constants';

import OrdersLayoutScene from './orders-layout-scene';
import useOrdersLayout from './use-orders-layout';

const OrdersLayout = () => {
  const {
    t,
    url,
    history,
    sidemenuOpen,
    setSidemenuOpen,
    properties,
    parties,
    statusFetchOrderData,
    orderHasAccountingData,
    isOrderLoading,
    orderType,
    idTitleCompany
  } = useOrdersLayout();

  const handleViewChange = (view: WorkspaceRoutes, param = '') => {
    localStorage.setItem(CURRENT_PATH, JSON.stringify({ view, param }));
    history.push(`${url}/${view}/${param}`);
  };

  return (
    <OrdersLayoutScene
      t={t}
      sidemenuOpen={sidemenuOpen}
      setSidemenuOpen={setSidemenuOpen}
      statusFetchOrderData={statusFetchOrderData}
      handleViewChange={handleViewChange}
      properties={properties}
      parties={parties}
      orderHasAccountingData={orderHasAccountingData}
      isOrderLoading={isOrderLoading}
      orderType={orderType}
      idTitleCompany={idTitleCompany}
    />
  );
};

export default OrdersLayout;
