import React, { FC } from 'react';

import useStepperHooks from './order-stepper-hooks';
import OrderStepperScene from './order-stepper-scene';

type orderStepperProps = {
  open: boolean,
  pdfInformation: {
    images: string[],
    blobFiles: Blob[]
  },
  onClose: () => void;
}

const ModalOrderStepper: FC<orderStepperProps> = ({ open, pdfInformation, onClose }) => {
  const {
    methods,
    states,
    handleCreateOrderAI,
    createOrderAIResponse,
    setShouldFetch,
    transactionTypes,
    types,
    workflows,
    selectTransactionType
  } = useStepperHooks(pdfInformation?.images || [], onClose);

  const handleRetry = () => {
    methods.setValue('errorForm', null);
    setShouldFetch(true);
  };
  return (
    <OrderStepperScene
      open={open}
      pdfInformation={pdfInformation}
      states={states}
      methods={methods}
      onClose={onClose}
      handleCreateOrderAI={handleCreateOrderAI}
      loadingCreatingOrder={createOrderAIResponse.isLoading}
      handleRetry={handleRetry}
      transactionTypes={transactionTypes}
      types={types}
      workflows={workflows}
      selectTransactionType={selectTransactionType}
    />
  );
};

export default ModalOrderStepper;
