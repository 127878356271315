import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { OrderProperty } from 'types/order';

export const useMapOverview = ({ properties }: { properties: OrderProperty[] }) => {
  const { t } = useTranslation();
  const [selectedProperty, setSelectedProperty] = useState<OrderProperty>();

  const selectProperty = (id: string) => {
    if (properties) {
      const property = properties.find((x) => x._id === id);
      setSelectedProperty(property);
    }
  };

  useEffect(() => {
    if (properties.length > 0 && selectedProperty === undefined) {
      setSelectedProperty(properties[0]);
    }
  }, [properties]);

  return {
    t,
    selectProperty,
    selectedProperty
  };
};
