import { Box, Button, Typography } from '@material-ui/core';
import React from 'react';

const CustomContactOption = ({ contact, handleContactCreation }) => {
  return (
    <Box display="flex" flexDirection="column" flex="1">
      {contact._id !== 'add-new-contact'
        ? (
          <>
            <Typography variant="body2">{contact.name}</Typography>
          </>
        )
        : (
          <Box style={{ display: "flex", gap: "10px" }}>
            <Button
              onClick={(e) => {
                e.stopPropagation();
                handleContactCreation("Person");
              }}
            >
              Create Person
            </Button>
            <Button
              onClick={(e) => {
                e.stopPropagation();
                handleContactCreation("Company");
              }}
            >
              Create Company
            </Button>
          </Box>
        )}
    </Box>
  );
};

export default CustomContactOption;
