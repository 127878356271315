import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Grid, Typography
} from '@material-ui/core';
import { EmptyConversationContent } from 'components/inference/components/empty-conversation-content';
import { MessageContent } from 'components/inference/components/message-content';
import { useAutoscroll } from 'hooks/useAutoscroll';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { useStyles } from './styles';
import { useInferenceV2 } from 'hooks/useInferenceV2';
import { PromptInput } from 'components/inference/components/prompt-input';

export const QuestionsAndAnswersLLMProxy = () => {
  const {
    htmlContent,
    isLoadingInference,
    sendQuestion,
    messageList,
    isRecording,
    stopRecordAudio,
    startRecordAudio,
    playSpeech,
    stopSpeech,
    currentPlaying,
    isSpeechLoading
  } = useInferenceV2({ pathLocation: "" });
  const classes = useStyles();
  const { t } = useTranslation();
  const { htmlContainerRef } = useAutoscroll();

  return (
    <Grid
      className={classes.root}
      container
    >
      <Grid
        item
        container
        className={classes.room}
        direction="column"
        wrap="nowrap"
      >
        <Grid
          item
          container
          className={classes.messageListContainer}
          direction="column"
          wrap="nowrap"
          ref={htmlContainerRef}
        >
          {messageList.length === 0 && !isLoadingInference && (
            <EmptyConversationContent />
          )}
          {messageList.map((message) => (
            <MessageContent
              message={message}
              onViewSourceClick={() => { }}
              onPlaySpeech={playSpeech}
              onStopSpeech={stopSpeech}
              isAudioPlaying={currentPlaying === message.id}
              isAudioLoading={isSpeechLoading}
            />
          ))}
          {isLoadingInference && (
            <Grid container justifyContent="center" style={{ padding: '10px' }}>
              <Grid item>
                <FontAwesomeIcon spin icon="spinner" size="3x" color="#FB8C00" />
              </Grid>
            </Grid>
          )}
        </Grid>

        <PromptInput
          contentIsLoading={isLoadingInference}
          onSendPrompt={sendQuestion}
          isRecording={isRecording}
          stopRecordAudio={stopRecordAudio}
          startRecordAudio={startRecordAudio}
        />
      </Grid>
    </Grid>
  );
};
