/* eslint-disable radix */

import { generateRandomString } from 'utils/helpers';
import { AccountingType } from 'v2-types/order';

export type formattedCharge = {
  id: string;
  description: string;
  chargeData: AccountingType;
  number: number;
  borrowerFunds?: number;
  sellerFunds?: number;
};

export const generateSingleGenericAccounting = (
  number: number,
  letter: string
): AccountingType => ({
  _id: generateRandomString(10),
  amount: 0,
  amount_calculated: 0,
  amount_override: 0,
  code: 'charge',
  kind: 'manual',
  letter,
  number,
  description: '',
  involved_parties: [],
  entry_date: '',
  months: null,
  per_month: null,
});

const generateGenericAccounting = (
  size: number,
  startNumber: number,
  letter: string
): AccountingType[] => {
  const mockCharges = Array.from(Array(size).keys()).map((_, index) => generateSingleGenericAccounting(startNumber + index, letter));
  return [...mockCharges];
};

const fillCharges = (
  acc: AccountingType[],
  from: number,
  to: number,
  limit: number,
  letter: string
) => {
  let size = to - from;

  const filteredAccounting = acc?.filter((a) => a.letter?.includes(letter));

  const filteredCharges = filteredAccounting.filter(
    (charge) => charge.number >= from && charge.number <= limit
  );

  const lastCharge = filteredCharges.at(-1);

  if (lastCharge && lastCharge.number > to) {
    size = lastCharge.number - from;
  }

  return [
    ...generateGenericAccounting(size + 1, from, letter).map(
      (genericChharge) => {
        const findedCharge = filteredCharges.find(
          (charge) => parseInt(charge.number as unknown as string)
            === genericChharge.number
        );
        if (findedCharge) {
          return findedCharge;
        }
        return genericChharge;
      }
    ),
  ];
};

export const formatDataKM = (accountingData: AccountingType[]) => accountingData.map((f) => ({
  id: f._id,
  description: f.description,
  chargeData: f,
  number: f.number,
  borrowerFunds:
      f.involved_parties.find((s) => s.order_kinds.includes('Buyer'))?.amount
      || 0,
  sellerFunds:
      f.involved_parties.find((s) => s.order_kinds.includes('Seller'))
        ?.amount || 0,
}));

export const mockData = (accounting: AccountingType[]) => ({
  totalBrokerFees: formatDataKM(fillCharges(accounting, 700, 705, 799, 'L')),
  itemspayablewithLoan: formatDataKM(
    fillCharges(accounting, 801, 812, 899, 'L')
  ),
  itemsRequiredyByLender: formatDataKM(
    fillCharges(accounting, 901, 905, 999, 'L')
  ),
  reservesDepositedLender: formatDataKM(
    fillCharges(accounting, 1001, 1009, 1099, 'L')
  ),
  titleCharges: formatDataKM(fillCharges(accounting, 1101, 1112, 1199, 'L')),
  governmentRecordingCharges: formatDataKM(
    fillCharges(accounting, 1201, 1207, 1299, 'L')
  ),
  additionalSettlementCharges: formatDataKM(
    fillCharges(accounting, 1301, 1306, 1399, 'L')
  ),
  totalSttlementCharges: formatDataKM(
    fillCharges(accounting, 1400, 1401, 1499, 'L')
  ),
});

export const getHideFieldsL = (param: { type: string; data?: any }) => {
  if (param.type === 'edit') {
    if (param.data?.id?.length === 10) {
      return {
        code: true,
        kind: true,
        months_advanced: true,
        annuality: true,
        entryDate: true,
        number: true,
        amount_override: true,
      };
    }
    return {
      code: true,
      kind: true,
      amount: true,
      months_advanced: true,
      annuality: true,
      entryDate: true,
      number: true,
    };
  }
  return {
    amount_override: true,
    months_advanced: true,
    annuality: true,
    entryDate: true,
    number: true,
  };
};

export const getDisabledFieldsL = (param: { type: string; data?: any }) => {
  if (param.type === 'edit') {
    if (param.data?.id?.length === 10) {
      return {
        code: true,
        kind: true,
        months_advanced: true,
        annuality: true,
        entryDate: true,
        number: true,
        letter: true,
      };
    }
    if (!param.data?.chargeData?.kind.includes('manual')) {
      return {
        code: true,
        kind: true,
        amount: true,
        months_advanced: true,
        annuality: true,
        entryDate: true,
        number: true,
        letter: true,
        description: true,
      };
    }
    return {
      code: true,
      kind: true,
      amount: true,
      months_advanced: true,
      annuality: true,
      entryDate: true,
      number: true,
      letter: true,
    };
  }
  return {
    amount_override: true,
    months_advanced: true,
    annuality: true,
    entryDate: true,
    number: true,
  };
};
