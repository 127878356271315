import { Grid, makeStyles, Typography } from '@material-ui/core';
import { TFunction } from 'i18next';
import React from 'react';
import { PaymentsType, ProjectedPaymentsType } from 'types/disclosures';

import { getPaymentsRows, projectedPayments } from '../services';
import ProjectedPaymentsTable from './projected-payments-table';
import { useTranslation } from 'react-i18next';
import { UseFormReturn, useWatch } from 'react-hook-form';

const useStyles = makeStyles((theme: any) => ({
  subTitle: {
    color: theme.palette.primary.main,
    fontWeight: 500,
  },
}));

type PatymentsSectionTableType = {
  methods: UseFormReturn<projectedPayments, any, undefined>
};

const PaymentSectionTable = ({ methods }: PatymentsSectionTableType) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { watch } = methods;

  const [year_range_1, year_range_2] = watch(['year_range_1', 'year_range_2']);
  const fields = [
    'year_ranges',
    'principal_interest_amount',
    'interest_only',
    'mortgage_insurance',
    'estimated_escrow'
  ];
  return (
    <Grid container direction="column">
      <Grid item xs={12}>
        <Typography variant="body2" className={classes.subTitle}>
          {`${t('projectedPayments:years-range-title')} ${year_range_1} - ${year_range_2}`}
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <ProjectedPaymentsTable
          data={fields}
          rows={getPaymentsRows({ ...fields, methods, t })}
        />
      </Grid>
    </Grid>
  );
};

export default PaymentSectionTable;
