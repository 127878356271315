import { Box } from '@material-ui/core';
import React from 'react';

interface TabPanelProps {
  children?: React.ReactNode;
  index: string;
  value: string;
}

const CustomTabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      // hidden={value !== index}
      style={{
        // visibility: value !== index ? 'hidden' : 'visible',
        display: value !== index ? 'none' : 'block',
      }}
      {...other}
    >
      {value === index && <Box style={{ paddingTop: 3 }}>{children}</Box>}
    </div>
  );
};

export default CustomTabPanel;