import { Grid, Paper, makeStyles } from '@material-ui/core';
import React from 'react';
import Header from '../components/header';
import { useTranslation } from 'react-i18next';
import { general } from '../styles/general';
import TableCharges from '../components/tableCharges';
import useGetCharges from '../services/getCharges';
import SkeletonCharges from '../components/skeleton';

const useStyles = makeStyles((theme) => general(theme));

const ChargesB = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const currentLetter = 'B';

  const {
    accounting,
    updatedAccounting,
    isFetching,
    setHideTax,
    hideTax,
    redirectToPolicyInfo,
    discloSection,
    setChargesSection,
    policyIsLoading,
  } = useGetCharges(currentLetter);

  return (
    <Grid container className={classes.chargesContainer} direction="column" spacing={2}>
      <Grid item xs={12} style={{ marginBottom: 24 }}>
        <Paper>
          <Header
            setHideTax={setHideTax}
            hideTax={hideTax}
            title={t('charges:did-not-shop-for')}
            chargesIdentifier={currentLetter}
            t={t}
            redirectToPolicyInfo={redirectToPolicyInfo}
            discloSection={discloSection}
            setChargesSection={setChargesSection}
          />
          {isFetching ? (
            <SkeletonCharges />
          ) : (
            <TableCharges
              accountingData={accounting}
              currentLetter={currentLetter}
              updatedAccounting={updatedAccounting}
              hideTax={hideTax}
              policyIsLoading={policyIsLoading}
            />
          )}
        </Paper>
      </Grid>
    </Grid>
  );
};

export default ChargesB;
