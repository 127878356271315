import { Grid, makeStyles, Typography } from '@material-ui/core';
import { BaseButton } from 'components/BaseButton';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  titleError: {
    fontWeight: 500,
    color: theme.palette.common.black,
    marginTop: theme.spacing(3)
  }
}));

type ErrorRefechProps = {
  onClose: () => void,
  handleRefetch: () => void
}

const ErrorRefetch: FC<ErrorRefechProps> = ({ onClose, handleRefetch }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <Grid container direction="column" spacing={3}>
      <Grid item xs={12} style={{ textAlign: 'center' }}>
        <Typography
          variant="h6"
          className={classes.titleError}
        >
          {t('charges:error-loading-document-charges')}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Grid container direction="row" justifyContent="center" spacing={2}>
          <Grid item>
            <BaseButton
              text="retry"
              onClick={(e) => {
                e.stopPropagation();
                handleRefetch();
              }}
            />

          </Grid>
          <Grid item>
            <BaseButton
              buttonVariant="outlined"
              buttonColor="primary"
              text="Cancel"
              onClick={(e) => {
                e.stopPropagation();
                onClose();
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ErrorRefetch;