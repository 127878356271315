import { getListing } from 'graphql/listings/queries';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';
import querys from 'services/querys';
import { NewOrderPartyType } from 'types/order';
import useAlert from 'utils/alert';
import {
  CACHE_30_DAYS,
  LISTING_CODE_ALLOWED_ORDER_ASSOCIATIONS,
} from 'utils/constants';
import { capitalize } from 'utils/helpers';

import { filterRole, partiesfiltered } from './services';
import useCreateAssociation from './services/use-create-association';
import useDeleteAssociation from './services/use-delete-association';

const useParties = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { path, url } = useRouteMatch();
  const { id: orderId } = useParams<{ id: string }>();
  const [filteredparties, setFilteresParties] = useState<partiesfiltered[]>([]);
  const [unfilteredParties, setunfilteredParties] = useState<
    NewOrderPartyType[]
  >([]);
  const [openAddModal, setOpenAddModal] = useState(false);
  const showAlert = useAlert();

  const filterParties = useCallback((parties: NewOrderPartyType[]) => {
    const partiesKinds: string[] = parties.reduce((prev, current): string[] => {
      const { kinds } = current;
      const currentKinds = [
        ...prev,
        ...kinds.filter((kind) => !prev.includes(kind)),
      ];
      return currentKinds;
    }, [] as string[]);
    const filterFn = filterRole(parties);
    const allPartiesFiltered = partiesKinds.map((associate) => ({
      title: associate,
      parties: filterFn(associate),
      sectionId: associate,
      kind: associate,
    }));
    setunfilteredParties(parties);
    setFilteresParties(allPartiesFiltered);
  }, []);

  const { createAssociation, responseCreateAssociation } = useCreateAssociation(
    (deleteCreatedata) => {
      showAlert(capitalize(t('dialogs:order-updated')), 'success');
      filterParties(deleteCreatedata.createOrderAssociation?.parties || []);
    }
  );
  const { deleteAssociation, responseDeleteAssociation } = useDeleteAssociation(
    (deleteData) => {
      showAlert(capitalize(t('dialogs:order-updated')), 'success');
      filterParties(deleteData.deleteOrderAssociation?.parties || []);
    }
  );

  const { data: listingResponse } = useQuery(
    ['getListing', LISTING_CODE_ALLOWED_ORDER_ASSOCIATIONS],
    {
      queryFn: () =>
        getListing({ code: LISTING_CODE_ALLOWED_ORDER_ASSOCIATIONS }),
      refetchOnWindowFocus: false,
      staleTime: CACHE_30_DAYS,
    }
  );

  const listingEntries = listingResponse?.getListing.entries || [];

  const getOrder = useQuery(
    ['order-parties', orderId],
    querys.getOnlyOrderParties,
    {
      enabled: !!orderId && listingResponse !== undefined,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        if (data) {
          const { parties } = data.getOrder;
          filterParties(parties);
        }
      },
    }
  );

  useEffect(() => {
    if (responseDeleteAssociation.isLoading) {
      showAlert('removing association', 'info');
    }
  }, [responseDeleteAssociation.isLoading, showAlert]);

  useEffect(() => {
    if (responseCreateAssociation.isLoading) {
      showAlert('Creating new Association', 'info');
    }
  }, [responseCreateAssociation.isLoading, showAlert]);

  return {
    t,
    url,
    path,
    history,
    orderId,
    createAssociation,
    filteredparties,
    deleteAssociation,
    unfilteredParties,
    getOrder,
    filterParties,
    openAddModal,
    setOpenAddModal,
    listingEntries,
  };
};

export default useParties;
