import { FormikErrors } from 'formik';
import { TFunction } from 'i18next';
import React from 'react';
import { formatStringInputToNumber } from 'utils/helpers';

import AmountFields from '../components/amount-fields';
import IncludesFields from '../components/includes-fields';
import InEscrowFields from '../components/InEscrowFields';
import InputCell from '../components/input-cell';
import InterestOnlyFields from '../components/interest-only-fields';
import PrincipalInterestAmountFields from '../components/principal-interest-amount-fields';
import YearsRangeFields from '../components/years-range-fields';
import { Controller, UseFormReturn } from 'react-hook-form';

export type projectedPayments = {
  year_range_1: number;
  year_range_2: number;
  principal_kind: 'amount' | 'range';
  principal_amount: number;
  principal_min: number;
  principal_max: number;
  interest_only: boolean;
  mortgage_insurance: number;
  estimated_escrow: number;
  prorations_amount: number;
  prorations_period: 'month' | 'week';
  includes_taxes: boolean;
  includes_homeowners: boolean;
  includes_other: boolean;
  other_description: string;
  escrow_homeowners: boolean;
  escrow_taxes: boolean;
  escrow_other: boolean;
}

export type PaymentsProps = {
  t: TFunction,
  setFieldValue: any;
  year_range_from: number;
  year_raneg_to: number;
  principal_interest_amount: number;
  interest_only: boolean;
  mortgage_insurance: number;
  estimated_escrow: number;
  principal_interest_min: number;
  principal_interest_max: number;
  principal_interest_type: 'amount' | 'range';
  errors: FormikErrors<any>;
};

export const getEstimatedTaxesRows = ({
  t,
  methods
}: { t: TFunction, methods: UseFormReturn<projectedPayments, any, undefined> }) => ({
  amount: {
    title: t('projectedPayments:amount'),
    render: <AmountFields methods={methods} />
  },
  includes: {
    title: t('projectedPayments:includes'),
    render: <IncludesFields methods={methods} />
  },
  inEscrow: {
    title: t('projectedPayments:inEscrow'),
    render: <InEscrowFields methods={methods} />
  }
});

export const getPaymentsRows = ({
  t,
  methods
}: { t: TFunction, methods: UseFormReturn<projectedPayments, any, undefined> }) => ({
  year_ranges: {
    title: t('projectedPayments:years-range'),
    render: <YearsRangeFields methods={methods} />
  },
  principal_interest_amount: {
    title: t('projectedPayments:principalInterest'),
    render: <PrincipalInterestAmountFields methods={methods} />
  },
  interest_only: {
    title: t('projectedPayments:interestOnly?'),
    render: <InterestOnlyFields methods={methods} />
  },
  mortgage_insurance: {
    title: t('projectedPayments:mortgageInsurance'),
    render: (
      <Controller
        control={methods.control}
        name="mortgage_insurance"
        render={({ field }) => (
          <InputCell
            value={field.value}
            onChangeInput={(e: any) => {
              const value = formatStringInputToNumber(e.target.value, true);
              field.onChange(value);
            }}
          />
        )}
      />

    ),
  },
  estimated_escrow: {
    title: t('projectedPayments:estimatedEscrow'),
    render: (
      <Controller
        control={methods.control}
        name="estimated_escrow"
        render={({ field }) => (
          <InputCell
            value={field.value}
            onChangeInput={(e: any) => {
              const value = formatStringInputToNumber(e.target.value, true);
              field.onChange(value);
            }}
          />
        )}
      />
    )
  },
});
