import {
  Backdrop,
  Box,
  capitalize,
  CircularProgress,
  Grid,
  makeStyles,
  Paper,
  Tabs,
} from '@material-ui/core';
import { TabContext, TabPanel } from '@material-ui/lab';
import clsx from 'clsx';
import { CustomTab } from 'components/common/custom-tab';
import SaveButton from 'components/common/save-button';
import React, { FC } from 'react';
import { FormProvider, UseFormReturn } from 'react-hook-form';
import { TFunction } from 'react-i18next';
import { NewUserType } from 'types/new-user';
import {
  NewBuyerSellerType,
  NewExchange1031Type,
  NewOrderPartyType,
  NewPOAType,
  UpdateOrderSpecific,
} from 'types/order';
import {
  AGENT,
  ATTORNEY,
  EXCHANGE,
  INFORMATION,
  NOTARY_BLOCKS,
  POA,
  REAL_STATE_AGENT,
  SELLER,
  SELLER_AGENT,
  SIGNATURE_AND_VESTING,
  SPOUSE,
  TRANSACTION_COORDINATOR
} from 'utils/constants';

import InformationTab from '../components/information-tab';
import SkeletonParties from '../components/skeleton-loading';
import SellerAgent from '../components/tab-agent/tab-agent';
import { TabCreateAssociation } from '../components/tab-create-association';
import ExchangeTab from '../components/tab-exchange/tab-exchange';
import NotaryBlocks from '../components/tab-notary-block/notary-blocks';
import PoaTab from '../components/tab-poa/tab-poa';
import Tabsignature from '../components/tab-signature/tab-signature';
import SpouseTab from '../components/tab-spouse/tab-spouse';
import TitleAction from '../components/title-action';

const useStyles = makeStyles((theme: any) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: theme.palette.secondary.main,
  },
  container: { padding: `${theme.spacing(6)}px ${theme.spacing(4)}px` },
  containerModal: { padding: `${theme.spacing(2)}px ${theme.spacing(2)}px` },
  containerCommon: {
    overflow: 'scroll',
    height: '68vh',
  },
  tabsContainer: {
    flex: 1,
    borderBottom: '1px solid',
    borderBottomColor: 'rgba(0, 0, 0, 0.2)',
    height: theme.spacing(2),
  },
  tabContextContainer: { padding: 0 },
  title: {
    fontWeight: 500,
    color: theme.palette.tab.offselected,
  },
  containerTitle: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
}));

type SellerSceneProps = {
  t: TFunction;
  sellerInfo: NewUserType | undefined;
  isLoading: boolean;
  currentTab: string;
  handleTabChange: (event: any, index: string) => void;
  attorneys: NewOrderPartyType[];
  agents: NewOrderPartyType[];
  methods: UseFormReturn<UpdateOrderSpecific, any>;
  handleSaveinfo: (data_seller: NewBuyerSellerType) => void;
  poa: NewOrderPartyType | undefined;
  handleSavePOA: (data_buyer: NewPOAType) => void;
  exchange: NewOrderPartyType | undefined;
  handleSaveExchange: (data_exchange: NewExchange1031Type) => void;
  spouse: NewOrderPartyType | undefined;
  isModal: boolean;
  loaderActive: boolean;
  setLoaderActive: React.Dispatch<React.SetStateAction<boolean>>;
  realStateAgents: NewOrderPartyType[],
  transactionCoordinators: NewOrderPartyType[]
};

const SellerScene: FC<SellerSceneProps> = ({
  sellerInfo,
  isModal,
  t,
  isLoading,
  currentTab,
  handleTabChange,
  attorneys,
  methods,
  handleSaveinfo,
  agents,
  poa,
  handleSavePOA,
  exchange,
  handleSaveExchange,
  spouse,
  realStateAgents,
  transactionCoordinators,
  loaderActive,
  setLoaderActive,
}) => {
  const classes = useStyles();
  const onSubmit = (data) => {
    switch (currentTab) {
      case INFORMATION:
        handleSaveinfo(data.data_seller);
        break;
      case NOTARY_BLOCKS:
        handleSaveinfo(data.data_seller);
        break;
      case SIGNATURE_AND_VESTING:
        handleSaveinfo(data.data_seller);
        break;
      case POA:
        handleSavePOA(data.data_power_of_attorney);
        break;
      case EXCHANGE:
        handleSaveExchange(data.data_exchange_1031);
        break;
      default:
        break;
    }
  };
  if (isLoading) {
    return <SkeletonParties />;
  }

  return (
    <Paper
      elevation={0}
      className={clsx({
        [classes.container]: isModal === false,
        [classes.containerModal]: isModal === true,
        [classes.containerModal]: isModal === true,
      })}
    >
      {sellerInfo && (
        <FormProvider {...methods}>
          <Grid container direction="column">
            <Grid item>
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                className={classes.containerTitle}
              >
                <Grid item>
                  <TitleAction
                    partyId={sellerInfo._id}
                    type="Seller"
                    name={sellerInfo?.name || ''}
                  />
                </Grid>
                <Grid item>
                  <SaveButton onClick={methods.handleSubmit(onSubmit)} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <TabContext value={currentTab}>
                <Tabs
                  value={currentTab}
                  onChange={handleTabChange}
                  indicatorColor="primary"
                  textColor="primary"
                  className={classes.tabsContainer}
                  variant="scrollable"
                  scrollButtons="auto"
                >
                  <CustomTab
                    currentValue={currentTab}
                    value={INFORMATION}
                    text={t('common:information')}
                  />
                  <CustomTab
                    currentValue={currentTab}
                    value={NOTARY_BLOCKS}
                    text={t('orders:notary-blocks')}
                  />
                  <CustomTab
                    currentValue={currentTab}
                    value={ATTORNEY}
                    text={t('orders:attorney')}
                  />
                  <CustomTab
                    currentValue={currentTab}
                    value={SIGNATURE_AND_VESTING}
                    text={t('orders:signature-vesting')}
                  />
                  <CustomTab
                    currentValue={currentTab}
                    value={POA}
                    text={t('parties:POA')}
                  />
                  <CustomTab
                    currentValue={currentTab}
                    value={EXCHANGE}
                    text={t('parties:1031_exchange')}
                  />
                  <CustomTab
                    currentValue={currentTab}
                    value={SPOUSE}
                    text={t('parties:spouse')}
                  />
                  <CustomTab
                    currentValue={currentTab}
                    value={SELLER_AGENT}
                    text={t('parties:seller-agent')}
                  />
                  <CustomTab
                    currentValue={currentTab}
                    value={REAL_STATE_AGENT}
                    text={t('parties:real-state-agent')}
                  />
                  <CustomTab
                    currentValue={currentTab}
                    value={TRANSACTION_COORDINATOR}
                    text={t('parties:transaction-coordinator')}
                  />
                </Tabs>
                <TabPanel
                  value={INFORMATION}
                  className={classes.tabContextContainer}
                >
                  <Box>
                    <InformationTab
                      party={sellerInfo}
                      t={t}
                      mainParty
                      methods={methods}
                      prefix="data_seller"
                    />
                  </Box>
                </TabPanel>
                <TabPanel
                  value={NOTARY_BLOCKS}
                  className={classes.tabContextContainer}
                >
                  <Box>
                    <NotaryBlocks party={sellerInfo} roleParty="Seller" />
                  </Box>
                </TabPanel>
                <TabPanel
                  value={ATTORNEY}
                  className={classes.tabContextContainer}
                >
                  <Box>
                    <SellerAgent
                      party={sellerInfo}
                      partiesAdded={attorneys}
                      roleParty={capitalize(SELLER)}
                      kind={capitalize(ATTORNEY)}
                      setLoaderActive={setLoaderActive}
                    />
                  </Box>
                </TabPanel>
                <TabPanel
                  value={SIGNATURE_AND_VESTING}
                  className={classes.tabContextContainer}
                >
                  <Box>
                    <Tabsignature roleParty="Seller" />
                  </Box>
                </TabPanel>
                <TabPanel
                  value={SELLER_AGENT}
                  className={classes.tabContextContainer}
                >
                  <Box>
                    <SellerAgent
                      party={sellerInfo}
                      partiesAdded={agents}
                      roleParty={capitalize(SELLER)}
                      kind={capitalize(AGENT)}
                      setLoaderActive={setLoaderActive}
                    />
                  </Box>
                </TabPanel>
                <TabPanel value={POA} className={classes.tabContextContainer}>
                  <Box>
                    <PoaTab
                      roleParty="Seller"
                      partyId={sellerInfo._id}
                      poa={poa}
                      setLoaderActive={setLoaderActive}
                    />
                  </Box>
                </TabPanel>
                <TabPanel
                  value={EXCHANGE}
                  className={classes.tabContextContainer}
                >
                  <Box>
                    <ExchangeTab
                      roleParty="Seller"
                      partyId={sellerInfo._id}
                      exchange={exchange}
                      setLoaderActive={setLoaderActive}
                    />
                  </Box>
                </TabPanel>
                <TabPanel
                  value={SPOUSE}
                  className={classes.tabContextContainer}
                >
                  <Box>
                    <SpouseTab
                      roleParty="Seller"
                      partyId={sellerInfo._id}
                      spouse={spouse}
                      setLoaderActive={setLoaderActive}
                      loaderActive={loaderActive}
                    />
                  </Box>
                </TabPanel>

                <TabPanel
                  value={REAL_STATE_AGENT}
                  className={classes.tabContextContainer}
                >
                  <TabCreateAssociation
                    roleParty="Seller"
                    associationKind={REAL_STATE_AGENT}
                    partyId={sellerInfo._id}
                    partyAssociations={realStateAgents}
                    setLoaderActive={setLoaderActive}
                    loaderActive={loaderActive}
                    searchDescription={t('parties:search-real-state-agent')}
                    removeButtonLabel={t('parties:remove-real-state-agent')}
                    removeSuccessMessage={t('parties:removed-success-real-state-agent')}
                    createSuccessMessage={t('parties:created-success-real-state-agent')}
                    listTitle={t('parties:real-state-agent-list')}
                  />
                </TabPanel>

                <TabPanel
                  value={TRANSACTION_COORDINATOR}
                  className={classes.tabContextContainer}
                >
                  <TabCreateAssociation
                    roleParty="Seller"
                    associationKind={TRANSACTION_COORDINATOR}
                    partyId={sellerInfo._id}
                    partyAssociations={transactionCoordinators}
                    setLoaderActive={setLoaderActive}
                    loaderActive={loaderActive}
                    searchDescription={t('parties:search-transaction-coordinator')}
                    removeButtonLabel={t('parties:remove-transaction-coordinator')}
                    removeSuccessMessage={t('parties:removed-success-transaction-coordinator')}
                    createSuccessMessage={t('parties:created-success-transaction-coordinator')}
                    listTitle={t('parties:transaction-coordinator-list')}
                  />
                </TabPanel>
              </TabContext>
            </Grid>
          </Grid>
        </FormProvider>
      )}
      <Backdrop className={classes.backdrop} open={loaderActive}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Paper>
  );
};

export default SellerScene;
