import React from 'react';
import { Box, Button, Popover, Typography, makeStyles } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const useStyles = makeStyles((theme) => ({
  buttonContainer: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.primary.contrastText,
    textTransform: 'capitalize',
  },
}));

const MenuRow = ({ openMenu, anchorElRow, handleCloseMenu, handleOpenDialog }) => {
  const classes = useStyles();

  return (
    <Popover
      open={openMenu}
      anchorEl={anchorElRow?.anchorEl}
      onClose={handleCloseMenu}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
    >
      <Box p={2}>
        <Button
          className={classes.buttonContainer}
          variant="contained"
          size="small"
          onClick={handleOpenDialog}
          startIcon={<FontAwesomeIcon icon="trash" />}
        >
          <Typography>Delete</Typography>
        </Button>
      </Box>
    </Popover>
  );
};

export default MenuRow;
