import { InputLabelProps, makeStyles, MenuItem, TextField } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme: any) => ({
  input: {
    '&:focus': { backgroundColor: theme.palette.background.default },
    '& .MuiFilledInput-root': {
      textTransform: 'none',
      fontSize: '0.875rem',
      color: theme.palette.common.black,
      borderTopLeftRadius: 4,
      borderTopRightRadius: 4,
      backgroundColor: theme.palette.background.default,
    },
    '& .Mui-disabled': { cursor: 'not-allowed' }
  },
  naked: {
    '&:focus': { backgroundColor: theme.palette.background.default },
    '& .MuiFilledInput-root': {
      textTransform: 'none',
      fontSize: '0.875rem',
      color: theme.palette.common.black,
      borderTopLeftRadius: 4,
      borderTopRightRadius: 4,
      backgroundColor: 'transparent',
    },
    '& .MuiFilledInput-input': {
      paddingTop: 10,
      paddingLeft: 0,
    },
    '& .MuiOutlinedInput-root': {
      textTransform: 'none',
      fontSize: '0.875rem',
      color: theme.palette.common.black,
      borderTopLeftRadius: 4,
      borderTopRightRadius: 4,
      backgroundColor: 'transparent',
    },
    '& .Mui-disabled': { cursor: 'not-allowed' }
  },
}));

type SelectFieldProps = {
  emptyValue?: boolean;
  naked?: boolean;
  name?: string;
  label?: string;
  value: string;
  data: any[];
  dataKey: any;
  dataValue: any;
  dataText: any;
  error?: boolean;
  helperText?: string;
  handleBlur?: any;
  handleChange?: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  InputProps?: any;
  style?: {};
  disabled?: boolean;
  inputRef?: any;
  className?: string,
  inputLabelProps?: Partial<InputLabelProps>
};

const getSelectfieldTexts = (element: { [index: string]: number }, keysArray: string[]) => `
${keysArray.reduce((prev, curr) => `${prev !== '' ? `${prev} -` : prev} ${(element[curr] !== undefined && element[curr] !== null) ? ` ${element[curr]}` : ''}`, '')}`.substring(1);

const SelectField = ({
  emptyValue,
  naked,
  name,
  label,
  value,
  data,
  dataKey,
  dataValue,
  dataText,
  error,
  helperText,
  handleBlur,
  handleChange,
  InputProps,
  style,
  disabled,
  inputRef,
  className,
  inputLabelProps
}: SelectFieldProps) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <TextField
      style={style}
      select
      fullWidth
      variant="filled"
      name={name}
      label={label}
      className={clsx(className, { [classes.input]: !naked, [classes.naked]: naked })}
      value={value}
      error={error}
      helperText={helperText}
      InputProps={{ disableUnderline: naked, ...InputProps }}
      onBlur={handleBlur}
      onChange={handleChange}
      disabled={disabled}
      inputRef={inputRef}
      InputLabelProps={inputLabelProps}
    >
      {emptyValue && (
        <MenuItem value="">
          {t('common:select-one')}
        </MenuItem>
      )}

      {data.map((element: any) => (
        <MenuItem
          key={(dataKey === '' ? element : element[dataKey])}
          disabled={element.disabled}
          value={(dataValue === '' ? element : element[dataValue])}
        >
          {Array.isArray(dataText) && (
            <span>{getSelectfieldTexts(element, dataText)}</span>
          )}

          {typeof dataText === 'string' && (
            <span>{(dataText === '' ? element : element[dataText])}</span>
          )}

          {(typeof dataText === 'object' && !Array.isArray(dataText) && dataText !== null) && (
            <span>{dataText.t(`${dataText.text}:${element}`)}</span>
          )}
        </MenuItem>
      ))}
    </TextField>
  );
};

SelectField.defaultProps = {
  emptyValue: true,
  naked: false,
  name: undefined,
  label: '',
  error: false,
  helperText: undefined,
  handleBlur: undefined,
  handleChange: () => { },
  InputProps: {},
  style: {},
  disabled: false,
  inputRef: undefined,
};

export default SelectField;
