import { getNarrative, GQLGetNarrativeResponse } from 'graphql/narrative/queries';
import { QueryConfig, useQuery } from 'react-query';

type Params = {
  orderId: string,
  queryConfig?: QueryConfig<GQLGetNarrativeResponse, Error>
}

export const useGetOrderNarrative = ({ orderId, queryConfig }: Params) => useQuery([{ orderId }, 'order-narrative'], {
  ...queryConfig,
  queryFn: getNarrative
});
