import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faAddressBook,
  faAngleDown,
  faAngleUp,
  faArchive,
  faArrowAltCircleDown,
  faArrowAltCircleRight,
  faArrowAltCircleUp,
  faArrowLeft,
  faArrowRight,
  faArrowUp,
  faBackspace,
  faBan,
  faBars,
  faBell,
  faBook,
  faBookmark,
  faBox,
  faBriefcase,
  faBuilding,
  faCalculator,
  faCalendarAlt,
  faCaretDown,
  faCaretSquareLeft,
  faCaretUp,
  faChartArea,
  faCheck,
  faCheckCircle,
  faCheckSquare,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faCircle,
  faClock,
  faCloud,
  faCogs,
  faCoins,
  faCommentDots,
  faCopy,
  faCreditCard,
  faDesktop,
  faDollarSign,
  faDownload,
  faEdit,
  faEllipsisH,
  faEllipsisV,
  faEnvelope,
  faEnvelopeOpenText,
  faExchangeAlt,
  faExclamation,
  faExclamationCircle,
  faExclamationTriangle,
  faExpandAlt,
  faExternalLinkAlt,
  faEye,
  faFile,
  faFileAlt,
  faFileInvoice,
  faFileInvoiceDollar,
  faFileSignature,
  faFolder,
  faFolderOpen,
  faGavel,
  faGripHorizontal,
  faHandshake,
  faHashtag,
  faHdd,
  faHome,
  faIdBadge,
  faImage,
  faIndent,
  faInfoCircle,
  faKey,
  faLanguage,
  faLink,
  faList,
  faListUl,
  faLock,
  faMapMarkedAlt,
  faMapMarkerAlt,
  faMicrophone,
  faMicrophoneSlash,
  faMinus,
  faMinusCircle,
  faMobile,
  faMoneyBill,
  faMoneyBillWaveAlt,
  faMoneyCheckAlt,
  faOutdent,
  faPaperPlane,
  faPause,
  faPen,
  faPencilAlt,
  faPeopleArrows,
  faPercentage,
  faPhone,
  faPlay,
  faPlayCircle,
  faPlug,
  faPlus,
  faPlusCircle,
  faPlusSquare,
  faPrint,
  faProjectDiagram,
  faQuestionCircle,
  faSearch,
  faShareAlt,
  faSignOutAlt,
  faSlidersH,
  faSpinner,
  faStickyNote,
  faStopCircle,
  faStream,
  faSync,
  faThLarge,
  faTicketAlt,
  faTimes,
  faTimesCircle,
  faTools,
  faTrash,
  faTrashAlt,
  faUndoAlt,
  faUnlink,
  faUpload,
  faUser,
  faUserPlus,
  faUsers,
  faUserTie,
  faVideo,
  faVideoSlash,
  faVolumeMute,
  faVolumeUp,
} from '@fortawesome/free-solid-svg-icons';

library.add(
  faExternalLinkAlt,
  faMoneyCheckAlt,
  faEllipsisH,
  faBook,
  faPause,
  faBan,
  faStopCircle,
  faPlay,
  faPlayCircle,
  faVolumeMute,
  faVolumeUp,
  faCaretUp,
  faCaretDown,
  faAddressBook,
  faAngleDown,
  faAngleUp,
  faArchive,
  faArrowLeft,
  faArrowRight,
  faArrowUp,
  faBackspace,
  faBars,
  faBell,
  faBookmark,
  faBox,
  faBriefcase,
  faBuilding,
  faCalendarAlt,
  faCaretSquareLeft,
  faCheck,
  faCheckCircle,
  faCheckSquare,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faCircle,
  faClock,
  faCloud,
  faCogs,
  faCommentDots,
  faCreditCard,
  faDesktop,
  faDollarSign,
  faDownload,
  faEdit,
  faEllipsisV,
  faEnvelope,
  faEnvelopeOpenText,
  faExchangeAlt,
  faExclamation,
  faExclamationCircle,
  faExclamationTriangle,
  faExpandAlt,
  faEye,
  faFile,
  faFileAlt,
  faFileInvoice,
  faFileInvoiceDollar,
  faFolder,
  faFolderOpen,
  faGripHorizontal,
  faHandshake,
  faHashtag,
  faHdd,
  faHome,
  faIdBadge,
  faInfoCircle,
  faKey,
  faLanguage,
  faLink,
  faList,
  faListUl,
  faLock,
  faMapMarkedAlt,
  faMapMarkerAlt,
  faMicrophone,
  faMicrophoneSlash,
  faMinus,
  faMinusCircle,
  faMobile,
  faMoneyBillWaveAlt,
  faPaperPlane,
  faPen,
  faPencilAlt,
  faPercentage,
  faPhone,
  faPlug,
  faPlus,
  faPlusCircle,
  faPlusSquare,
  faPrint,
  faProjectDiagram,
  faQuestionCircle,
  faSearch,
  faShareAlt,
  faSignOutAlt,
  faSlidersH,
  faSpinner,
  faStream,
  faSync,
  faThLarge,
  faTimes,
  faTimesCircle,
  faTools,
  faTrash,
  faTrashAlt,
  faUndoAlt,
  faUnlink,
  faUpload,
  faUser,
  faUserPlus,
  faUsers,
  faVideo,
  faVideoSlash,
  faDesktop,
  faArrowAltCircleDown,
  faArrowAltCircleUp,
  faStickyNote,
  faIndent,
  faOutdent,
  faCopy,
  faImage,
  faGavel,
  faUserTie,
  faFileSignature,
  faChartArea,
  faCalculator,
  faTicketAlt,
  faMoneyBill,
  faCoins,
  faPeopleArrows,
  faArrowAltCircleRight
);
