import './theme/icons';
import './i18n';

import { AppState, Auth0Provider } from '@auth0/auth0-react';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import SocketProvider from 'contexts/socketcontext/socket-provider';
import { createBrowserHistory } from 'history';
import React from 'react';
import ReactDOM from 'react-dom';
import { QueryCache, ReactQueryCacheProvider } from 'react-query';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';

import App from './App';
import MutateThemeProvider from './providers/mutate-theme-provider';
import configureStore from './redux';
import * as serviceWorker from './serviceWorker';
import { SENTRY_URL } from './utils/constants';

const queryCache = new QueryCache();
const ENVIRONMENT = process.env.REACT_APP_ENV || 'STAGING';

if (ENVIRONMENT !== 'DEVELOPMENT') {
  Sentry.init({
    dsn: SENTRY_URL,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
  });
}

const { store } = configureStore();
const history = createBrowserHistory();

const onRedirectCallback = (appState?: AppState) => {
  history.replace(appState?.returnTo || window.location.pathname);
};

ReactDOM.render(
  <Provider store={store}>
    <MutateThemeProvider>
      <ReactQueryCacheProvider queryCache={queryCache}>
        <Auth0Provider
          domain={process.env.REACT_APP_Auth_0_Domain || ''}
          clientId={process.env.REACT_APP_Auth_0_Client_ID || ''}
          redirectUri={window.location.origin}
          useRefreshTokens
          onRedirectCallback={onRedirectCallback}
        >
          <Router>
            <SocketProvider>
              <App />
            </SocketProvider>
          </Router>
        </Auth0Provider>
      </ReactQueryCacheProvider>
    </MutateThemeProvider>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
