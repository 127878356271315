import { gql } from "graphql-request";
import gqlRequest from "graphql/generic";
import { projectedPayments } from ".";

const requestString = gql`
  query Projected_payments($id: ID!) {
  getOrder(_id: $id) {
    projected_payments {
      year_range_1
      year_range_2
      principal_kind
      principal_amount
      principal_min
      principal_max
      interest_only
      mortgage_insurance
      estimated_escrow
      prorations_amount
      prorations_period
      includes_taxes
      includes_homeowners
      includes_other
      other_description
      escrow_homeowners
      escrow_taxes
      escrow_other
    }
  }
}
`;

type GetProjectedPaymentsReturn = {
  getOrder: {
    projected_payments:projectedPayments
  };
};

type GetProjectedPaymentsParams = {id: string };

export const getOrderProjectedPayments = (id: string) => gqlRequest<GetProjectedPaymentsReturn, GetProjectedPaymentsParams>(
  requestString, { id });

export default {};
