import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { projectedPayments } from './services';
import { useMutation, useQuery } from 'react-query';
import { getOrderProjectedPayments } from './services/queries';
import { updateOrderProjectedPayments } from './services/mutations';
import useAlert from 'utils/alert';
import { useEffect } from 'react';
import { capitalize } from 'utils/helpers';
import { useTranslation } from 'react-i18next';

const useLoanTerms = () => {
  const methods = useForm<projectedPayments>({
    defaultValues: {
      year_range_1: 1,
      year_range_2: 0,
      principal_kind: 'amount',
      principal_amount: 0,
      principal_min: 0,
      principal_max: 0,
      interest_only: false,
      mortgage_insurance: 0,
      estimated_escrow: 0,
      prorations_amount: 0,
      prorations_period: 'month',
      includes_taxes: false,
      includes_homeowners: false,
      includes_other: false,
      other_description: '',
      escrow_homeowners: false,
      escrow_taxes: false,
      escrow_other: false,
    }
  });
  const { id: orderId } = useParams<{ id: string }>();
  const showAlert = useAlert();
  const { t } = useTranslation();
  const getProjectedPayments = useQuery(['order-projected-payemnts', orderId],
    () => getOrderProjectedPayments(orderId),
    {
      enabled: !!orderId,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        if (data.getOrder?.projected_payments) {
          methods.reset(data.getOrder?.projected_payments);
        }
      }
    });

  const [updateProjectedPayments, updateOrderProjectedResponse] = useMutation(updateOrderProjectedPayments, {
    onSuccess: () => {
      updateOrderProjectedResponse.reset();
      showAlert('Order Updated', 'success');
    },
    onError: () => { showAlert(capitalize(t('dialogs:there-is-an')), 'error'); }
  });
  useEffect(() => {
    if (getProjectedPayments.isFetching && getProjectedPayments.isLoading) {
      showAlert('Updating...', 'info');
    }
  }, [showAlert, getProjectedPayments.isFetching, getProjectedPayments.isLoading]);

  return {
    getProjectedPayments,
    methods,
    updateProjectedPayments,
    updateOrderProjectedResponse,
    orderId
  };
};

export default useLoanTerms;
