import { Box, makeStyles, Typography } from '@material-ui/core';
import AddContactModal from 'components/add-contact-modal/add-contact-modal';
import { BaseButton } from 'components/BaseButton';
import React, { FC, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getProfile } from 'redux/profile-reducer/selectors';
import {
  AGENCY_SELECTED_ID,
  CONTACT_TITLE_ADMINISTRATOR,
} from 'utils/constants';

import useChangeAssociationHooks from '../../services/association-hooks';
import { useHistory, useParams } from 'react-router-dom';
import PartiesContext from '../../services/context';

const useStyles = makeStyles((theme: any) => ({
  title: {
    fontWeight: 500,
    color: theme.palette.tab.offselected,
    marginRight: theme.spacing(4),
  },
}));

type titleActionProps = {
  type: string;
  name: string;
  partyId: string;
};

const TitleAction: FC<titleActionProps> = ({ type, name, partyId }) => {
  const [changecontactModal, setChangeContactModal] = useState(false);
  const { id: orderId } = useParams<{ id: string }>();
  const userProfile = useSelector(getProfile);
  const history = useHistory();
  const AgencySelected = localStorage.getItem(AGENCY_SELECTED_ID) || '';
  const authorization = userProfile.accesses.find(
    (access) => access._id === AgencySelected
  )?.authorization;
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    createAssociation,
  } = useChangeAssociationHooks(partyId, type);
  const context = useContext(PartiesContext);
  const enableChangeKind = context?.listingEntries.some((listingEntry) => listingEntry.code === type);
  return (
    <>
      <Box display="flex" flexDirection="row">
        <Typography variant="h5" className={classes.title}>
          {`${type} / ${`${name}`}`}
        </Typography>
        {authorization === CONTACT_TITLE_ADMINISTRATOR && (
          <BaseButton
            text={t('parties:edit-contact-details')}
            icon="arrow-right"
            onClick={(e) => {
              e.preventDefault();
              window.open(
                `${window.location.origin}/contacts/${partyId}`,
                '_blank',
                'noopener,noreferrer'
              );
            }}
          />
        )}
        {enableChangeKind &&
          <BaseButton
            customStyle={{ marginLeft: 8 }}
            text="Change Contact Kind"
            onClick={(e) => {
              e.preventDefault();
              setChangeContactModal(true);
            }}
          />}
      </Box>
      {changecontactModal &&
        <AddContactModal
          open={changecontactModal}
          onClose={() => setChangeContactModal(false)}
          onClickAddParty={(_, kind) => {
            setChangeContactModal(false);
            createAssociation({
              id: orderId!,
              partyId,
              kind,
              linkedToId: '',
              linkedToKind: ''
            }).then(() => { history.push(`/orders/details/${orderId}/parties/${kind}/${partyId}`); });
          }}
          allowedCodeAssociations="allowed_order_associations"
          preselectedcontact={{
            _id: partyId,
            name
          }}
        />}
    </>
  );
};

export default TitleAction;
