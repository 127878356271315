import { useLLMProxyServiceContext } from "contexts/llmProxySerivceContext";
import { useGetOrderNarrative } from "hooks/useGetOrderNarrative";
import { Message } from "hooks/useInference/types";
import { useMicrophone } from "hooks/useMicrophone";
import { useSpeech } from "hooks/useSpeech";
import { useCallback, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { v4 as uuidv4 } from 'uuid';

type Params = {
  pathLocation: string,
  onConversationUpdated?: () => void
}

export const useInferenceV2 = ({ pathLocation, onConversationUpdated }: Params) => {
  const [isLoadingInference, setIsLoadingInference] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);
  const [messageList, setMessageList] = useState<Message[]>([]);
  const [htmlContent, setHtmlContent] = useState<string>("");
  const { id: orderId } = useParams<{ id: string }>();
  const { llmServiceRef } = useLLMProxyServiceContext();
  const [currentPlaying, setCurrentPlaying] = useState<string>();
  const playAudioEnabledRef = useRef(false);

  const {
    getSpeech,
    stopSpeechAudio,
    isSpeechLoading,
    abortGetSpeech
  } = useSpeech({
    onSpeechAudioEnd() {
      setCurrentPlaying('');
    },
  });

  const playSpeech = ({ messageId, text }: { messageId: string, text: string }) => {
    setCurrentPlaying(messageId);
    stopSpeechAudio();
    if (isSpeechLoading) {
      abortGetSpeech();
    }
    getSpeech({ text });
  };

  const stopSpeech = () => {
    setCurrentPlaying('');
    stopSpeechAudio();
  };

  const toogleAudioEnable = () => {
    playAudioEnabledRef.current = !playAudioEnabledRef.current;
  };

  const {
    isRecording,
    startRecordAudio,
    stopRecordAudio
  } = useMicrophone({
    onRecordStart() {
      stopSpeech();
    },
    onRecordStop: async (audioBlob) => {}
  });

  useGetOrderNarrative({
    orderId,
    queryConfig: {
      enabled: orderId !== "",
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        llmServiceRef.current.setCurrentOrderNarrative(data.getOrder.narrative);
      }
    }
  });

  useEffect(() => {
    llmServiceRef.current.setCurrentAppLocation(pathLocation);
  }, [pathLocation]);

  const onNewMessage = useCallback((id: string) => {
    return (message: string) => {
      if (playAudioEnabledRef.current) {
        getSpeech({ text: message }, {
          onSuccess() {
            setMessageList(prevMessageList => {
              const messageIndex = prevMessageList.findIndex((m) => m.id === id);
              const messageListCopy = [...prevMessageList];

              messageListCopy[messageIndex].response += message;
              messageListCopy[messageIndex].isLoading = false;

              return messageListCopy;
            });
            if (onConversationUpdated) onConversationUpdated();
          }
        });
      } else {
        setMessageList(prevMessageList => {
          const messageIndex = prevMessageList.findIndex((m) => m.id === id);
          const messageListCopy = [...prevMessageList];

          messageListCopy[messageIndex].response += message;
          messageListCopy[messageIndex].isLoading = false;

          return messageListCopy;
        });
        if (onConversationUpdated) onConversationUpdated();
      }
    };
  }, []);

  const sendQuestion = useCallback(async (prompt: string) => {
    const id: string = uuidv4();
    const newMessage = {
      id,
      questionTime: new Date(),
      isLoading: true,
      response: "",
      text: prompt,
    };

    setMessageList((prevMessageList) => [
      ...prevMessageList,
      newMessage
    ]);

    const onNewMessageCallback = onNewMessage(id);

    try {
      setIsLoadingInference(true);
      await llmServiceRef.current.invoke(prompt, onNewMessageCallback);
      setIsLoadingInference(false);
    } catch (error) {
      setIsLoadingInference(false);
    }
  }, []);

  return {
    htmlContent,
    isLoadingInference,
    sendQuestion,
    messageList,
    currentPlaying,
    playSpeech,
    stopSpeech,
    isSpeechLoading,
    toogleAudioEnable,
    isRecording,
    startRecordAudio,
    stopRecordAudio
  };
};
