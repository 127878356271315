import React from 'react';
import { AGENCY_SELECTED_ID } from 'utils/constants';

import AuthenticatedLayoutScene from './authenticated-layout-scene';
import useAuthenticatedLayout from './use-authenticated-layout';

const languages = {
  en: 'English',
  es: 'Español',
};

type AuthenticatedLayoutProps = {
  children: React.ReactElement;
};

const AuthenticatedLayout = ({ children }: AuthenticatedLayoutProps) => {
  const {
    t,
    i18n,
    anchorEl,
    setAnchorEl,
    languageAnchorEl,
    setLanguageAnchorEl,
    history,
    userIsTitleAdmin,
    location,
    logout,
    userLetters,
    agencySelected,
    selectAgency
  } = useAuthenticatedLayout();

  const isMenuOpen = Boolean(anchorEl);
  const currentLanguage = languages[i18n.language || window.localStorage.i18nextLng || 'en'];

  const handleRoute = (path: string) => history.push(path);

  const handleNavigateProfile = () => history.push('/profile');

  const handleNavigateTermsOfService = () => history.push('/terms-of-service');

  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget);

  const handleLanguageClick = (event: React.MouseEvent<HTMLButtonElement>) => setLanguageAnchorEl(event.currentTarget);

  const handleMenuClose = (path?: string) => {
    setAnchorEl(null);

    if (path) {
      history.push(path);
    }
  };

  const handleLanguage = (language?: string) => {
    if (language) {
      i18n.changeLanguage(language);
    }

    setLanguageAnchorEl(null);
  };

  const handleLogout = () => {
    // V2
    localStorage.removeItem('v2-workspace');

    localStorage.removeItem('access_token');
    localStorage.removeItem(AGENCY_SELECTED_ID);
    window.location.replace(window.location.origin);
    logout({ returnTo: window.location.origin });
  };
  return (
    <AuthenticatedLayoutScene
      t={t}
      agencySelected={agencySelected}
      selectAgency={selectAgency}
      location={location}
      userIsTitleAdmin={userIsTitleAdmin}
      currentLanguage={currentLanguage}
      languageAnchorEl={languageAnchorEl}
      userLetters={userLetters}
      anchorEl={anchorEl}
      isMenuOpen={isMenuOpen}
      handleRoute={handleRoute}
      handleLanguageClick={handleLanguageClick}
      handleLanguage={handleLanguage}
      handleProfileMenuOpen={handleProfileMenuOpen}
      handleNavigateProfile={handleNavigateProfile}
      handleNavigateTermsOfService={handleNavigateTermsOfService}
      handleLogout={handleLogout}
      handleMenuClose={handleMenuClose}
    >
      {React.cloneElement(children, { agencySelected })}
    </AuthenticatedLayoutScene>
  );
};

export default AuthenticatedLayout;
