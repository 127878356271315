import { useMutation } from "react-query";
import querys from "services/querys";
import { GQLDeleteAssociation } from "services/querys/orders";
import useAlert from "utils/alert";

const useDeleteAssociation = (callback?:(data: GQLDeleteAssociation)=>void) => {
  const showAlert = useAlert();
  const [deleteAssociation, responseDeleteAssociation] = useMutation(
    querys.deleteAssociation,
    {
      onSuccess: (data) => {
        responseDeleteAssociation.reset();
        if (callback) {
          callback(data);
        }
      },
      onError: () => {
        showAlert('There was an error, try again', 'error');
      }
    }
  );

  return {
    deleteAssociation,
    responseDeleteAssociation
  };
};

export default useDeleteAssociation;