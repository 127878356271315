import { Button, ButtonGroup, Grid, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { BaseButton } from 'components/BaseButton';
import React, { FC } from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { NewBasicInfoType } from 'types/new-user';
import { ContactKind } from 'views/contacts/contacts/types';

import FormPersonalInformation from '../personal-information';
import { useCreateContact } from './contact-creation-hooks';

const useStyles = makeStyles((theme: any) => ({
  title: { color: theme.palette.tab.offselected },
  buttonSelect: { textTransform: 'capitalize' },
  buttonSelectActive: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.primary.main,
    },
    '&:disabled': { color: theme.palette.common.white },
  },
  buttonContainer: { marginTop: theme.spacing(2) },
  buttonContainerCreated: { paddingBottom: theme.spacing(2), paddingLeft: theme.spacing(2) },
  buttonText: {
    textTransform: 'none',
    color: theme.palette.primary.main,
    marginLeft: theme.spacing(1),
  },
  buttonLink: {
    fontSize: '0.75rem',
    textTransform: 'none',
    color: theme.palette.primary.main,
  },
  divider: {
    fontWeight: 400,
    backgroundColor: theme.palette.grey[400],
  },
  alertContainer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  emailContainer: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
}));

type contactCreationType = {
  selectedKind: string;
  kindList?: ContactKind[];
  onSuccess: ({ _id, name }) => void;
  handleCancel?: () => void;
  searchText?: string;
};

const ContactCreation: FC<contactCreationType> = ({
  selectedKind,
  onSuccess,
  handleCancel,
  searchText,
  kindList = ['Company', 'Organization', 'Person'],
}) => {
  const { created, createParty, methods, responseCreateParty } = useCreateContact(
    selectedKind,
    onSuccess
  );
  const classes = useStyles();
  const { t } = useTranslation();

  const handleSubmit = (data: NewBasicInfoType) => {
    createParty({
      data: {
        ...data,
        middle_name: data.kind !== 'Company' ? data.middle_name : '',
        last_name: data.kind !== 'Company' ? data.last_name : '',
      },
    });
  };

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item xs={12}>
        <ButtonGroup fullWidth disabled={created} size="small" color="primary">
          {kindList.map((kind) => (
            <Button
              key={kind}
              className={clsx(classes.buttonSelect, {
                [classes.buttonSelectActive]: methods.watch('kind') === kind,
              })}
              onClick={() => methods.setValue('kind', kind, { shouldValidate: true })}
            >
              {t(`users:${kind.toLowerCase()}`)}
            </Button>
          ))}
        </ButtonGroup>
      </Grid>
      <Grid item xs={12}>
        <Grid container direction="column">
          <FormProvider {...methods}>
            <form
              onSubmit={(e) => {
                e.stopPropagation();
                methods.handleSubmit(handleSubmit)(e);
              }}
            >
              <Grid item xs={12}>
                <FormPersonalInformation
                  t={t}
                  shouldValidate
                  showTitle={false}
                  showOptionalFields={false}
                  helpers={{
                    first_name: { disabled: created },
                    middle_name: { disabled: created },
                    last_name: { disabled: created },
                  }}
                  searchText={searchText?.split(' ')}
                />
              </Grid>

              {!created && (
                <Grid item xs={12}>
                  <Grid container direction="row" justifyContent="flex-end">
                    {handleCancel && (
                      <Grid
                        item
                        className={clsx(classes.buttonContainer, {
                          [classes.buttonContainerCreated]: true,
                        })}
                      >
                        <BaseButton
                          disableElevation
                          buttonVariant="text"
                          text="Cancel"
                          buttonColor="primary"
                          onClick={handleCancel}
                        />
                      </Grid>
                    )}
                    <Grid
                      item
                      className={clsx(classes.buttonContainer, {
                        [classes.buttonContainerCreated]: true,
                      })}
                    >
                      <BaseButton
                        type="submit"
                        disableElevation
                        disabled={!methods.formState.isValid}
                        isLoading={responseCreateParty.isLoading}
                        text={t('users:create-user')}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </form>
          </FormProvider>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ContactCreation;
