import { Box, Button, FormControlLabel, Switch, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { TFunction } from 'i18next';
import React, { Dispatch, SetStateAction } from 'react';
import { MutateFunction } from 'react-query';
import { useParams } from 'react-router-dom';
import { UpdateOrder, UpdateOrderParams } from '../services/mutations';

const useStyles = makeStyles((theme: any) => ({
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: theme.spacing(10),
    borderBottomStyle: 'solid',
    borderBottomColor: theme.accent.main,
    borderBottomWidth: 1,
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    '& :nth-child(2)': { marginLeft: 'auto' },
  },
  inlineText: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 8,
  },
  title: {
    color: theme.palette.primary.main,
    fontSize: 23,
    fontWeight: 500,
  },
  containerleyend: {
    background: theme.accent.main,
    borderRadius: 4,
    width: 30,
    height: 30,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  leyend: {
    fontSize: 18,
    color: theme.palette.primary.darker,
    textTransform: 'uppercase',
  },
  redirectPolicyInfo: {
    fontSize: '14px',
    textTransform: 'capitalize',
    color: theme.palette.primary.main,
  },
  buttonsContainer: {
    display: 'flex',
    gap: '10px',
  },
}));

type HeaderChargesProps = {
  title: string;
  chargesIdentifier: string;
  hideTax: boolean;
  setHideTax: Dispatch<SetStateAction<boolean>>;
  t: TFunction;
  redirectToPolicyInfo?: () => void;
  discloSection?: string;
  setChargesSection?: MutateFunction<UpdateOrder, unknown, UpdateOrderParams, unknown>;
};

const HeaderCharges = ({
  title,
  chargesIdentifier,
  setHideTax,
  hideTax,
  t,
  redirectToPolicyInfo,
  discloSection,
  setChargesSection,
}: HeaderChargesProps) => {
  const classes = useStyles();

  const { id } = useParams<{ id: string }>();

  const handleChange = () => {
    setHideTax(!hideTax);
  };

  const handleSection = () => {
    if (setChargesSection) {
      setChargesSection({
        id,
        kind: 'lenders',
        data: { disclosure_section: discloSection === 'B' ? 'C' : 'B' },
        filters: {
          accounting: chargesIdentifier,
        },
      });
    }
  };

  return (
    <Box className={classes.header}>
      <Box className={classes.inlineText}>
        <Typography className={classes.title}>{title}</Typography>

        <Box className={classes.containerleyend}>
          <Typography className={classes.leyend}>{chargesIdentifier}</Typography>
        </Box>
      </Box>
      <FormControlLabel
        control={<Switch checked={hideTax} onChange={handleChange} />}
        label="Hide Tax"
      />
      {(chargesIdentifier === 'B' || chargesIdentifier === 'C') && (
        <Box className={classes.buttonsContainer}>
          <Button
            variant="contained"
            className={classes.redirectPolicyInfo}
            color="secondary"
            onClick={redirectToPolicyInfo}
          >
            <Typography variant="body2">{t('charges:edit-premium-line')}</Typography>
          </Button>

          {discloSection === chargesIdentifier && (
            <Button
              variant="outlined"
              className={classes.redirectPolicyInfo}
              color="secondary"
              onClick={handleSection}
            >
              <Typography variant="body2">
                {`${t('charges:send-title-charges-to')} ${chargesIdentifier === 'B' ? 'C' : 'B'}`}
              </Typography>
            </Button>
          )}
        </Box>
      )}
    </Box>
  );
};

export default HeaderCharges;
