import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import React from 'react';

const ConfirmationModal = ({ openDialog, handleCloseDialog, handleDeleteCharge, anchorElRow }) => {
  return (
    <Dialog open={openDialog}>
      <DialogTitle>Confirm Charge Deletion</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Are you sure you want to delete this charge? This action cannot be undone, and all
          associated information will be lost.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseDialog} color="primary">
          Disagree
        </Button>
        <Button
          onClick={() => handleDeleteCharge(anchorElRow.accountingId, anchorElRow.number)}
          color="primary"
          autoFocus
        >
          Agree
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationModal;
