import { useSocket } from 'contexts/socketcontext/hooks';
import { debounce } from 'debounce';
import { listOrders } from 'graphql/orders';
import { useCallback, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { getProfile } from 'redux/profile-reducer/selectors';
import { ListOrdersParams } from 'types/common';
import { infoUpdateType } from 'types/notifications';

const useLayoutHeader = () => {
  const profile = useSelector(getProfile);
  const { socket } = useSocket();
  const [isConnectedSocket, setIsConnectedSocket] = useState(false);
  const [notifications, setNotifications] = useState<infoUpdateType[]>([]);
  const [newNotification, setNewNotification] = useState<infoUpdateType>();

  const [anchorNotifications, setAnchorNotifications] = useState<HTMLButtonElement | null>(null);

  const handleClickNotifications = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setAnchorNotifications(event.currentTarget);
  };

  const handleCloseNotifications = () => {
    setAnchorNotifications(null);

    const viewedNotifications = notifications.map((notification) => ({
      ...notification,
      viewed: true,
    }));

    setNotifications(viewedNotifications);
  };

  const openNotifications = Boolean(anchorNotifications);

  const [params, setParams] = useState<ListOrdersParams>({
    searchText: '',
    pagination: {
      items: 20,
      page: 1,
    },
  });

  const getOrders = useQuery([params, 'global-orders'], listOrders, {
    enabled: params.searchText !== '',
    refetchOnWindowFocus: false,
  });

  const orders = getOrders.data?.listOrders.orders || [];

  const searchTextDebounce = useCallback(
    debounce((text: string) => {
      setParams((prevParams) => ({
        ...prevParams,
        searchText: text,
      }));
    }, 1000),
    []
  );

  const updateSearchText = useCallback(
    (text: string) => {
      searchTextDebounce.clear();
      searchTextDebounce(text);
    },
    [searchTextDebounce]
  );

  useEffect(() => {
    if (socket && socket.active) {
      socket.on('connect', () => {
        setIsConnectedSocket(true);
      });

      socket.on('info-update', (data: infoUpdateType) => {
        const actualDate = new Date();
        const notification = {
          ...data,
          timestamp: actualDate.getTime(),
        };

        if (notification.name !== 'Worker') {
          setNotifications((prev) => [...prev, { ...notification }]);

          setNewNotification(notification);
        }
      });

      socket.on('disconnect', () => {
        setIsConnectedSocket(false);
      });
    }
  }, [socket]);

  return {
    isConnectedSocket,
    options: orders,
    loading: getOrders.isLoading,
    updateSearchText,
    profile,
    notifications,
    newNotification,
    handleClickNotifications,
    anchorNotifications,
    openNotifications,
    handleCloseNotifications,
  };
};

export default useLayoutHeader;
