import React, {
  createContext,
  FC,
  useContext,
  useRef,
} from "react";

import { LlmProxyService } from "services/inference/LlmProxyService";

type LLMProxyServiceContextType = {
  llmServiceRef: React.MutableRefObject<LlmProxyService>
}

const LLMProxyServiceContext = createContext<LLMProxyServiceContextType | null>(null);

export const LLMPRoxyServiceProvider: FC<{ children: React.ReactNode }> = ({ children }) => {
  const llmServiceRef = useRef(new LlmProxyService());

  return (
    <LLMProxyServiceContext.Provider value={{
      llmServiceRef
    }}
    >
      {children}
    </LLMProxyServiceContext.Provider>
  );
};

export const useLLMProxyServiceContext = () => {
  const methods = useContext(LLMProxyServiceContext) as LLMProxyServiceContextType;
  return methods;
};