import { format } from 'date-fns';
import { useGetListings } from 'hooks/useGetListings';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { LEDGER_CODE_LOAN_AMOUNT, LEDGER_CODE_PURCHASE_PRICE } from 'utils/constants';

import { gqlUpdateOrder } from './graphql/mutations';
import { getOrderConfiguration, GetOrderConfigurationsType } from './graphql/queries';
import { DatesAndConfigurationForm, updateType } from './services';

const useDatesAndConfig = () => {
  const { id: orderId } = useParams<{ id: string }>();
  const [info, setInfo] = useState<updateType>({ time: '', type: 'info', message: 'listen updates' });
  const methods = useForm<DatesAndConfigurationForm>({
    defaultValues: {
      order_id: '',
      acceptance_date: '',
      closing_date: '',
      contract_date: '',
      disbursement_date: '',
      funding_date: '',
      transaction_type: '',
      type: '',
      workflow: '',
      heloc: false,
      construction_loan: false,
      cash_only: false,
      closing_place: '',
      additional_fees_letter: 'B'
    }
  });

  const { data: configurationOrder } = useQuery<GetOrderConfigurationsType>(
    ['order-configuration', orderId],
    () => getOrderConfiguration({ id: orderId, filters: { accounting: 'premium' } }),
    {
      refetchOnWindowFocus: false,
      enabled: !!orderId,
      onSuccess: (data) => {
        const { reset } = methods;
        const { configuration, order_id } = data.getOrder;
        reset({
          ...configuration,
          closing_place: configuration.closing_place?._id,
          order_id,
          additional_fees_letter: configuration?.additional_fees_letter || 'B'
        });
      }
    }
  );

  const [updateOrder, updateOrderResponse] = useMutation(gqlUpdateOrder, {
    onSuccess: () => {
      updateOrderResponse.reset();
      const now = format(new Date(), 'hh:mm');
      methods.reset(methods.getValues(), { keepValues: true });
      setInfo({ time: now, type: 'success', message: `updated at  ${now}` });
    },
  });

  const { data: transactionsResponse } = useGetListings({ code: "allowed_transactions", queryConfig: { refetchOnWindowFocus: false } });
  const { data: workflowsResponse } = useGetListings({ code: "allowed_workflows", queryConfig: { refetchOnWindowFocus: false } });
  const transactionTypes = transactionsResponse?.getListing.entries ?? [];
  const workflows = workflowsResponse?.getListing.entries ?? [];

  const accounting = configurationOrder?.getOrder.accounting || [];
  const totalLoanAmount = accounting.find((entry) => entry.code === LEDGER_CODE_LOAN_AMOUNT)?.amount || 0;
  const totalPurchasePrice = accounting.find((entry) => entry.code === LEDGER_CODE_PURCHASE_PRICE)?.amount || 0;

  return {
    transactionTypes,
    workflows,
    orderId,
    totalLoanAmount,
    totalPurchasePrice,
    methods,
    parties: configurationOrder?.getOrder.parties || [],
    previousclosingPlace: configurationOrder?.getOrder.configuration.closing_place,
    updateOrder,
    info
  };
};

export default useDatesAndConfig;
