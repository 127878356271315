import { useMutation } from "react-query";
import querys from "services/querys";
import { GQLCreateAssociation } from "services/querys/orders";
import useAlert from "utils/alert";

const useCreateAssociation = (callback?:(data: GQLCreateAssociation)=>void) => {
  const showAlert = useAlert();
  const [createAssociation, responseCreateAssociation] = useMutation(
    querys.createAssociation,
    {
      onSuccess: (data) => {
        responseCreateAssociation.reset();
        if (callback) {
          callback(data);
        }
      },
      onError: () => {
        showAlert('There was an error, try again', 'error');
      }
    }
  );
  return {
    createAssociation,
    responseCreateAssociation
  };
};

export default useCreateAssociation;