import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  IconButton,
  makeStyles,
  Tab,
  Tabs,
  Typography,
} from '@material-ui/core';
import clsx from 'clsx';
import { useWorkspaceContext } from 'contexts/workspace-context';
import React from 'react';

const useStyles = makeStyles((theme: any) => ({
  tabIndicator: { backgroundColor: theme.palette.secondary.dark },
  tab: {
    height: theme.spacing(8),
    padding: theme.spacing(2),
  },
  tabSelected: { backgroundColor: theme.palette.background.paper },
  tabLabel: {
    padding: 0,
    margin: 0,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  label: {
    marginRight: theme.spacing(2),
    fontWeight: 'bold',
  },
  labelSelected: { color: theme.palette.common.black },
  iconContainer: {
    fontSize: 14,
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
    padding: 0,
  },
  iconButton: {
    fontSize: 14,
    width: theme.spacing(3),
    height: theme.spacing(3),
    borderRadius: theme.spacing(0.5),
    zIndex: 100
  },
  icon: { color: theme.palette.dualBackground.default },
  iconSelected: { color: theme.palette.common.black },
}));

const OrderOpenTabs = () => {
  const classes = useStyles();

  const {
    removeOrderTab,
    selectOrderTab,
    selectedOrderIdTab,
    openedOrderIdList
  } = useWorkspaceContext();

  return (
    selectedOrderIdTab ? (
      <Tabs
        indicatorColor="primary"
        textColor="primary"
        variant="scrollable"
        value={selectedOrderIdTab.id}
        TabIndicatorProps={{ className: classes.tabIndicator }}
      >
        {openedOrderIdList.map((tab) => (
          <Tab
            key={tab.id}
            value={tab.id}
            className={clsx(classes.tab, { [classes.tabSelected]: tab.id === selectedOrderIdTab.id })}
            onClick={() => selectOrderTab(tab)}
            label={(
              <div className={classes.tabLabel}>
                <Typography
                  variant="caption"
                  className={clsx(classes.label, { [classes.labelSelected]: tab.id === selectedOrderIdTab.id })}
                >
                  {tab.firstPropertyAddress && tab.firstPropertyAddress !== "" ? tab.firstPropertyAddress : tab.orderId}
                </Typography>

                <div className={classes.iconContainer}>
                  <span>
                    <IconButton
                      component="div"
                      className={classes.iconButton}
                      onClick={(e: any) => {
                        e.stopPropagation();
                        removeOrderTab(tab.id);
                      }}
                    >
                      <FontAwesomeIcon
                        icon="times"
                        size="xs"
                        className={clsx(classes.icon, { [classes.iconSelected]: tab.id === selectedOrderIdTab.id })}
                      />
                    </IconButton>
                  </span>
                </div>
              </div>
            )}
          />
        ))}
      </Tabs>
    ) : null);
};

export default OrderOpenTabs;
