import { Grid } from '@material-ui/core';
import TextField from 'components/text-field';
import React, { FC } from 'react';
import { Control, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { formInfoOrder } from '../services';
import SectionTitle from './section-title';
import { ListingEntry } from 'graphql/listings/queries';
import SelectField from 'components/select-field';

type orderTypeFormProps = {
  control: Control<formInfoOrder, object>,
  transactionTypes: ListingEntry[];
  types: ListingEntry[];
  workflows: ListingEntry[];
  selectTransactionType: (code: string) => void;
}

const OrderTypeForm: FC<orderTypeFormProps> = ({
  control,
  transactionTypes,
  types,
  workflows,
  selectTransactionType
}) => {
  const { t } = useTranslation();
  return (
    <>
      <SectionTitle title="Order Information" />
      <Grid item>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <Grid container direction="row" spacing={2}>
              <Grid item xs={6}>
                <Controller
                  control={control}
                  name="transaction_type"
                  render={({ field: { ref, ...field } }) => (
                    <SelectField
                      {...field}
                      inputRef={ref}
                      data={transactionTypes}
                      dataKey="code"
                      dataText="description"
                      dataValue="code"
                      label={t('common:transaction-types')}
                      handleChange={(event) => {
                        selectTransactionType(event.target.value);
                        field.onChange(event);
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  control={control}
                  name="order_type"
                  render={({ field: { ref, ...field } }) => (
                    <SelectField
                      {...field}
                      inputRef={ref}
                      data={types}
                      dataKey="code"
                      dataText="description"
                      dataValue="code"
                      disabled={types.length === 0}
                      label={t('common:type')}
                      handleChange={field.onChange}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container direction="row" spacing={2}>
              <Grid item>
                <Controller
                  control={control}
                  name="workflow"
                  render={({ field: { ref, ...field } }) => (
                    <SelectField
                      {...field}
                      inputRef={ref}
                      data={workflows}
                      dataKey="code"
                      dataText="description"
                      dataValue="code"
                      disabled={workflows.length === 0}
                      label={t('common:workflow')}
                      handleChange={field.onChange}
                    />
                  )}
                />
              </Grid>
              <Grid item>
                <Controller
                  control={control}
                  name="loan_number"
                  rules={{ required: true }}
                  render={({ field: { ref, ...field } }) => (
                    <TextField
                      {...field}
                      inputRef={ref}
                      label={t('orders:loan-number')}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Grid>

        </Grid>
      </Grid>
    </>
  );
};

export default OrderTypeForm;
