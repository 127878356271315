import { Box, makeStyles } from '@material-ui/core';
import React from 'react';
import { Control } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { OrderProperty } from 'types/order';

import { PropertyTextField } from '../PropertyTextField';

const useStyles = makeStyles((theme: any) => ({
  container: {
    marginTop: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    gap: "16px"
  },
}));

type legalDescriptionType = {
  control: Control<OrderProperty, object>,
}

export const LegalDescription = ({ control }: legalDescriptionType) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <PropertyTextField
        control={control}
        name="brief_legal_description"
        label="Brief Legal Description"
        multiline
      />

      <PropertyTextField
        control={control}
        name="legal_description"
        label={t('orders:property-description')}
        multiline
      />
    </Box>
  );
};
