import gqlRequest from 'graphql/generic';
import { AllowedLedgerListingType } from 'types/ledger';
import { AccountingFilter, AccountingType } from 'v2-types/order';

type GetledgerAllowedListings = {
  getListing: AllowedLedgerListingType
};

type GetLedgerAllowedParams = {code: string };

const queryCodes = `
  query GetListing($code: String!) {
    getListing(code: $code) {
      code
      entries {
        code
        description
      }
    }
  }
`;
export const getLedgerListings = (code:string) => gqlRequest<GetledgerAllowedListings, GetLedgerAllowedParams>(queryCodes, { code });

export type GetOrderAccountingReturn = {
  getOrder: {
    accounting: AccountingType[]
  }
}

const queryOrderAccounting = `
  query GetOrder($id: ID!, $filters: OrderFilters) {
    getOrder(_id: $id, filters: $filters) {
      accounting {
        _id
        amount
        amount_calculated
        amount_override
        code
        description
        entry_date
        involved_parties {
          _id
          amount
          at_closing_amount
          at_closing_percent
          before_closing_amount
          before_closing_percent
          kind
          member_of {
            _id
            kind
            name
          }
          name
          order_kinds
          payment_id
          payment_kind
          payment_reference
          percent
        }
        kind
        letter
        number
      }
    }
  }
`;

type GetOrderAccountingParams = {
  id: string,
  filters?: {[key: string]: AccountingFilter}
}

export const getOrderAccounting = (params: GetOrderAccountingParams) => gqlRequest<GetOrderAccountingReturn, GetOrderAccountingParams>(queryOrderAccounting, params);
