import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  makeStyles,
} from '@material-ui/core';
import clsx from 'clsx';

import React from 'react';
import useTableCharges from '../hooks/tableCharges-hooks';
import NumberField from 'components/number-field';
import TextField from 'components/text-field';
import { Controller } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MenuRow from './menuRow';
import ConfirmationModal from './confirmationModal';
import SearchContact from './searchContact';
import { AccountingType } from 'v2-types/order';
import { formatMoney } from 'utils/helpers';
import { Autocomplete } from '@material-ui/lab';
import { textPredectiveCharges } from 'utils/constants';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1000,
    color: '#fff',
  },
  container: {
    padding: `${theme.spacing(2)}px`,
  },
  buttonContainer: {
    padding: `${theme.spacing(2)}px`,
  },
  tableContainer: {
    maxHeight: '460px',
  },
  table: {
    padding: `${theme.spacing(2)}px`,
  },
  tableHeadRowFirst: {
    position: 'sticky',
    background: 'white',
    zIndex: theme.zIndex.drawer + 1,
    top: 0,
  },
  tableHeadRowSecond: {
    position: 'sticky',
    background: 'white',
    zIndex: theme.zIndex.drawer + 1,
    top: 56,
  },
  searchContact: {
    '& .MuiInputLabel-filled': {
      color: theme.palette.primary.dark,
    },
    '& .MuiFilledInput-root': {
      backgroundColor: theme.palette.background.default,
      color: theme.palette.primary.dark,
      border: 'unset',
      borderRadius: '4px',

      '&.MuiFilledInput-underline::before': {
        content: 'unset',
      },

      '&.MuiFilledInput-underline::after': {
        content: 'unset',
      },
    },
  },
  numberField: {
    '& .MuiOutlinedInput-root': {
      backgroundColor: theme.palette.background.default,
      color: theme.palette.primary.dark,
      borderRadius: '5px',
      fontSize: '14px',

      '& .MuiOutlinedInput-notchedOutline': {
        border: 'unset',
      },

      '& .MuiOutlinedInput-input ': {
        textAlign: 'center',
      },
    },
  },
  inputChangeOverride: {
    '& .MuiOutlinedInput-root': {
      backgroundColor: theme.palette.warning.main,
    },
  },
  textField: {
    '& .MuiOutlinedInput-root': {
      backgroundColor: theme.palette.background.default,
      borderRadius: '5px',
      color: theme.palette.primary.dark,
      fontSize: '14px',

      '& .MuiOutlinedInput-notchedOutline': {
        border: 'unset',
      },
    },
  },
  tableHeaderPayee: {
    minWidth: '300px',
  },
  tableHeaderDescription: {
    minWidth: '350px',
  },
  tableHeaderNumber: {
    minWidth: '90px',
  },
  numberInput: {
    '& .MuiOutlinedInput-root': {
      width: '35px',
      height: '35px',
      backgroundColor: theme.palette.secondary.main,
      borderRadius: '5px',
      color: theme.palette.primary.dark,

      '& .MuiOutlinedInput-notchedOutline': {
        border: 'unset',
      },

      '& .MuiOutlinedInput-input ': {
        padding: `${theme.spacing(0.25)}px`,
        textAlign: 'center',
      },
    },
  },
  numberContainer: {
    width: '35px',
    height: '35px',
    backgroundColor: theme.palette.secondary.main,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '5px',
    color: theme.palette.primary.dark,
  },
  tableChargeRow: {
    padding: `${theme.spacing(1)}px`,
  },
  addEntry: {
    textTransform: 'capitalize',
  },
  updateCharges: {
    textTransform: 'capitalize',
  },
  tableMenuRow: {
    padding: `${theme.spacing(1)}px`,
    textAlign: 'center',
    cursor: 'pointer',
  },
}));

const lettersPerMonth = ['F', 'G'];

type TableChargesProps = {
  accountingData: AccountingType[];
  currentLetter: string;
  updatedAccounting: (data: any) => void;
  hideTax: boolean;
  policyIsLoading?: boolean;
};

const TableCharges = ({
  accountingData,
  currentLetter,
  updatedAccounting,
  hideTax,
  policyIsLoading,
}: TableChargesProps) => {
  const classes = useStyles();
  const {
    hanldeUpdateCharge,
    isLoading,
    t,
    anchorElRow,
    handleClickMenu,
    handleCloseMenu,
    openMenu,
    openDialog,
    handleCloseDialog,
    handleOpenDialog,
    handleDeleteCharge,
    formatedAccountingData,
    hanldeAddEmptyCharges,
    control,
    getValues,
    handleSubmit,
    isValid,
    handleChangeAmount,
    chargesOverrides,
    handleChangeMonth,
  } = useTableCharges(accountingData, currentLetter, updatedAccounting);

  const onSubmit = (data) => {
    const arrayChargesToUpload = data.charges.map((charge) => {
      const newCharge = charge;
      delete newCharge.kind;
      delete newCharge.payee;
      return newCharge;
    });

    hanldeUpdateCharge(arrayChargesToUpload);
  };

  const getColSpan = () => {
    const initSpan = lettersPerMonth.includes(currentLetter) ? 6 : 4;
    return hideTax ? initSpan : initSpan + 1;
  };

  const filterDescription = (options, value) => {
    return options.filter((option) => option.toLowerCase().includes(value.toLowerCase()));
  };

  return (
    <Box className={classes.container}>
      <TableContainer className={classes.tableContainer}>
        <form>
          <Table className={classes.table}>
            <TableHead>
              <TableRow className={classes.tableHeadRowFirst}>
                <TableCell align="center" colSpan={getColSpan()} />
                <TableCell align="center" colSpan={2}>
                  {t('charges:paid-by-borrower')}
                </TableCell>
                <TableCell align="center" colSpan={2}>
                  {t('charges:paid-by-seller')}
                </TableCell>
                <TableCell />
              </TableRow>
              <TableRow className={classes.tableHeadRowSecond}>
                <TableCell />
                <TableCell />
                <TableCell className={classes.tableHeaderDescription}>
                  {t('charges:description')}
                </TableCell>
                <TableCell className={classes.tableHeaderPayee}>{t('charges:payee')}</TableCell>
                {lettersPerMonth.includes(currentLetter) && (
                  <>
                    <TableCell className={classes.tableHeaderNumber}>
                      {t('charges:per-month')}
                    </TableCell>
                    <TableCell className={classes.tableHeaderNumber}>
                      {t('charges:number-of-month')}
                    </TableCell>
                  </>
                )}
                {!hideTax && (
                  <TableCell className={classes.tableHeaderNumber}>{t('charges:tax')}</TableCell>
                )}
                <TableCell className={classes.tableHeaderNumber}>
                  {t('charges:at-closing')}
                </TableCell>
                <TableCell className={classes.tableHeaderNumber}>
                  {t('charges:before-closing')}
                </TableCell>
                <TableCell className={classes.tableHeaderNumber}>
                  {t('charges:at-closing')}
                </TableCell>
                <TableCell className={classes.tableHeaderNumber}>
                  {t('charges:before-closing')}
                </TableCell>
                <TableCell className={classes.tableHeaderNumber}>
                  {t('charges:by-others')}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {formatedAccountingData.map((charge, index) => {
                const getTilteTooltip = (isOpen) => {
                  const amount = formatMoney(accountingData[index]?.amount_calculated || 0);
                  return isOpen ? `The amount of the charge should be ${amount}` : '';
                };

                return (
                  <TableRow key={`${charge.accounting_id}|${charge.number}`}>
                    {charge.kind === 'manual' ? (
                      <TableCell
                        className={classes.tableMenuRow}
                        onClick={(e) => handleClickMenu(e, charge.accounting_id, charge.number)}
                      >
                        <FontAwesomeIcon icon="ellipsis-v" />
                      </TableCell>
                    ) : (
                      <TableCell className={classes.tableChargeRow} />
                    )}
                    {charge.kind === 'manual' ? (
                      <TableCell className={classes.tableChargeRow}>
                        <Controller
                          name={`charges.${index}.number`}
                          control={control}
                          render={({ field: { ref, onChange, ...field } }) => (
                            <NumberField
                              {...field}
                              customRef={ref}
                              color="secondary"
                              className={classes.numberInput}
                              variant="outlined"
                              onValueChange={(prop) => {
                                onChange(prop.floatValue);
                              }}
                              value={getValues(`charges.${index}.number`)}
                            />
                          )}
                        />
                      </TableCell>
                    ) : (
                      <TableCell className={classes.tableChargeRow}>
                        <Box className={classes.numberContainer}>{charge.number}</Box>
                      </TableCell>
                    )}

                    {charge.kind === 'manual' ? (
                      <TableCell className={classes.tableChargeRow}>
                        <Controller
                          name={`charges.${index}.description`}
                          control={control}
                          render={({ field: { onChange, ref, ...field } }) => (
                            <Autocomplete
                              {...field}
                              innerRef={ref}
                              freeSolo
                              value={getValues(`charges.${index}.description`)}
                              options={textPredectiveCharges}
                              filterOptions={(options) => {
                                const value = getValues(`charges.${index}.description`);
                                if (value) {
                                  return filterDescription(options, value);
                                }
                                return options;
                              }}
                              getOptionLabel={(option) => option}
                              onInputChange={(_, value, reason) => {
                                if (reason === 'input') {
                                  onChange(value);
                                }
                              }}
                              onChange={(_, value) => {
                                onChange(value);
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  inputRef={params.InputProps.ref}
                                  InputProps={params.inputProps}
                                  customClassName={classes.textField}
                                  color="secondary"
                                  variant="outlined"
                                />
                              )}
                            />
                          )}
                        />
                      </TableCell>
                    ) : (
                      <TableCell className={classes.tableChargeRow}>{charge.description}</TableCell>
                    )}

                    <TableCell className={classes.tableChargeRow}>
                      <Controller
                        name={`charges.${index}.payee_id`}
                        control={control}
                        render={({ field: { ref, onChange, ...field } }) => (
                          <SearchContact
                            {...field}
                            reference={ref}
                            initialValue={{
                              _id: getValues(`charges.${index}.payee_id`),
                              name: formatedAccountingData[index].payee,
                            }}
                            getValue={(user) => {
                              onChange(user?._id ?? null);
                            }}
                          />
                        )}
                      />
                    </TableCell>

                    {lettersPerMonth.includes(currentLetter) && (
                      <>
                        <TableCell className={classes.tableChargeRow}>
                          <Controller
                            name={`charges.${index}.per_month`}
                            control={control}
                            render={({ field: { ref, onChange, ...field } }) => (
                              <NumberField
                                {...field}
                                customRef={ref}
                                color="secondary"
                                className={classes.numberField}
                                variant="outlined"
                                onValueChange={(prop) => {
                                  onChange(prop.floatValue);
                                  handleChangeMonth(getValues(`charges.${index}`), index);
                                }}
                                value={getValues(`charges.${index}.per_month`)}
                              />
                            )}
                          />
                        </TableCell>
                        <TableCell className={classes.tableChargeRow}>
                          <Controller
                            name={`charges.${index}.months`}
                            control={control}
                            render={({ field: { ref, onChange, ...field } }) => (
                              <NumberField
                                {...field}
                                customRef={ref}
                                color="secondary"
                                className={classes.numberField}
                                variant="outlined"
                                onValueChange={(prop) => {
                                  onChange(prop.floatValue);
                                  handleChangeMonth(getValues(`charges.${index}`), index);
                                }}
                                value={getValues(`charges.${index}.months`)}
                              />
                            )}
                          />
                        </TableCell>
                      </>
                    )}

                    {!hideTax && (
                      <Tooltip
                        title={getTilteTooltip(
                          (chargesOverrides[`charge${index}`] || []).includes('tax')
                        )}
                        arrow
                        placement="top"
                      >
                        <TableCell className={classes.tableChargeRow}>
                          <Controller
                            name={`charges.${index}.tax`}
                            control={control}
                            render={({ field: { ref, onChange, ...field } }) => (
                              <NumberField
                                {...field}
                                customRef={ref}
                                color="secondary"
                                className={clsx({
                                  [classes.numberField]: true,
                                  [classes.inputChangeOverride]: (
                                    chargesOverrides[`charge${index}`] || []
                                  ).includes('tax'),
                                })}
                                onValueChange={(prop) => {
                                  onChange(prop.floatValue);
                                  handleChangeAmount(getValues(`charges.${index}`), index, 'tax');
                                }}
                                prefix="$"
                                thousandSeparator
                                variant="outlined"
                                value={getValues(`charges.${index}.tax`)}
                              />
                            )}
                          />
                        </TableCell>
                      </Tooltip>
                    )}
                    <Tooltip
                      title={getTilteTooltip(
                        (chargesOverrides[`charge${index}`] || []).includes('buyer_at')
                      )}
                      arrow
                      placement="top"
                    >
                      <TableCell className={classes.tableChargeRow}>
                        <Controller
                          name={`charges.${index}.buyer_at`}
                          control={control}
                          render={({ field: { ref, onChange, ...field } }) => (
                            <NumberField
                              {...field}
                              customRef={ref}
                              color="secondary"
                              className={clsx({
                                [classes.numberField]: true,
                                [classes.inputChangeOverride]: (
                                  chargesOverrides[`charge${index}`] || []
                                ).includes('buyer_at'),
                              })}
                              onValueChange={(prop) => {
                                onChange(prop.floatValue);
                                handleChangeAmount(
                                  getValues(`charges.${index}`),
                                  index,
                                  'buyer_at'
                                );
                              }}
                              prefix="$"
                              thousandSeparator
                              variant="outlined"
                              value={getValues(`charges.${index}.buyer_at`)}
                            />
                          )}
                        />
                      </TableCell>
                    </Tooltip>

                    <Tooltip
                      title={getTilteTooltip(
                        (chargesOverrides[`charge${index}`] || []).includes('buyer_before')
                      )}
                      arrow
                      placement="top"
                    >
                      <TableCell className={classes.tableChargeRow}>
                        <Controller
                          name={`charges.${index}.buyer_before`}
                          control={control}
                          render={({ field: { ref, onChange, ...field } }) => (
                            <NumberField
                              {...field}
                              customRef={ref}
                              color="secondary"
                              className={clsx({
                                [classes.numberField]: true,
                                [classes.inputChangeOverride]: (
                                  chargesOverrides[`charge${index}`] || []
                                ).includes('buyer_before'),
                              })}
                              onValueChange={(prop) => {
                                onChange(prop.floatValue);
                                handleChangeAmount(
                                  getValues(`charges.${index}`),
                                  index,
                                  'buyer_before'
                                );
                              }}
                              prefix="$"
                              thousandSeparator
                              variant="outlined"
                              value={getValues(`charges.${index}.buyer_before`)}
                            />
                          )}
                        />
                      </TableCell>
                    </Tooltip>

                    <Tooltip
                      title={getTilteTooltip(
                        (chargesOverrides[`charge${index}`] || []).includes('seller_at')
                      )}
                      arrow
                      placement="top"
                    >
                      <TableCell className={classes.tableChargeRow}>
                        <Controller
                          name={`charges.${index}.seller_at`}
                          control={control}
                          render={({ field: { ref, onChange, ...field } }) => (
                            <NumberField
                              {...field}
                              customRef={ref}
                              color="secondary"
                              className={clsx({
                                [classes.numberField]: true,
                                [classes.inputChangeOverride]: (
                                  chargesOverrides[`charge${index}`] || []
                                ).includes('seller_at'),
                              })}
                              onValueChange={(prop) => {
                                onChange(prop.floatValue);
                                handleChangeAmount(
                                  getValues(`charges.${index}`),
                                  index,
                                  'seller_at'
                                );
                              }}
                              prefix="$"
                              thousandSeparator
                              variant="outlined"
                              value={getValues(`charges.${index}.seller_at`)}
                            />
                          )}
                        />
                      </TableCell>
                    </Tooltip>

                    <Tooltip
                      title={getTilteTooltip(
                        (chargesOverrides[`charge${index}`] || []).includes('seller_before')
                      )}
                      arrow
                      placement="top"
                    >
                      <TableCell className={classes.tableChargeRow}>
                        <Controller
                          name={`charges.${index}.seller_before`}
                          control={control}
                          render={({ field: { ref, onChange, ...field } }) => (
                            <NumberField
                              {...field}
                              customRef={ref}
                              color="secondary"
                              className={clsx({
                                [classes.numberField]: true,
                                [classes.inputChangeOverride]: (
                                  chargesOverrides[`charge${index}`] || []
                                ).includes('seller_before'),
                              })}
                              onValueChange={(prop) => {
                                onChange(prop.floatValue);
                                handleChangeAmount(
                                  getValues(`charges.${index}`),
                                  index,
                                  'seller_before'
                                );
                              }}
                              prefix="$"
                              thousandSeparator
                              variant="outlined"
                              value={getValues(`charges.${index}.seller_before`)}
                            />
                          )}
                        />
                      </TableCell>
                    </Tooltip>

                    <Tooltip
                      title={getTilteTooltip(
                        (chargesOverrides[`charge${index}`] || []).includes('by_others')
                      )}
                      arrow
                      placement="top"
                    >
                      <TableCell className={classes.tableChargeRow}>
                        <Controller
                          name={`charges.${index}.by_others`}
                          control={control}
                          render={({ field: { ref, onChange, ...field } }) => (
                            <NumberField
                              {...field}
                              customRef={ref}
                              color="secondary"
                              className={clsx({
                                [classes.numberField]: true,
                                [classes.inputChangeOverride]: (
                                  chargesOverrides[`charge${index}`] || []
                                ).includes('by_others'),
                              })}
                              onValueChange={(prop) => {
                                onChange(prop.floatValue);
                                handleChangeAmount(
                                  getValues(`charges.${index}`),
                                  index,
                                  'by_others'
                                );
                              }}
                              prefix="$"
                              thousandSeparator
                              variant="outlined"
                              value={getValues(`charges.${index}.by_others`)}
                            />
                          )}
                        />
                      </TableCell>
                    </Tooltip>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </form>
      </TableContainer>
      <Box
        className={classes.buttonContainer}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Button
          className={classes.addEntry}
          variant="outlined"
          color="secondary"
          onClick={hanldeAddEmptyCharges}
        >
          {t('charges:add-entry')}
        </Button>

        <Button
          className={classes.updateCharges}
          disabled={!isValid}
          color="secondary"
          variant="contained"
          onClick={handleSubmit(onSubmit)}
        >
          {t('charges:update-charges')}
        </Button>
      </Box>

      <Backdrop className={classes.backdrop} open={isLoading || Boolean(policyIsLoading)}>
        <CircularProgress color="secondary" />
      </Backdrop>

      <MenuRow
        handleOpenDialog={handleOpenDialog}
        anchorElRow={anchorElRow}
        openMenu={openMenu}
        handleCloseMenu={handleCloseMenu}
      />

      <ConfirmationModal
        anchorElRow={anchorElRow}
        handleDeleteCharge={handleDeleteCharge}
        openDialog={openDialog}
        handleCloseDialog={handleCloseDialog}
      />
    </Box>
  );
};

export default TableCharges;
