import { makeStyles } from '@material-ui/core';
import React from 'react';
import { formatStringInputToNumber } from 'utils/helpers';

import InputCell from './input-cell';
import { projectedPayments } from '../services';
import { Controller, UseFormReturn } from 'react-hook-form';

const useStyles = makeStyles((theme: any) => ({
  span: {
    display: 'flex',
    '&>*': {
      width: theme.spacing(10),
      marginRight: theme.spacing(5),
    }
  },
}));

type YearsRangeFieldsProps = {
  methods: UseFormReturn<projectedPayments, any, undefined>
};

const YearsRangeFields = ({ methods }: YearsRangeFieldsProps) => {
  const classes = useStyles();

  return (
    <span className={classes.span}>
      <Controller
        control={methods.control}
        name="year_range_1"
        render={({ field }) => (
          <InputCell
            value={field.value}
            onChangeInput={(e: any) => {
              const value = formatStringInputToNumber(e.target.value);
              field.onChange(value);
            }}
          />
        )}
      />

      <Controller
        control={methods.control}
        name="year_range_2"
        render={({ field }) => (
          <InputCell
            value={field.value}
            onChangeInput={(e: any) => {
              const value = formatStringInputToNumber(e.target.value);
              field.onChange(value);
            }}
          />
        )}
      />
    </span>
  );
};

export default YearsRangeFields;
