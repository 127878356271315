import { z } from "zod";
import { defaultZodSchema } from "./default";

const toolSchema = z.discriminatedUnion("tool", [
  z.object({
    tool: z.literal('createNewOrder').describe("this tool is usefull to create a new order"),
    toolParams: z.object({
      transactionType: z.string(),
      type: z.enum([
        "Closing Disclosure", "HUD 1", "Settlement Statement"
      ]).default("Closing Disclosure"),
      workflow: z.string(),
    }).describe("Tool parameters")
  }),

  z.object({
    tool: z.literal("createNewProperty").describe("this tool is usefull to create a new property"),
    toolParams: z.object({
      orderId: z.string().describe("This is the orderId of the order"),
      type: z.enum([
        'Residence (1-4 Families)',
        'Residence (5+ Families)',
        'Commercial', 'Condominium',
        'Planned Unit Development',
        'Land and Buildings',
        'Land Only',
        'Leasehold',
        'Commercial Leasehold',
        'Manufactured Home',
        'Mobile Home',
        'Wetlands'
      ]),
      block: z.string().nullable(),
      jurisdiction: z.string().describe("should be on of the following values: Boroug,City,Town,Township,Unincorporated area,Village"),
      legal_description: z.string().nullable(),
      lot: z.string().nullable(),
      parcel_ids: z.string().nullable(),
      range: z.string().nullable(),
      section: z.string().nullable(),
      subdivision: z.string().nullable(),
      survey_date: z.string().describe("Date in format 'yyyy-MM-dd'").nullable(),
      survey_information: z.string().nullable(),
      survey_number: z.string().nullable(),
      township: z.string().nullable(),
      street: z.string().describe("is the street of the property"),
      settlement: z.string().nullable(),
      references: z.string().nullable(),
      postal_code: z.string().describe("this property is a zip code mostly represented with 5 numbers"),
      locality: z.string().nullable(),
      state: z.string().describe("this property is a state represented like TX"),
      country: z.string().describe("this property is a country commonly represented as US")
    }).describe("try to fill all the properties based on the direction that the user gives you")
  }),

  z.object({
    tool: z.literal("updateProperty").describe("this tool is usefull to update data of a property"),
    toolParams: z.object({
      orderId: z.string().describe("This is the orderId of the order"),
      propertyId: z.string().describe("This is the propertyId"),
      brief_legal_description: z.string().optional(),
      type: z.enum([
        'Residence (1-4 Families)',
        'Residence (5+ Families)',
        'Commercial', 'Condominium',
        'Planned Unit Development',
        'Land and Buildings',
        'Land Only',
        'Leasehold',
        'Commercial Leasehold',
        'Manufactured Home',
        'Mobile Home',
        'Wetlands'
      ]).optional(),
      block: z.string().optional(),
      jurisdiction: z.string().describe("should be on of the following values: Boroug,City,Town,Township,Unincorporated area,Village").optional(),
      legal_description: z.string().optional(),
      lot: z.string().optional(),
      parcel_ids: z.string().optional(),
      range: z.string().optional(),
      section: z.string().optional(),
      subdivision: z.string().optional(),
      survey_date: z.string().describe("Date in format 'yyyy-MM-dd'").optional(),
      survey_information: z.string().optional(),
      survey_number: z.string().optional(),
      township: z.string().optional(),
    }).describe("try to fill all the properties based on the direction that the user gives you")
  }),

  z.object({
    tool: z.literal("removeProperty").describe("this tool is usefull to delete a property"),
    toolParams: z.object({
      orderId: z.string().describe("This is the orderId of the order"),
      propertyId: z.string().describe("This is the propertyId"),
    }).describe("try to fill all the properties based on the direction that the user gives you")
  }),

  z.object({
    tool: z.literal("updatePropertyPurchasePrice").describe("this tool is usefull to update the purchase price of a property"),
    toolParams: z.object({
      orderId: z.string().describe("This is the orderId of the order"),
      purchasePriceEntryId: z.string().describe("This is the purchase price entry Id of the purchase price"),
      amount: z.number(),
    }).describe("try to fill all the properties based on the direction that the user gives you")
  }),
]).nullable();

export const propertiesToolsZodSchema = z.intersection(
  defaultZodSchema,
  z.object({
    toolsToUse: z.array(toolSchema).nullable().describe("Tools to use if are necesary"),
  })
);