import { Box, Grid, Switch, Typography } from '@material-ui/core';
import React, { FC, useEffect } from 'react';
import { formatMoney } from 'utils/helpers';
import { AccountingType } from 'v2-types/order';

import { useStyles } from '../../styles';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Title from 'views/orders/dates-and-config/components/title';
import SaveButton from 'components/common/save-button';
import { gqlUpdateOrder } from 'views/orders/dates-and-config/graphql/mutations';
import { useMutation } from 'react-query';
import { useParams } from 'react-router-dom';
import useAlert from 'utils/alert';

type formAccountingfees = {
  additional_fees_letter: string
}

type Props = {
  isDataLoading?: boolean,
  orderAccountingEscrowEntries: AccountingType[],
  additionalFeesLetter?: string | null
}

export const EscrowAmounts: FC<Props> = ({ isDataLoading, orderAccountingEscrowEntries, additionalFeesLetter }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const showAlert = useAlert();
  const methods = useForm<formAccountingfees>({
    defaultValues: {
      additional_fees_letter: 'B'
    }
  });

  useEffect(() => {
    methods.reset({ additional_fees_letter: additionalFeesLetter || 'B' });
  }, [additionalFeesLetter, methods]);

  const [updateOrder, updateOrderResponse] = useMutation(gqlUpdateOrder, {
    onSuccess: () => {
      updateOrderResponse.reset();
      showAlert(t('charges:success-updated'), 'success');
    },
    onError: () => {
      showAlert(t('documents:error-updated'), 'error');
    },
  });

  return (
    <Box>
      <Typography variant="h6" className={classes.subTitle}>
        Escrow Amounts
      </Typography>
      <Box style={{ display: "flex", flexDirection: "row", gap: "16px" }}>
        {orderAccountingEscrowEntries.map((entry) => (
          <Typography style={{ textTransform: "capitalize" }}>
            {entry.code.replace("_", " ")}
            :
            {' '}
            {formatMoney(entry.amount)}
          </Typography>
        ))}
      </Box>
      <form>
        <Grid item xs={12} style={{ marginTop: 16, marginBottom: 8 }}>
          <Title>{t('datesAndConfig:additional-letter-fees')}</Title>
        </Grid>
        <Grid item xs={12}>
          <Grid container direction="row" spacing={3}>
            <Grid item>
              <Controller
                control={methods.control}
                name="additional_fees_letter"
                render={({ field }) => (
                  <Box display="flex" flexDirection="row" alignItems="center">
                    <Typography>B</Typography>
                    <Switch
                      {...field}
                      checked={field.value === 'C'}
                      color="secondary"
                      onChange={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        if (e.target.checked) {
                          field.onChange('C');
                        } else {
                          field.onChange('B');
                        }
                      }}
                    />
                    <Typography>C</Typography>
                  </Box>
                )}
              />
            </Grid>
            <Grid item>
              <SaveButton
                size="small"
                loading={updateOrderResponse.isLoading}
                disabled={updateOrderResponse.isLoading}
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  methods.handleSubmit((data) => {
                    updateOrder({
                      id,
                      data: {
                        additional_fees_letter: data.additional_fees_letter
                      } as any
                    });
                  })(e);
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};
