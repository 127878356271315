import React, { FC, useState } from 'react';
import { Box, IconButton, makeStyles, Popover, Typography } from '@material-ui/core';
import { Controller, UseFieldArrayUpdate, UseFormReturn } from 'react-hook-form';
import { formType } from '../services';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import TextField from 'components/text-field';
import SearchContact from 'components/add-contact-modal/components/simple-search-contact';
import ContactCreation from 'components/users/form-contact-creation/contact-creation';

const useStyles = makeStyles((theme: any) => ({
  fontSize: { fontSize: '14px' },
  description: { fontWeight: 500, color: 'black', marginBottom: theme.spacing(1) },
  textFound: { fontWeight: 500, color: '#607D8B', marginBottom: 12 },
  deleteIcon: {
    marginLeft: 24,
    backgroundColor: '#CFD8DC',
    padding: 8,
    borderRadius: 4
  }

}));

type PayeeSelectionProps = {
  methods: UseFormReturn<formType, any, undefined>
  index: number,
  label?: string,
  rows?: number,
  chargeskey: "charges" | "existingCharges",
  row: any
}

const PayeeSelection: FC<PayeeSelectionProps> = ({
  methods,
  index,
  label,
  rows,
  chargeskey,
  row,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const [showContactForm, setShowContactForm] = useState(false);
  const classes = useStyles();
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const { control, getValues, setValue } = methods;
  const payeeFound = row.payeeText;
  const payeeSelected = getValues(`${chargeskey}.${index}.payee.name`) || '';
  const alert = !!payeeFound;
  return (
    <Box style={{ width: '100%' }} display="flex" flexDirection="row">
      <Controller
        control={control}
        name={`${chargeskey}.${index}.payee.name`}
        render={({ field: { ref, ...field } }) => (
          <TextField
            {...field}
            fullWidth
            label={label}
            variant="standard"
            value={field.value}
            InputProps={{
              disableUnderline: true,
              size: 'small',
              margin: 'dense',
              style: {
                fontSize: '14px',
                color: 'black'
              }
            }}
            multiline
            disabled
            rows={rows || 0}
          />
        )}
      />
      <IconButton style={{ padding: 0 }} onClick={handleClick}>
        <FontAwesomeIcon icon="info-circle" size="xs" style={{ fontSize: '14px', color: alert ? 'red' : 'black' }} />
      </IconButton>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        keepMounted={false}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}

      >
        <Box style={{ padding: 12, width: 500 }}>
          {!showContactForm ?

            <Box style={{ width: '100%' }} display="flex" flexDirection="column">
              {payeeFound !== '' &&
                <>
                  <Typography variant="body2" className={classes.description}>We found the next text as payee in the document:</Typography>
                  <Typography variant="body2" className={classes.textFound}>{`${payeeFound}`}</Typography>
                </>}
              {
                payeeSelected === '' ?
                  (
                    <>
                      <Typography variant="body2" className={classes.description}>Please select a contact, or you can create a new one in the last option:</Typography>
                      <SearchContact
                        getValue={(user) => {
                          setAnchorEl(null);
                          setValue(`${chargeskey}.${index}.payee`, {
                            name: user?.name || '',
                            id: user?._id || ''
                          });
                        }}
                        initialValue={payeeFound !== '' ? payeeFound : undefined}
                        handleContactCreation={() => {
                          setShowContactForm(true);
                        }}
                      />
                    </>
                  )
                  :
                  (
                    <>
                      <Typography variant="body2" className={classes.description}>Current contact selected as payee:</Typography>
                      <Box display="flex" flexDirection="row" style={{ alignContent: 'center', }}>
                        <Box style={{ alignContent: 'center', }}>
                          <Typography variant="body2" className={classes.description}>{`${payeeSelected}`}</Typography>
                        </Box>

                        <Box>
                          <IconButton
                            className={classes.deleteIcon}
                            onClick={(e) => {
                              e.stopPropagation();
                              setAnchorEl(null);
                              setValue(`${chargeskey}.${index}.payee`, {
                                name: '',
                                id: ''
                              });
                            }}
                          >
                            <FontAwesomeIcon icon="trash-alt" size="xs" style={{ fontSize: "14px", color: 'black' }} />
                          </IconButton>
                        </Box>
                      </Box>
                    </>
                  )
              }
            </Box>
            :
            <ContactCreation
              selectedKind="Person"
              searchText="Edgar"
              kindList={['Person', 'Company']}
              onSuccess={(params) => {
                setShowContactForm(false);
                setAnchorEl(null);
                setValue(`${chargeskey}.${index}.payee`, {
                  name: params?.name || '',
                  id: params?._id || ''
                });
              }}
              handleCancel={() => {
                setShowContactForm(false);
              }}
            />}
        </Box>
      </Popover>
    </Box>
  );
};

export default PayeeSelection;
