import { deleteLedgerEntry, deleteLedgerEntryReturn } from 'graphql/ledger/mutations';
import { useMutation } from 'react-query';

const useDeleteLedger = (
  onSuccess?: (data: deleteLedgerEntryReturn) => void,
  onError?: () => void
) => {
  const [deleteEntry, deleteEntryResponse] = useMutation(deleteLedgerEntry, { onSuccess, onError });

  return { deleteEntry, deleteEntryResponse };
};

export default useDeleteLedger;
