import {
  Box,
  makeStyles,
  Typography
} from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles((theme: any) => ({
  cardContainer: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    // height: 'auto',
    marginBottom: theme.spacing(3)
  },
  title: {
    fontWeight: 500,
    color: theme.palette.tab.offselected,
    marginBottom: theme.spacing(2)
  },
  titleContainer: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  }
}));

type OverviewCardtype = {
  title?: string;
  children: any;
  marginTitle?: number;
  rightComponent?: React.ReactNode,
  flex?: number,
  noTitle?: boolean,
  noPadding?: boolean,
  backgroundImage?: string
}

const OverviewCard = ({
  title,
  children,
  marginTitle,
  rightComponent,
  flex,
  noTitle,
  noPadding,
  backgroundImage
}: OverviewCardtype) => {
  const classes = useStyles();

  return (
    <Box
      className={classes.cardContainer}
      flex={flex}
      style={{
        paddingTop: noPadding ? 0 : undefined,
        paddingBottom: noPadding ? 0 : undefined,
        backgroundImage
      }}
    >
      {!noTitle
        && (
          <Box className={classes.titleContainer}>
            <Typography
              variant="h6"
              className={classes.title}
              style={{ marginBottom: marginTitle }}
            >
              {title}

            </Typography>
            {rightComponent}
          </Box>
        )}
      {children}
    </Box>
  );
};

OverviewCard.defaultProps = {
  title: '',
  marginTitle: undefined,
  rightComponent: undefined,
  flex: undefined,
  noTitle: false,
  noPadding: false,
  backgroundImage: undefined,
};
export default OverviewCard;
