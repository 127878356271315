import { Box } from "@material-ui/core";
import Switch from "components/switch";
import React, { FC } from "react";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ProrationsForm } from "../types";
import { defaultTabs } from "../utils";

type Props = {
  selectedTabIndex: number
  isLoading: boolean
}

export const FlagsForm: FC<Props> = ({
  selectedTabIndex,
  isLoading
}) => {
  const { t } = useTranslation();
  const { control } = useFormContext<ProrationsForm>();
  const { forms } = useWatch({ control });
  const showImpoundDisclosure = forms ? defaultTabs.includes(forms[selectedTabIndex].description ?? "") : false;

  return (
    <>
      {showImpoundDisclosure && (
        <Box>
          <Controller
            control={control}
            key={`forms.${selectedTabIndex}.impound_disclosure`}
            name={`forms.${selectedTabIndex}.impound_disclosure`}
            render={({ field }) => (
              <Switch
                label={t('taxesAndProrations:pull-tax-to-impound-disclosure')}
                checked={field.value}
                handleOnChange={(_, checked) => field.onChange(checked)}
                disabled={isLoading}
              />
            )}
          />
        </Box>
      )}

      <Box>
        <Controller
          control={control}
          key={`forms.${selectedTabIndex}.include_1099`}
          name={`forms.${selectedTabIndex}.include_1099`}
          render={({ field }) => (
            <Switch
              label={t('taxesAndProrations:include-1099')}
              checked={field.value}
              handleOnChange={(_, checked) => field.onChange(checked)}
              disabled={isLoading}
            />
          )}
        />
      </Box>
    </>
  );
};