import { Box, Popover, TextField, makeStyles } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import ContactCreation from 'components/users/form-contact-creation/contact-creation';
import useSearchParty from 'hooks/useSearchParty';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NewUserType } from 'v2-types/user';
import CustomContactOption from './customContactOption';

type ContactAutocompleteType = {
  _id: string;
  name: string;
};

const useStyles = makeStyles((theme) => ({
  searchContact: {
    '&.MuiAutocomplete-fullWidth ': {
      backgroundColor: theme.palette.background.default,
      borderRadius: '5px',
      padding: '4px 10px 4px 10px',

      '& .MuiFormLabel-root ': {
        color: theme.palette.primary.dark,
      },

      '& .MuiAutocomplete-inputRoot': {
        color: theme.palette.primary.dark,
        fontSize: '14px',

        '&::before': {
          display: 'none',
        },

        '&::after': {
          display: 'none',
        }
      }

    },
  },
}));

const SearchContact = ({ initialValue, getValue, reference }) => {
  const [searchText, setSearchText] = useState<string | undefined>(initialValue.name);
  const [localValue, setLocalValue] = useState<ContactAutocompleteType | null>(initialValue);
  const { t } = useTranslation();

  const { parties } = useSearchParty(searchText);

  const searchContainerRef = useRef<HTMLDivElement>(null);
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const [showForm, setShowForm] = useState(false);
  const id = showForm ? 'simple-popover' : undefined;
  const [newContactKind, setNewContactKind] = useState<string>('Person');
  const [searchContactText, setSearchContactText] = useState<string | undefined>();

  const classes = useStyles();

  return (
    <div ref={searchContainerRef}>
      <Autocomplete
        innerRef={reference}
        className={classes.searchContact}
        value={localValue}
        freeSolo
        fullWidth
        options={parties}
        filterOptions={(options) =>
          options.length > 0
            ? [
              ...options,
              {
                _id: 'add-new-contact',
                username: '',
                user_id: '',
                name: '',
                emails: [],
                addresses: [],
                phones: [],
              } as unknown as NewUserType,
            ]
            : [
              {
                _id: 'add-new-contact',
                username: '',
                user_id: '',
                name: '',
                emails: [],
                addresses: [],
                phones: [],
              } as unknown as NewUserType,
            ]}
        getOptionLabel={(party) => party.name || ''}
        onInputChange={(_, value, reason) => {
          if (reason === 'input' || reason === 'clear') {
            setSearchText(value);
          }
        }}
        renderOption={(props) => (
          <CustomContactOption
            contact={props}
            handleContactCreation={(kind) => {
              setSearchText(undefined);
              setNewContactKind(kind);
              setSearchContactText(searchText);
              setShowForm(true);
              setAnchorEl(searchContainerRef.current);
            }}
          />
        )}
        onChange={(_, value) => {
          _.stopPropagation();
          if (value && typeof value === 'object') {
            setLocalValue(value);
            getValue({
              _id: value._id,
              name: value.name
            });
          } else {
            setLocalValue(null);
            getValue(null);
          }
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            inputRef={params.InputProps.ref}
            label={t('parties:contact')}
            placeholder={t('parties:type-as-3-characters')}
          />
        )}
      />
      <Popover
        id={id}
        open={showForm}
        anchorEl={anchorEl}
        onClose={() => {
          setAnchorEl(null);
          setShowForm(false);
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Box display="flex" flex="1" flexDirection="column">
          <ContactCreation
            searchText={searchContactText}
            selectedKind={newContactKind}
            kindList={['Person', 'Company']}
            onSuccess={({ _id, name }) => {
              setShowForm(false);
              setLocalValue({ _id, name });
              getValue({ _id, name });
            }}
            handleCancel={() => {
              setShowForm(false);
            }}
          />
        </Box>
      </Popover>
    </div>
  );
};

export default SearchContact;
