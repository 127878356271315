import { useOrderTabs, UseOrderTabsReturn } from "hooks/useOrderTabs";
import React, {
  createContext,
  FC,
  useContext,
} from "react";

type WorkspaceContextType = UseOrderTabsReturn;

const WorkspaceContext = createContext<WorkspaceContextType | null>(null);

export const WorkspaceContextProvider: FC<{ children: React.ReactNode }> = ({ children }) => {
  const orderTabsMethods = useOrderTabs();

  return (
    <WorkspaceContext.Provider value={{
      ...orderTabsMethods
    }}
    >
      {children}
    </WorkspaceContext.Provider>
  );
};

export const useWorkspaceContext = () => {
  const methods = useContext(WorkspaceContext) as WorkspaceContextType;
  return methods;
};