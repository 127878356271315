import {
  Box,
  Grid,
  makeStyles,
  Paper,
  Typography,
} from '@material-ui/core';
import React from 'react';

import EstimatedTaxesSectionTable from './components/estimated-taxes-section-table';
import Header from './components/header';
import PaymentSectionTable from './components/payment-section-table';
import { UseFormReturn } from 'react-hook-form';
import { projectedPayments } from './services';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme: any) => ({
  rootContainer: { padding: theme.spacing(3) },
  mainContainer: { padding: theme.spacing(3) },
  header: {
    color: theme.palette.tab.offselected,
    fontWeight: 500,
  },
}));

type ProjectedPaymentsSceneProps = {
  methods: UseFormReturn<projectedPayments, any, undefined>
  loadingSave?: boolean,
  handleSave: (data: projectedPayments) => void
};

const ProjectedPaymentsScene = ({
  methods,
  loadingSave,
  handleSave,
}: ProjectedPaymentsSceneProps) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (

    <form onSubmit={methods.handleSubmit((data) => handleSave(data))}>
      <Box className={classes.rootContainer}>
        <Paper>
          <Grid container direction="column">
            <Grid item xs={12}>
              <Header
                t={t}
                isValid
                loading={loadingSave}
              />
            </Grid>

            <Grid item xs={12} className={classes.mainContainer}>
              <Grid container direction="row" spacing={5}>
                <Grid item xs={12}>
                  <Typography variant="h6" className={classes.header}>
                    {t('projectedPayments:payments')}
                  </Typography>
                </Grid>

                <Grid item xs={6}>
                  <PaymentSectionTable
                    methods={methods}
                  />
                </Grid>

                <Grid item xs={6}>
                  <EstimatedTaxesSectionTable
                    methods={methods}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Box>

    </form>
  );
};

export default ProjectedPaymentsScene;
