import { capitalize } from '@material-ui/core';
import moment, { Moment } from 'moment';
import { LEDGER_CODE_PRORATION_AMOUNT, LEDGER_CODE_PRORATION_PERDIEM, LEDGER_CODE_PRORATION_PRORATED } from 'utils/constants';
import { formatMoney } from 'utils/helpers';
import { Proration, ScheduleProrationEnum } from 'v2-types/order';
import { PerDiemDecimals, ProrationForm } from '../types';

export const perDiemDecimals: PerDiemDecimals[] = [
  { value: 2, name: '2' },
  { value: 3, name: '3' },
  { value: 4, name: '4' },
  { value: 5, name: '5' },
  { value: 6, name: '6' },
  { value: 7, name: '7' },
  { value: 8, name: '8' },
  { value: 9, name: '9' },
];

export const paymentScheduleOptions = Object.values(ScheduleProrationEnum)
  .filter((schedule) => !schedule.includes('custom'))
  .map((schedule) => ({
    value: schedule,
    name: capitalize(schedule).replaceAll("_", ' '),
  }))
  .sort((scheduleA, scheduleB) => scheduleA.name.localeCompare(scheduleB.name));

export const defaultTabs = ['Assessments', 'City/Town Taxes', 'County Taxes'];

export const getPropertyProrationForms = (propertyId: string, prorations: Proration[]) => {
  if (!prorations) return [];

  const propertyProrations: ProrationForm[] =
    prorations
      .filter((proration) => proration.estate._id === propertyId)
      .map((proration) => {
        const anualAmount = proration.accounting.find((entry) => entry.code.includes(LEDGER_CODE_PRORATION_AMOUNT));
        const perDiemAmount = proration.accounting.find((entry) => entry.code.includes(LEDGER_CODE_PRORATION_PERDIEM));
        const proratedAmount = proration.accounting.find((entry) => entry.code.includes(LEDGER_CODE_PRORATION_PRORATED));
        return {
          ...proration,
          anualAmount,
          perDiemAmount,
          proratedAmount,
        };
      }) ?? [];

  return propertyProrations;
};

type getDaysParams = {
  debitedParty: 'buyer_owes_seller' | 'seller_owes_buyer';
  startDate: Moment;
  endDate: Moment;
  prorationDate: Moment;
};

export const getDays = ({ debitedParty, startDate, endDate, prorationDate }: getDaysParams) => {
  let days = prorationDate.diff(startDate, 'days');
  if (debitedParty === 'buyer_owes_seller') {
    days = endDate.diff(prorationDate, 'days');
  }
  return days;
};

export const getProrationFormTitle = (form?: ProrationForm) => {
  let title = 'Prorated Amount';
  if (form) {
    const { perDiemAmount, decimals, debited_party: debitedParty, end_date, start_date, proration_date, kind: prorateUsing } = form;
    if (prorateUsing === 'per_diem') {
      const endDate = moment(end_date);
      const startDate = moment(start_date);
      const prorationDate = moment(proration_date);

      if (
        debitedParty &&
        endDate.isValid() &&
        startDate.isValid() &&
        prorationDate.isValid() &&
        endDate.isSameOrAfter(startDate) &&
        prorationDate.isSameOrAfter(startDate) &&
        prorationDate.isSameOrBefore(endDate)
      ) {
        const days = getDays({ debitedParty, endDate, prorationDate, startDate });
        title += ` (${days} days times ${formatMoney(perDiemAmount?.amount ?? 0, decimals)})`;
      }
    }
  }

  return title;
};
